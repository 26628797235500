import { FC, SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@mui/material'
import { CellContext } from '@tanstack/react-table'

import {
  getInitialPropertiesForRequest,
  getPropertiesForUpdate,
} from '@pages/Parameters/helpers/getPropertiesForUpdate'

import { DataSource } from '@components/DataSource'
import { GeneralButtons } from '@components/GeneralButtons'
import { CommandPickerController } from '@components/hookFormControllers/CommandPickerController'
import { NewModel } from '@components/NewModel'
import { SwitchTable } from '@components/SwitchTable'
import { Tab, TabPanel, Tabs } from '@components/Tabs'
import { INITIAL_TEXT_STATE as defaultTextSettings, TextParams } from '@components/TextSettings'

import { useFetchContextVariablesByIdNoCacheMutation } from '@redux/api'
import { useUpdateLocalParameterPropertyWithViewResetMutation } from '@redux/api/parameters.api'
import { setStep } from '@redux/reducers/stepper.reducer'

import { useAppDispatch, usePrompt } from '@hooks'
import {
  getAvailableActionEvents,
  getColumnParametersFromArray,
  getDefaultValues,
  getLocalStorageContext,
  getParametersForCreation,
} from '@helpers'
import { ENTITY, MODAL_TYPE, MODEL_TYPE, REGEX, ROUTES } from '@constants'

import { PARAMETER_SHOW_SA, PARAMETER_SHOW_SA_VALUES } from '../../../../constants/parameterShowSA'
import {
  AutocompleteOption,
  ENTITY_COMMAND_TYPE,
  EntityType,
  FormValues,
  GETEntityColumn,
  ShareActionType,
  ViewRow,
} from '../../../../types'
import { PageContext } from '../../EntityCreateOrEdit'
import { ParametersModal } from '../ParametersModal'

import { Prefilter } from './components'

type EntitySuccessCallbackParams = {
  entityData?: EntityType
  entityColumnHeaderData: TextParams | undefined
  entityColumnTextData: TextParams | undefined
}

type EntityOnSubmitType = {
  data: FormValues
  isUpdate?: boolean
  entityColumnHeaderData?: TextParams
  entityColumnTextData?: TextParams
}

type EntityConfigurationProps = {
  type: ENTITY
}

export const EntityConfiguration: FC<EntityConfigurationProps> = ({ type }) => {
  const [currentTab, setCurrentTab] = useState(0)
  const [isOpenParameters, setOpenParameters] = useState(false)
  const {
    onShowDialog,
    deleteColumn,
    deleteAction,
    mainTable,
    actionTable,
    objects,
    onSetObjectCode,
    onSetModelCode,
    currentEntity,
    onCancel,
    addEntity,
    isSuccessAddModel,
    entityIsLoading,
    bindingValuesActionEvents,
    entityCreateLoading,
    entityCode,
    showDialog,
    updateColumnOrder,
    updateVisibilityColumn,
    watchUseParameters,
    watchQuickSearch,
    methods: parametersMethods,
    modalType,
    handleUpdateColumnTitle,
    handleCreateAllColumns,
    createAllColumnsLoading,
    createMandatoryColumnsLoading,
    watchParameterShowSA,
  } = useContext(PageContext)
  const defaultValues: FormValues = getDefaultValues()
  const methods = useForm<FormValues>({ defaultValues })
  const {
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
    getValues,
    setValue,
  } = methods
  const watchedObject = watch('object')
  const watchedPrefilter = watch('prefilter')
  const { code } = useParams()
  const location = useLocation() as Location & { state?: { code?: string } }
  const copyCode = location.state?.code
  const isEdit = !!code
  const isFormLoading = isEdit && entityIsLoading && !copyCode
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const filteredActionEvents = getAvailableActionEvents(bindingValuesActionEvents, actionTable)
  const isCreate = [
    ROUTES.CONFIG_VIEWS_CREATE,
    ROUTES.CONFIG_LIST_CONTROLS_CREATE,
    ROUTES.CONFIG_DROP_DOWN_ENTITY_CREATE,
  ].includes(location.pathname)
  const isLeaveEdit = isDirty && !entityCreateLoading && !!currentEntity && !isCreate
  const isLeaveCreate = isDirty && isCreate && !entityCode
  const context = getLocalStorageContext()

  const [fetchVariables, { data: variables, isLoading: isVariablesLoading }] =
    useFetchContextVariablesByIdNoCacheMutation()

  const [updateParameterProperty] = useUpdateLocalParameterPropertyWithViewResetMutation()

  usePrompt({ when: isLeaveEdit || isLeaveCreate })

  const variableOptions = useMemo(
    () => variables?.variables.map(({ id, variableTitle: label }) => ({ id, label })) || [],
    [variables?.variables]
  )

  const selectedObjectCode = useMemo(() => (watchedObject ? watchedObject.id : ''), [watchedObject])

  useEffect(() => {
    if (context && !isVariablesLoading) {
      fetchVariables(context?.id)
        .unwrap()
        .catch(error => {
          if (error.status === 404) {
            localStorage.removeItem('context')
            navigate(ROUTES.CHOOSE_CONTEXT)
          }
        })
    }
  }, [context?.id])

  useEffect(() => {
    dispatch(setStep(0))
  }, [location])

  useEffect(() => {
    if (currentEntity && objects) {
      const object = objects?.find(obj => obj.code === currentEntity.objectCode)

      onSetModelCode(currentEntity.code)
      const commands = currentEntity.commands.map(command => ({
        type: {
          id: command.type,
          label: command.type,
        },
        code: {
          id: command.name,
          label: command.name,
        },
      }))

      reset({
        ...currentEntity,
        commands,
        object: object ? { id: object.code, label: object.title } : null,
      })

      return
    }

    onSetModelCode('')
  }, [currentEntity, objects])

  useEffect(() => {
    if (watchedObject && objects) {
      const currentObj = objects.filter(obj => obj.title === watchedObject.label)
      if (currentObj.length) {
        onSetObjectCode?.(currentObj[0].code)
      }
    }
  }, [watchedObject])

  useEffect(() => {
    if (!isOpenParameters) {
      parametersMethods.reset({
        useParameters: Boolean(currentEntity?.useLocalParameters),
        hasQuickSearch: Boolean(currentEntity?.hasQuickSearch),
        parameterShowSA: currentEntity?.parameterShowSA
          ? {
              id: `${currentEntity?.parameterShowSA}`,
              label:
                PARAMETER_SHOW_SA[currentEntity?.parameterShowSA as keyof typeof PARAMETER_SHOW_SA],
            }
          : null,
      })
    }
  }, [isOpenParameters, currentEntity])

  const handleCloseParameters = () => {
    setOpenParameters(false)
  }

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const handleEdit = (id: number | string, isAction?: boolean) => {
    if (id) {
      onShowDialog(MODAL_TYPE.EDIT, id, isAction)
    }
  }

  const handleCreateEntitySuccess = ({
    entityData,
    entityColumnHeaderData,
    entityColumnTextData,
  }: EntitySuccessCallbackParams) => {
    const { bgFontColor, formats, ...otherDefaultTextSettings } = defaultTextSettings
    const textSettingsData = {
      ...otherDefaultTextSettings,
      isBold: String(Boolean(formats.find(format => format === 'bold'))),
      isItalic: String(Boolean(formats.find(format => format === 'italic'))),
      fontFamily: defaultTextSettings.fontFamily.label,
    }
    const columnHeaderData = entityColumnHeaderData ?? textSettingsData
    const columnTextData = entityColumnTextData ?? {
      ...textSettingsData,
      bgFontColor,
    }

    if (!entityData?.parameters) {
      return
    }

    const { headerParamId, textParamId } = getColumnParametersFromArray(entityData.parameters)

    if (!textParamId || !headerParamId) {
      return
    }

    const propertiesForCreation = [
      ...getInitialPropertiesForRequest({
        textData: columnHeaderData,
        paramId: headerParamId,
      }),
      ...getInitialPropertiesForRequest({
        textData: columnTextData,
        paramId: textParamId,
      }),
    ]

    updateParameterProperty(propertiesForCreation)

    reset(defaultValues)
  }

  const handleUpdateColumn = ({
    entityData,
    entityColumnHeaderData,
    entityColumnTextData,
  }: EntitySuccessCallbackParams) => {
    if (!entityColumnHeaderData || !entityColumnTextData || !entityData?.parameters) {
      return
    }

    const { headerProperties, textProperties, headerParamId, textParamId } =
      getColumnParametersFromArray(entityData.parameters)

    if (!headerProperties || !textProperties || !headerParamId || !textParamId) {
      return
    }

    if (headerProperties.length === 0 && textProperties.length === 0) {
      handleCreateEntitySuccess({
        entityData,
        entityColumnHeaderData,
        entityColumnTextData,
      })

      return
    }

    const propertiesForUpdate = [
      ...getPropertiesForUpdate(entityColumnHeaderData, headerProperties, headerParamId),
      ...getPropertiesForUpdate(entityColumnTextData, textProperties, textParamId),
    ]

    updateParameterProperty(propertiesForUpdate)

    handleCloseParameters()
  }

  const onSubmit = ({
    data,
    isUpdate = false,
    entityColumnHeaderData,
    entityColumnTextData,
  }: EntityOnSubmitType) => {
    const { id, method, dataType, code, title, object, commands: commandOptions, prefilter } = data

    const columnHeaderCode = `column_header_styles_${code}`
    const columnTextCode = `column_text_styles_${code}`
    const commands = commandOptions
      .filter(command => command.type && command.code)
      .map(command => ({
        type: (command.type as AutocompleteOption<ENTITY_COMMAND_TYPE>).id,
        name: (command.code as AutocompleteOption<string>).id,
      }))

    addEntity?.({
      id,
      code,
      title,
      commands,
      objectCode: object?.id || '',
      objectTitle: object?.label || '',
      useLocalParameters: watchUseParameters,
      hasQuickSearch: currentEntity ? watchQuickSearch : true,
      parameterShowSA: watchParameterShowSA?.id ?? PARAMETER_SHOW_SA_VALUES[PARAMETER_SHOW_SA.VIEW],
      prefilter,
      parameters: [],
      // Создание параметров
      ...(isUpdate && !currentEntity?.parameters?.length
        ? { parameters: getParametersForCreation(columnHeaderCode, columnTextCode) }
        : {}),
    })
      .then(entityData => {
        if (isUpdate) {
          handleUpdateColumn({
            entityData,
            entityColumnHeaderData,
            entityColumnTextData,
          })
        }

        parametersMethods.reset({
          useParameters: entityData.useLocalParameters,
          hasQuickSearch: entityData.hasQuickSearch,
          parameterShowSA: entityData?.parameterShowSA
            ? {
                id: entityData?.parameterShowSA,
                label:
                  PARAMETER_SHOW_SA[
                    currentEntity?.parameterShowSA as keyof typeof PARAMETER_SHOW_SA
                  ],
              }
            : null,
        })
      })
      .catch(() => {
        onSetModelCode('')
      })
    onSetModelCode(code)
  }

  const handleDropChange = (rows: GETEntityColumn[]) => {
    const result = rows.map((el, i) => ({ ...el, order: i }))

    if (JSON.stringify(result) !== JSON.stringify(mainTable.rows)) {
      updateColumnOrder(result.map(el => ({ id: Number(el.id), order: el.order })))
    }

    return result
  }

  const handleHide = (params: CellContext<ViewRow, unknown>, visibility: boolean) => {
    const pinnedRows = mainTable.rows.filter(row => row.pinnedColumn).map(row => row.id)
    const visibleRows = mainTable.rows
      .filter(row => row.visibility)
      .filter(row => row.id !== params.row.original.id)
      .map(row => row.id)

    const hasAllPinnedInVisibleColumns =
      pinnedRows.length && visibleRows.length && visibleRows.every(v => pinnedRows.includes(v))

    // проверка на наличие всех видимых закрепленных колонок, обход бага с дергающимся скроллом https://jiradc.utg.group/browse/WF-1342
    if (
      hasAllPinnedInVisibleColumns ||
      (visibleRows.length === 0 && visibility && params.row.original.pinnedColumn)
    ) {
      alert(t('configEntity.view.allPinError'))

      return
    }

    updateVisibilityColumn({ id: Number(params.row.original.id), visibility })
  }

  const handleOpenParameters = () => setOpenParameters(true)

  const handleSaveParameters = (
    entityColumnHeaderData: TextParams,
    entityColumnTextData: TextParams
  ) => {
    onSubmit({ data: getValues(), isUpdate: true, entityColumnHeaderData, entityColumnTextData })
  }
  const modelType = isEdit ? 'editModel' : 'newModel'

  return (
    <Box sx={{ width: '100%' }}>
      <form>
        <FormProvider {...methods}>
          <Grid container px={1} py={0.5}>
            <Grid container alignItems='center' justifyContent={'space-between'}>
              <Typography py={0.5} variant='h5'>
                {t(`configEntity.${type}.${modelType}`)}
              </Typography>
              <Button disabled={!currentEntity} variant='outlined' onClick={handleOpenParameters}>
                {t(`configEntity.${type}.parameters.title`)}
              </Button>
            </Grid>
            <Grid container display='flex' justifyContent='space-between' pb={0.5}>
              <Grid item md={5.9}>
                <Typography py={0.5} variant='h5'>
                  {t('viewsCreate.general')}
                </Typography>
                <NewModel loading={isFormLoading} />
                <Prefilter
                  keyWordList={variableOptions}
                  value={watchedPrefilter}
                  keyWordObj={{
                    pattern: REGEX.GLOBAL_VARIABLE,
                    alias: 'global',
                  }}
                  onChange={value => setValue('prefilter', value, { shouldDirty: true })}
                />
              </Grid>
              <Grid item md={5.9}>
                <DataSource
                  isDisabledObject={!!code || !!copyCode}
                  isLoading={isFormLoading}
                  objects={objects}
                />
              </Grid>
            </Grid>
            <Grid item md>
              <CommandPickerController objectCode={selectedObjectCode} />
            </Grid>
          </Grid>
        </FormProvider>
      </form>
      <ParametersModal
        isShow={isOpenParameters}
        onClose={handleCloseParameters}
        onSave={handleSaveParameters}
      />
      <Grid container justifyContent={'flex-end'}>
        <GeneralButtons
          cancelText={t('viewOrFormCreate.cancelBtnText')}
          saveDisabled={!isDirty}
          saveText={t('viewOrFormCreate.saveBtnText')}
          onCancel={onCancel}
          onSave={handleSubmit(data => {
            onSubmit({ data })
          })}
        />
      </Grid>
      <Box sx={{ pl: 1, pt: 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab
              label={t(`configEntity.${type}.columns.title`)}
              sx={{
                pl: 0,
              }}
            />
            {type !== ENTITY.DROP_DOWN_ENTITY ? (
              <Tab
                label={t(`configEntity.${type}.actions.title`)}
                sx={{
                  pl: 0,
                }}
              />
            ) : (
              <></>
            )}
            <Tab
              label={t(`configEntity.${type}.order.title`)}
              sx={{
                pl: 0,
              }}
            />
          </Tabs>
        </Box>
        <TabPanel index={0} value={currentTab}>
          <SwitchTable
            disablePagination
            isCrud
            showVisibilityColumn
            btnDisabled={!isEdit && !isSuccessAddModel}
            btnText={t(`configEntity.${type}.columns.addButton`)}
            columns={mainTable.columns}
            createAllBtnText={t('configEntity.allEntities.createAll')}
            createAllRequiredBtnText={t('configEntity.allEntities.createAllMandatory')}
            isCreateAllLoading={createAllColumnsLoading}
            isCreateMandatoryLoading={createMandatoryColumnsLoading}
            loading={entityIsLoading}
            rows={mainTable.rows}
            showActionsColumn={!copyCode}
            showDialog={showDialog}
            showSearch={true}
            type={type as ShareActionType}
            updateRow={handleUpdateColumnTitle}
            useDragAndDrop={false}
            containerSx={{
              '.MuiTableBody-root > div': {
                height: '400px !important',
              },
            }}
            onCreateAllEntityFields={handleCreateAllColumns}
            onDelete={deleteColumn}
            onDropChange={handleDropChange}
            onEdit={params => handleEdit(params.row.original.id)}
            onHide={handleHide}
            onShowDialog={onShowDialog}
          />
        </TabPanel>
        {type !== ENTITY.DROP_DOWN_ENTITY ? (
          <TabPanel index={1} value={currentTab}>
            <SwitchTable
              disablePagination
              isCrud
              btnDisabled={!isEdit && !isSuccessAddModel}
              btnText={t(`configEntity.${type}.actions.addButton`)}
              columns={actionTable.columns}
              loading={entityIsLoading}
              rows={actionTable.rows}
              showActionsColumn={!copyCode}
              showDialog={showDialog}
              type={MODEL_TYPE.ACTION}
              containerSx={{
                '.MuiTableBody-root > div': {
                  height: '400px !important',
                },
              }}
              onDelete={deleteAction}
              onEdit={params => handleEdit(params.row.original.id, true)}
              onShowDialog={onShowDialog}
            />
          </TabPanel>
        ) : (
          <></>
        )}
        <TabPanel index={2} value={currentTab}>
          <SwitchTable
            disablePagination
            disableSort
            isCrud
            showVisibilityColumn
            btnDisabled={!isEdit && !isSuccessAddModel}
            btnText={t(`viewsCreate.views.btnText`)}
            columns={mainTable.columns}
            disabledVisibilityColumn={true}
            loading={entityIsLoading}
            rows={mainTable.rows}
            showActionsColumn={!copyCode}
            showDialog={showDialog}
            type={type as ShareActionType}
            useDragAndDrop={!copyCode}
            containerSx={{
              '.MuiTableBody-root > div': {
                height: '400px !important',
              },
            }}
            onDelete={deleteColumn}
            onDropChange={handleDropChange}
            onEdit={params => handleEdit(params.row.original.id)}
            onHide={handleHide}
            onShowDialog={onShowDialog}
          />
        </TabPanel>
      </Box>
    </Box>
  )
}
