import { createContext, FC, useEffect, useMemo, useState } from 'react'
import { DragDropContext, DraggableLocation, DragStart, DropResult } from 'react-beautiful-dnd'
import { flushSync } from 'react-dom'
import { useTranslation } from 'react-i18next'
import isNumber from 'lodash/isNumber'
import { Box, Divider, SxProps, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { DraggableFormElement } from '@pages/FormCreateOrEdit/types'

import {
  useFetchPointsLinesByIdQuery,
  useFetchUndefinedPointsByIdQuery,
  useUpdatePointLineByIdMutation,
  useUpdatePointLineElementByIdMutation,
} from '@redux/api/tooltips.api'
import { showMessage } from '@redux/reducers/snackbar.reducer'

import { useAppDispatch, useTrimming } from '@hooks'
import {
  add,
  getElementPathByDroppableId,
  getLineById,
  getLinePathByDroppableId,
  move,
  reorder,
} from '@helpers'
import { AVAILABLE_FORM_ELEMENT_TYPE, FORM_DROPPABLE_TYPE, GRID_SIZE } from '@constants'

import { GETTooltipPoint, TooltipContainer, TooltipElement } from '../../../../types'

import { AvailableTooltipElements } from './AvailableTooltipElements'
import { ConfiguredTooltipElements } from './ConfiguredTooltipElements'

export const Title: FC<{ title: string; sx?: SxProps }> = ({ title, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 250,
        padding: '0px 16px 0px 24px',
        gap: '16px',
        pl: 0,
        ...sx,
      }}
    >
      <Typography fontWeight={700} sx={{ color: grey[600] }} variant={'caption'}>
        {title}
      </Typography>
    </Box>
  )
}

export type UnallocatedFields = GETTooltipPoint[]
export type AllocatedFields = Array<GETTooltipPoint[]>

type FieldsConfigProps = {
  tooltipId: number
}

type FieldsConfigContextType = {
  containers: TooltipContainer[]
  isLoadingLines: boolean
  availableFormFields: DraggableFormElement[]
  isLoadingAvailableFields: boolean
  currentDragged?: DragStart
  changeFieldsFilter: (filter: string | undefined) => void
}

export const FieldsConfigContext = createContext<FieldsConfigContextType>(
  {} as FieldsConfigContextType
)

export const FieldsConfig: FC<FieldsConfigProps> = ({ tooltipId }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [fieldsFilterValue, setFieldsFilterValue] = useState<string>()

  const { data: undefinedPoints, isLoading: isLoadingAvailableFields } =
    useFetchUndefinedPointsByIdQuery(
      { tooltipId, filter: fieldsFilterValue },
      {
        skip: !tooltipId,
        refetchOnMountOrArgChange: true,
      }
    )

  const { data: fetchedLines, isLoading: isLoadingLines } = useFetchPointsLinesByIdQuery(
    tooltipId,
    {
      skip: !tooltipId,
      refetchOnMountOrArgChange: true,
    }
  )

  const [updateLine] = useUpdatePointLineByIdMutation()
  const [updateElement] = useUpdatePointLineElementByIdMutation()

  const [unallocatedFields, setUnallocatedFields] = useState<UnallocatedFields>([])
  const [containers, setContainers] = useState<TooltipLine<GETTooltipPoint>[]>([
    {
      id: 0,
      order: 0,
      title: 'Tooltip',
      tooltipId,
      tabs: [
        {
          id: 0,
          order: 0,
          title: 'Tooltip Lines',
          lines: [],
        },
      ],
    },
  ])

  useEffect(() => {
    setUnallocatedFields(undefinedPoints || [])
  }, [undefinedPoints])

  useEffect(() => {
    if (fetchedLines) {
      const updatedContainer = containers[0]
      updatedContainer.tabs[0].lines = fetchedLines
      setContainers([updatedContainer])
    }
  }, [fetchedLines])

  const availableFormFields = useMemo(
    (): DraggableFormElement[] =>
      unallocatedFields.map(field => ({
        id: field.id,
        type: AVAILABLE_FORM_ELEMENT_TYPE.FIELD,
        title: field.title,
      })),
    [unallocatedFields]
  )

  const showFullRowMessage = () => {
    dispatch(showMessage({ type: 'info', text: t('notifications.rowIsFull') }))
  }

  const [currentDragged, setCurrentDragged] = useState<DragStart>()
  const handleDragStart = (initial: DragStart) => {
    setCurrentDragged(initial)
  }

  const changeFieldsFilter = (filter: string | undefined) => {
    setFieldsFilterValue(filter)
  }

  const { onBeforeCapture } = useTrimming()

  const addNewElement = (source: DraggableLocation, destination: DraggableLocation) => {
    const [containerId, tabId, lineId] = getElementPathByDroppableId(destination.droppableId)
    const newContainers = structuredClone(containers) as TooltipContainer[]
    const { line } = getLineById(newContainers, containerId, tabId, lineId)
    if (!line) {
      return
    }

    let newElement = {} as Omit<TooltipElement, 'id'>

    const rowSize = line.elements.reduce((a, b) => a + b.size, 0)
    const elementSize = rowSize === 11 ? 1 : 2

    if (rowSize === 12) {
      showFullRowMessage()

      return
    }

    if (source.droppableId.includes(AVAILABLE_FORM_ELEMENT_TYPE.FIELD)) {
      const draggedField = availableFormFields[source.index]

      const newFields = [...unallocatedFields].filter(({ id }) => id !== draggedField.id)
      // Принудительно вызываем ререндер списка, иначе dragging item мерцает, возвращаясь в исходный список
      flushSync(() => {
        setUnallocatedFields(newFields)
      })

      newElement = {
        order: destination.index,
        size: elementSize,
        //type: FORM_ELEMENT_TYPE.FIELD,
        pointId: Number(draggedField.id),
        lineId: 0,
        title: draggedField.title,
      }
    }

    line.elements = add(
      {
        id: -1,
        ...newElement,
      },
      line.elements,
      destination
    )[destination.droppableId]

    setContainers(newContainers)

    updateElement({
      ...newElement,
      lineId: line.id,
    })
  }

  const reorderElements = (source: DraggableLocation, destination: DraggableLocation) => {
    const [containerId, tabId, lineId] = getElementPathByDroppableId(source.droppableId)
    const newContainers = structuredClone(containers) as TooltipContainer[]
    const { line } = getLineById(newContainers, containerId, tabId, lineId)

    if (!line) {
      return
    }

    const newElements = reorder(line.elements, source.index, destination.index).map(
      (element, index) => ({
        ...element,
        order: index,
      })
    )
    line.elements = newElements
    setContainers(newContainers)

    const { id, order, size, title, point } = newElements[destination.index]

    updateElement({
      id,
      order,
      size,
      title,
      lineId: line.id,
      pointId: point?.id,
    })
  }

  const moveElements = (source: DraggableLocation, destination: DraggableLocation) => {
    const [sContainerId, sTabId, sLineId] = getElementPathByDroppableId(source.droppableId)
    const [dContainerId, dTabId, dLineId] = getElementPathByDroppableId(destination.droppableId)

    const newContainers = structuredClone(containers) as TooltipContainer[]

    if (isNumber(sContainerId) && isNumber(dContainerId)) {
      const { line: sLine } = getLineById(newContainers, sContainerId, sTabId, sLineId)
      const { line: dLine } = getLineById(newContainers, dContainerId, dTabId, dLineId)

      const countSizeRow = dLine?.elements.reduce((a, b) => a + b.size, 0)

      if (countSizeRow === 12) {
        showFullRowMessage()

        return
      }

      // Если перетаскиваемый элемент больше чем свободное место в строке
      if (sLine && countSizeRow && sLine?.elements[source.index].size > GRID_SIZE - countSizeRow) {
        showFullRowMessage()

        return
      }

      if (!sLine || !dLine) {
        return
      }

      const moveResult = move(sLine.elements, dLine.elements, source, destination)
      sLine.elements = moveResult[source.droppableId].map((element, index) => ({
        ...element,
        order: index,
      }))
      dLine.elements = moveResult[destination.droppableId].map((element, index) => ({
        ...element,
        order: index,
      }))
      setContainers(newContainers)

      const { id, title, order, size, point } = dLine.elements[destination.index]

      updateElement({
        id,
        title,
        order,
        size,
        pointId: point.id,
        lineId: dLine.id,
      })
    }
  }

  const reorderLines = (source: DraggableLocation, destination: DraggableLocation) => {
    const [sContainerId, sTabId] = getLinePathByDroppableId(source.droppableId)
    const [dContainerId, dTabId] = getLinePathByDroppableId(destination.droppableId)

    const newContainers = structuredClone(containers) as TooltipContainer[]
    const sContainer = newContainers.find(item => item.id === sContainerId)
    const dContainer = newContainers.find(item => item.id === dContainerId)

    if (!sContainer || !dContainer) {
      return
    }

    const sTab = sContainer.tabs.find(item => item.id === sTabId)
    const dTab = dContainer.tabs.find(item => item.id === dTabId)

    if (!sTab || !dTab) {
      return
    }

    if (sTab === dTab) {
      dTab.lines = reorder(dTab.lines, source.index, destination.index).map((line, index) => ({
        ...line,
        order: index,
      }))
    } else {
      const moveResult = move(sTab.lines, dTab.lines, source, destination)
      sTab.lines = moveResult[source.droppableId].map((line, index) => ({ ...line, order: index }))
      dTab.lines = moveResult[destination.droppableId].map((line, index) => ({
        ...line,
        order: index,
      }))
    }
    setContainers(newContainers)
    const { elements, ...body } = dTab.lines[destination.index]
    updateLine({ ...body, tooltipId })
  }

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result

    setCurrentDragged(undefined)

    if (!destination) {
      return
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return
    }

    const sInd = source.droppableId
    const dInd = destination.droppableId

    if (result.type === FORM_DROPPABLE_TYPE.LINES) {
      reorderLines(source, destination)

      return
    }

    if (result.type === FORM_DROPPABLE_TYPE.ELEMENTS) {
      if (sInd.match(/(FIELD):/g)) {
        addNewElement(source, destination)

        return
      }
      if (sInd === dInd) {
        reorderElements(source, destination)
      } else {
        moveElements(source, destination)
      }

      return
    }
  }

  return (
    <Box sx={{ display: 'flex', paddingTop: '16px' }}>
      <FieldsConfigContext.Provider
        value={{
          containers,
          availableFormFields,
          isLoadingAvailableFields,
          isLoadingLines,
          currentDragged,
          changeFieldsFilter,
        }}
      >
        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <AvailableTooltipElements />
          <Divider flexItem orientation='vertical' />
          <ConfiguredTooltipElements />
        </DragDropContext>
      </FieldsConfigContext.Provider>
    </Box>
  )
}
