import { getFactDutyFilter } from '@pages/BarChartPage/helpers/getFactDutyFilter'

export const getFactDutyRequestParams = (
  startHourInterval: string,
  endHourInterval: string,
  department: string,
  staffQual: string[]
) => {
  return {
    objectCode: 'roster-duty',
    filter: getFactDutyFilter(startHourInterval, endHourInterval, department, staffQual),
    // groupBy: [],
    select: 'fact:count(*)',
  }
}
