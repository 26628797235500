import { URLS } from '@constants'
import {
  DELETEViewRestrictionParams,
  GETViewRestrictionsParams,
  POSTRestriction,
  RestrictionData,
} from '@types'

import { commonAPI } from './common.api'

export const restrictionsAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchRestrictionsByViewId: build.query<RestrictionData, GETViewRestrictionsParams>({
      query: ({ viewId, restrictionType }) => ({
        url: URLS.FRONT_CFG + `/restriction/getByViewId/${viewId}`,
        params: {
          restrictionType,
        },
      }),
      providesTags: ['Restrictions'],
    }),
    fetchRestrictionsByViewIdNoCache: build.mutation<RestrictionData, GETViewRestrictionsParams>({
      query: ({ viewId, restrictionType }) => ({
        url: URLS.FRONT_CFG + `/restriction/getByViewId/${viewId}`,
        params: {
          restrictionType,
        },
        method: 'GET',
      }),
    }),
    updateRestrictions: build.mutation<POSTRestriction[], POSTRestriction[]>({
      query: restrictions => ({
        url: URLS.FRONT_CFG + '/restriction',
        method: 'POST',
        body: restrictions,
      }),
      invalidatesTags: ['Restrictions'],
    }),
    deleteViewRestrictionsByType: build.mutation<null, DELETEViewRestrictionParams>({
      query: ({ viewId, restrictionType }) => ({
        url: URLS.FRONT_CFG + `/restriction/viewId/${viewId}`,
        method: 'DELETE',
        params: {
          type: restrictionType,
        },
      }),
      invalidatesTags: ['SingleView', 'Restrictions'],
    }),
  }),
})

export const {
  useFetchRestrictionsByViewIdQuery,
  useUpdateRestrictionsMutation,
  useDeleteViewRestrictionsByTypeMutation,
  useFetchRestrictionsByViewIdNoCacheMutation,
} = restrictionsAPI
