import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'

import { FIELD_VALUE_TYPE } from './forms'
import { NAMES_FIELDS } from './names'
import { OBJECT_FIELD_TYPE } from './object'
import { RULES_EXCEPTIONS, RULES_SEARCH } from './rulesSearch'

type selectOptionType = {
  label: string
  value: string
  exception: string
}

export const enum SEARCH_RULE_TYPE {
  CONTAIN = 'CONTAIN',
  NOT_CONTAIN = 'NOT_CONTAIN',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  EMPTY = 'EMPTY',
  NOT_EMPTY = 'NOT_EMPTY',
  MORE = 'MORE',
  LESS = 'LESS',
  MORE_OR_EQUAL = 'MORE_OR_EQUAL',
  LESS_OR_EQUAL = 'LESS_OR_EQUAL',
}

export const DIRTY_RULES = [
  SEARCH_RULE_TYPE.CONTAIN,
  SEARCH_RULE_TYPE.NOT_CONTAIN,
  SEARCH_RULE_TYPE.EQUAL,
  SEARCH_RULE_TYPE.NOT_EQUAL,
  SEARCH_RULE_TYPE.ENDS_WITH,
  SEARCH_RULE_TYPE.STARTS_WITH,
  SEARCH_RULE_TYPE.MORE,
  SEARCH_RULE_TYPE.LESS,
  SEARCH_RULE_TYPE.MORE_OR_EQUAL,
  SEARCH_RULE_TYPE.LESS_OR_EQUAL,
]

export const SEARCH_RULE_OPTIONS: AutocompleteOption[] = [
  { id: SEARCH_RULE_TYPE.CONTAIN, label: RULES_SEARCH.CONTAIN },
  { id: SEARCH_RULE_TYPE.NOT_CONTAIN, label: RULES_SEARCH.NOT_CONTAIN },
  { id: SEARCH_RULE_TYPE.EQUAL, label: RULES_SEARCH.EQUAL },
  { id: SEARCH_RULE_TYPE.NOT_EQUAL, label: RULES_SEARCH.NOT_EQUAL },
  { id: SEARCH_RULE_TYPE.STARTS_WITH, label: RULES_SEARCH.STARTS_WITH },
  { id: SEARCH_RULE_TYPE.ENDS_WITH, label: RULES_SEARCH.ENDS_WITH },
  { id: SEARCH_RULE_TYPE.EMPTY, label: RULES_SEARCH.EMPTY },
  { id: SEARCH_RULE_TYPE.NOT_EMPTY, label: RULES_SEARCH.NOT_EMPTY },
  { id: SEARCH_RULE_TYPE.MORE, label: RULES_SEARCH.MORE },
  { id: SEARCH_RULE_TYPE.LESS, label: RULES_SEARCH.LESS },
  { id: SEARCH_RULE_TYPE.MORE_OR_EQUAL, label: RULES_SEARCH.MORE_OR_EQUAL },
  { id: SEARCH_RULE_TYPE.LESS_OR_EQUAL, label: RULES_SEARCH.LESS_OR_EQUAL },
]

type AvailableSearchRulesTypesType = Record<
  SEARCH_RULE_TYPE,
  {
    objectFieldTypes: OBJECT_FIELD_TYPE[]
    fieldValueTypes: FIELD_VALUE_TYPE[]
    excludeNames: string[]
  }
>

export const AVAILABLE_SEARCH_RULES_TYPES: AvailableSearchRulesTypesType = {
  [SEARCH_RULE_TYPE.CONTAIN]: {
    objectFieldTypes: [OBJECT_FIELD_TYPE.SINGLE, OBJECT_FIELD_TYPE.OBJECT_LINK],
    fieldValueTypes: [
      // FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
  },
  [SEARCH_RULE_TYPE.NOT_CONTAIN]: {
    objectFieldTypes: [OBJECT_FIELD_TYPE.SINGLE, OBJECT_FIELD_TYPE.OBJECT_LINK],
    fieldValueTypes: [
      // FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
  },
  [SEARCH_RULE_TYPE.EQUAL]: {
    objectFieldTypes: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      OBJECT_FIELD_TYPE.ENUM,
    ],
    fieldValueTypes: [
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
  },
  [SEARCH_RULE_TYPE.NOT_EQUAL]: {
    objectFieldTypes: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      OBJECT_FIELD_TYPE.ENUM,
    ],
    fieldValueTypes: [
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
  },
  [SEARCH_RULE_TYPE.STARTS_WITH]: {
    objectFieldTypes: [OBJECT_FIELD_TYPE.SINGLE, OBJECT_FIELD_TYPE.OBJECT_LINK],
    fieldValueTypes: [
      // FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
  },
  [SEARCH_RULE_TYPE.ENDS_WITH]: {
    objectFieldTypes: [OBJECT_FIELD_TYPE.SINGLE, OBJECT_FIELD_TYPE.OBJECT_LINK],
    fieldValueTypes: [
      // FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
  },
  [SEARCH_RULE_TYPE.EMPTY]: {
    objectFieldTypes: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      OBJECT_FIELD_TYPE.ENUM,
    ],
    fieldValueTypes: [
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
  },
  [SEARCH_RULE_TYPE.NOT_EMPTY]: {
    objectFieldTypes: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      OBJECT_FIELD_TYPE.ENUM,
    ],
    fieldValueTypes: [
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
  },
  [SEARCH_RULE_TYPE.MORE]: {
    objectFieldTypes: [OBJECT_FIELD_TYPE.SINGLE, OBJECT_FIELD_TYPE.OBJECT_LINK],
    fieldValueTypes: [
      // FIELD_VALUE_TYPE.BOOLEAN,
      // FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
  },
  [SEARCH_RULE_TYPE.LESS]: {
    objectFieldTypes: [OBJECT_FIELD_TYPE.SINGLE, OBJECT_FIELD_TYPE.OBJECT_LINK],
    fieldValueTypes: [
      // FIELD_VALUE_TYPE.BOOLEAN,
      // FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
  },
  [SEARCH_RULE_TYPE.MORE_OR_EQUAL]: {
    objectFieldTypes: [OBJECT_FIELD_TYPE.SINGLE, OBJECT_FIELD_TYPE.OBJECT_LINK],
    fieldValueTypes: [
      // FIELD_VALUE_TYPE.BOOLEAN,
      // FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
  },
  [SEARCH_RULE_TYPE.LESS_OR_EQUAL]: {
    objectFieldTypes: [OBJECT_FIELD_TYPE.SINGLE, OBJECT_FIELD_TYPE.OBJECT_LINK],
    fieldValueTypes: [
      // FIELD_VALUE_TYPE.BOOLEAN,
      // FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
  },
}

export const SEARCH_RULE_EXCEPTION = {
  [SEARCH_RULE_TYPE.CONTAIN]: RULES_EXCEPTIONS.DEFAULT,
  [SEARCH_RULE_TYPE.NOT_CONTAIN]: RULES_EXCEPTIONS.DEFAULT,
  [SEARCH_RULE_TYPE.EQUAL]: RULES_EXCEPTIONS.DEFAULT,
  [SEARCH_RULE_TYPE.NOT_EQUAL]: RULES_EXCEPTIONS.DEFAULT,
  [SEARCH_RULE_TYPE.STARTS_WITH]: RULES_EXCEPTIONS.DEFAULT,
  [SEARCH_RULE_TYPE.ENDS_WITH]: RULES_EXCEPTIONS.DEFAULT,
  [SEARCH_RULE_TYPE.EMPTY]: RULES_EXCEPTIONS.SINGLE,
  [SEARCH_RULE_TYPE.NOT_EMPTY]: RULES_EXCEPTIONS.SINGLE,
  [SEARCH_RULE_TYPE.MORE]: RULES_EXCEPTIONS.NUMERIC,
  [SEARCH_RULE_TYPE.LESS]: RULES_EXCEPTIONS.NUMERIC,
  [SEARCH_RULE_TYPE.MORE_OR_EQUAL]: RULES_EXCEPTIONS.NUMERIC,
  [SEARCH_RULE_TYPE.LESS_OR_EQUAL]: RULES_EXCEPTIONS.NUMERIC,
}

export const selectOptionsForRulesQuickSearch: selectOptionType[] = [
  {
    label: RULES_SEARCH.CONTAIN,
    value: RULES_SEARCH.CONTAIN,
    exception: RULES_EXCEPTIONS.DEFAULT,
  },
  {
    label: RULES_SEARCH.NOT_CONTAIN,
    value: RULES_SEARCH.NOT_CONTAIN,
    exception: RULES_EXCEPTIONS.DEFAULT,
  },
  {
    label: RULES_SEARCH.EQUAL,
    value: RULES_SEARCH.EQUAL,
    exception: RULES_EXCEPTIONS.DEFAULT,
  },
  {
    label: RULES_SEARCH.NOT_EQUAL,
    value: RULES_SEARCH.NOT_EQUAL,
    exception: RULES_EXCEPTIONS.SINGLE,
  },
  {
    label: RULES_SEARCH.STARTS_WITH,
    value: RULES_SEARCH.STARTS_WITH,
    exception: RULES_EXCEPTIONS.DEFAULT,
  },
  {
    label: RULES_SEARCH.ENDS_WITH,
    value: RULES_SEARCH.ENDS_WITH,
    exception: RULES_EXCEPTIONS.DEFAULT,
  },
  {
    label: RULES_SEARCH.EMPTY,
    value: RULES_SEARCH.EMPTY,
    exception: RULES_EXCEPTIONS.SINGLE,
  },
  {
    label: RULES_SEARCH.NOT_EMPTY,
    value: RULES_SEARCH.NOT_EMPTY,
    exception: RULES_EXCEPTIONS.SINGLE,
  },
  {
    label: RULES_SEARCH.MORE,
    value: RULES_SEARCH.MORE,
    exception: RULES_EXCEPTIONS.NUMERIC,
  },
  {
    label: RULES_SEARCH.LESS,
    value: RULES_SEARCH.LESS,
    exception: RULES_EXCEPTIONS.NUMERIC,
  },
  {
    label: RULES_SEARCH.MORE_OR_EQUAL,
    value: RULES_SEARCH.MORE_OR_EQUAL,
    exception: RULES_EXCEPTIONS.NUMERIC,
  },
  {
    label: RULES_SEARCH.LESS_OR_EQUAL,
    value: RULES_SEARCH.LESS_OR_EQUAL,
    exception: RULES_EXCEPTIONS.NUMERIC,
  },
]
