import { AutocompleteOption, POSTDefaultCriteria } from '@types'
/**
 * Cопоставляет два массива критериев и возвращает только те, у которых default = false после изменения
 * @param initialCriterias начальные критерии
 * @param newDefaultCriterias новые критерии
 * @returns массив измененных критериев, у которых default = true
 */
export const getFalseDefaultCriteriasByForm = (
  initialCriterias: AutocompleteOption<number>[],
  newDefaultCriterias: POSTDefaultCriteria[]
): POSTDefaultCriteria[] => {
  const criterias: POSTDefaultCriteria[] = []

  initialCriterias.forEach(({ id }) => {
    if (!newDefaultCriterias.find(criteria => id === criteria.id)) {
      criterias.push({
        id,
        // default: false
      })
    }
  })

  return criterias
}
