import { useState } from 'react'
import { UseFieldArrayAppend, UseFieldArrayRemove, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  useCreateTooltipMutation,
  useDeleteTooltipPointMutation,
  useUpdateTooltipJSONMutation,
  useUpdateTooltipMutation,
  useUpdateTooltipPointMutation,
} from '@redux/api/tooltips.api'

import { OPERATION_TYPE, ROUTES } from '@constants'
import { TooltipVariableRowType } from '@types'

import { TooltipForm } from '../types'

type useHandlersParams = {
  id: number | null
  methods: UseFormReturn<TooltipForm>
  append: UseFieldArrayAppend<any, any>
  remove: UseFieldArrayRemove
}

export const useHandlers = ({ id, methods, append, remove }: useHandlersParams) => {
  const navigate = useNavigate()
  const { handleSubmit, getValues } = methods

  const [currentTab, setCurrentTab] = useState<number>(0)
  const [currentRowId, setCurrentRowId] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)
  const [isShowScriptValueDialogJson, setShowScriptValueDialogJson] = useState<boolean>(false)
  const [isShowDeleteVariableModal, setIsShowDeleteVariableModal] = useState<boolean>(false)
  const [updatedVariable, setUpdatedVariable] = useState<TooltipVariableRowType | null>(null)

  const [createTooltip] = useCreateTooltipMutation()
  const [updateTooltip] = useUpdateTooltipMutation()
  const [updateTooltipJson, { isLoading: isLoadingTooltipJSON }] = useUpdateTooltipJSONMutation()
  const [updateTooltipPoint] = useUpdateTooltipPointMutation()
  const [deleteTooltipPoint] = useDeleteTooltipPointMutation()

  const handleSave = handleSubmit((tooltipData: TooltipForm) => {
    const data = {
      id: tooltipData.id ?? null,
      code: tooltipData.code,
      title: tooltipData.title,
      objectCode: tooltipData.object!.id,
      objectTitle: tooltipData.object!.label,
      width: tooltipData.width,
    }

    setIsLoading(true)

    if (tooltipData.id) {
      updateTooltip(data).finally(() => setIsLoading(false))
    } else {
      createTooltip(data)
        .unwrap()
        .then(res => navigate(`${ROUTES.CONFIG_TOOLTIPS_EDIT}/${res.id}`))
        .finally(() => setIsLoading(false))
    }
  })

  const handleCancel = () => navigate(`${ROUTES.CONFIG_TOOLTIPS}`)

  const handleTabChange = (event: React.SyntheticEvent, value: number) => setCurrentTab(value)

  const handleChangeJsonMode = (value: boolean) => updateTooltipJson({ id, isJson: value })

  const handleChangeValueScriptJson = (value: string) => {
    updateTooltipJson({
      id,
      isJson: true,
      jsonValue: value,
    })
  }

  const handleOpenPointDialog = () => setIsOpenDialog(true)

  const handleClosePointDialog = () => {
    setIsOpenDialog(false)
    setCurrentRowId(null)
  }

  const handleSavePoint = (data: any) => {
    updateTooltipPoint({
      id: currentRowId ?? null,
      tooltipId: id,
      title: data.title,
      bindType: data.bindType.id,
      value: data.value,
      background: data.background,
      conditionsHidden: data.conditionsHidden,
    }).then(() => handleClosePointDialog())
  }

  const handleEditPoint = (params: any) => {
    setIsOpenDialog(true)
    setCurrentRowId(params.row.original.id)
  }

  const handleDeletePoint = (id: number | string) => deleteTooltipPoint(id)

  const handleAddVariableItem = () => {
    append({ field: '', pathStr: '', pathArray: [] })
  }

  const handleSaveVariable = (variable: TooltipVariableRowType) => {
    const { id, code, title, object } = getValues()
    const { id: variableId, order, field, pathStr, pathArray } = variable

    updateTooltip({
      id,
      code,
      title,
      objectCode: object!.id,
      objectTitle: object!.label,
      updateTooltipVariables: [
        {
          operationType: variableId ? OPERATION_TYPE.UPDATE : OPERATION_TYPE.CREATE,
          type: 'VARIABLE',
          data: {
            id: variableId ?? null,
            tooltipId: id,
            order,
            field,
            pathStr,
            pathArray,
          },
        },
      ],
    })
  }

  const handleDeleteVariable = () => {
    const { id, code, title, object, variables } = getValues()

    const findIndex = variables?.findIndex(el => el.id === updatedVariable?.id)

    if (findIndex === -1) {
      return
    }

    updateTooltip({
      id,
      code,
      title,
      objectCode: object!.id,
      objectTitle: object!.label,
      updateTooltipVariables: [
        {
          operationType: OPERATION_TYPE.DELETE,
          type: 'VARIABLE',
          data: {
            id: updatedVariable?.id,
            tooltipId: id,
            order: updatedVariable?.order,
            field: updatedVariable?.field,
            pathStr: updatedVariable?.pathStr,
            pathArray: updatedVariable?.pathArray,
          },
        },
      ],
    }).then(() => setIsShowDeleteVariableModal(false))

    remove(findIndex)
  }

  const handleOpenDeleteVariableModal = (variable: TooltipVariableRowType, index: number) => {
    if (variable.id) {
      setIsShowDeleteVariableModal(true)
      setUpdatedVariable(variable)

      return
    }

    remove(index)
  }

  const handleCloseDeleteVariableModal = () => {
    setIsShowDeleteVariableModal(false)
  }

  return {
    data: {
      isLoadingTooltipJSON,
    },
    state: {
      currentTab,
      currentRowId,
      isOpenDialog,
      isShowScriptValueDialogJson,
      isShowDeleteVariableModal,
      isLoading,
    },
    handlers: {
      handleSave,
      handleCancel,
      handleTabChange,
      handleChangeJsonMode,
      handleChangeValueScriptJson,
      handleOpenPointDialog,
      handleClosePointDialog,
      handleSavePoint,
      handleEditPoint,
      handleDeletePoint,
      handleAddVariableItem,
      handleSaveVariable,
      handleDeleteVariable,
      handleOpenDeleteVariableModal,
      handleCloseDeleteVariableModal,
      setShowScriptValueDialogJson,
    },
  }
}
