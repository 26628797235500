import { useMemo } from 'react'
import { Position } from 'react-rnd'
import { ActionData } from '@microservices/gantt-react-component'

import { getActionBoolean, getInitialPositionWindow } from '@helpers'
import { EVENT_CODE, MENU_POINT_TYPES } from '@constants'
import { MenuPointOptionType, ViewActionRow } from '@types'

import { findActionByEventCode } from '../helpers'

type ActionHandlerType = (data: ActionData) => void

type ConfigActionType<T> = Omit<
  Record<EVENT_CODE, T>,
  EVENT_CODE.ON_COLUMN_RIGHT_CLICK | EVENT_CODE.ON_HEADER_TOOLTIP | EVENT_CODE.ON_CELL_TOOLTIP
> & {
  [EVENT_CODE.ON_COLUMN_RIGHT_CLICK]: Record<string | number, T>
  [EVENT_CODE.ON_HEADER_TOOLTIP]: Record<string | number, T>
  [EVENT_CODE.ON_CELL_TOOLTIP]: Record<string | number, T>
}

export type ConfigActionsType = {
  actionsAvailable: ConfigActionType<boolean>
  actionsData: ConfigActionType<ViewActionRow | null>
  actionHandlers: ConfigActionType<undefined | ActionHandlerType>
}

export const useGetActions = ({
  actions,
  onClickLink,
}: {
  actions: ViewActionRow[]
  onClickLink: (item: MenuPointOptionType, position?: Position) => void
}): ConfigActionsType => {
  const actionsAvailable: ConfigActionsType['actionsAvailable'] = {
    [EVENT_CODE.ON_ROW_EDIT]: false,
    [EVENT_CODE.ON_ROW_CLICK]: false,
    [EVENT_CODE.ON_ROW_ADDITIONAL_BUTTON_CLICK]: false,
    [EVENT_CODE.ON_ROW_CREATE]: false,
    [EVENT_CODE.ON_ROW_RIGHT_CLICK]: false,
    [EVENT_CODE.ON_COLUMN_RIGHT_CLICK]: {},
    [EVENT_CODE.ON_ROW_TOOLTIP]: false,
    [EVENT_CODE.ON_CELL_TOOLTIP]: {},
    [EVENT_CODE.ON_HEADER_TOOLTIP]: {},
  }

  const actionsData: ConfigActionsType['actionsData'] = {
    [EVENT_CODE.ON_ROW_EDIT]: null,
    [EVENT_CODE.ON_ROW_CLICK]: null,
    [EVENT_CODE.ON_ROW_ADDITIONAL_BUTTON_CLICK]: null,
    [EVENT_CODE.ON_ROW_CREATE]: null,
    [EVENT_CODE.ON_ROW_RIGHT_CLICK]: null,
    [EVENT_CODE.ON_COLUMN_RIGHT_CLICK]: {},
    [EVENT_CODE.ON_ROW_TOOLTIP]: null,
    [EVENT_CODE.ON_CELL_TOOLTIP]: {},
    [EVENT_CODE.ON_HEADER_TOOLTIP]: {},
  }

  const actionHandlers: ConfigActionsType['actionHandlers'] = {
    [EVENT_CODE.ON_ROW_EDIT]: undefined,
    [EVENT_CODE.ON_ROW_CLICK]: undefined,
    [EVENT_CODE.ON_ROW_ADDITIONAL_BUTTON_CLICK]: undefined,
    [EVENT_CODE.ON_ROW_CREATE]: undefined,
    [EVENT_CODE.ON_ROW_RIGHT_CLICK]: undefined,
    [EVENT_CODE.ON_COLUMN_RIGHT_CLICK]: {},
  }

  const handleAction = (data: ActionData, action: EVENT_CODE) => {
    const initialPosition = getInitialPositionWindow(data.metaData.event)

    switch (action) {
      case EVENT_CODE.ON_COLUMN_RIGHT_CLICK:
        if (data.metaData?.menuItem.menuPointType === MENU_POINT_TYPES.LINK) {
          onClickLink(data.metaData.menuItem, initialPosition)
        }
        break
      case EVENT_CODE.ON_ROW_RIGHT_CLICK:
        if (data.metaData?.menuItem.menuPointType === MENU_POINT_TYPES.LINK) {
          onClickLink(data.metaData.menuItem, initialPosition)
        }

        break
    }
  }

  return useMemo(() => {
    Object.values(EVENT_CODE).forEach(action => {
      const findAction = findActionByEventCode(action, actions) || null

      // т.к. для данного евента разные экшены по колонкам
      if (action === EVENT_CODE.ON_COLUMN_RIGHT_CLICK) {
        const findRightColumnActions = actions.filter(
          action => action.eventCode === EVENT_CODE.ON_COLUMN_RIGHT_CLICK
        )

        if (findRightColumnActions.length) {
          findRightColumnActions.forEach(findRightColumnAction => {
            if (findRightColumnAction.target) {
              actionsData[action][findRightColumnAction.target?.id] = findRightColumnAction
              actionsAvailable[action][findRightColumnAction.target?.id] =
                getActionBoolean(findRightColumnAction)
              actionHandlers[action][findRightColumnAction.target?.id] = data =>
                handleAction(data, action)
            }
          })
        }

        return
      }

      if (action === EVENT_CODE.ON_HEADER_TOOLTIP) {
        const findRightColumnActions = actions.filter(
          action => action.eventCode === EVENT_CODE.ON_HEADER_TOOLTIP
        )

        if (findRightColumnActions.length) {
          findRightColumnActions.forEach(findRightColumnAction => {
            if (findRightColumnAction.target) {
              actionsData[action][findRightColumnAction.target?.id] = findRightColumnAction
              actionsAvailable[action][findRightColumnAction.target?.id] =
                getActionBoolean(findRightColumnAction)
            }
          })
        }

        return
      }

      if (action === EVENT_CODE.ON_CELL_TOOLTIP) {
        const findRightColumnActions = actions.filter(
          action => action.eventCode === EVENT_CODE.ON_CELL_TOOLTIP
        )

        if (findRightColumnActions.length) {
          findRightColumnActions.forEach(findRightColumnAction => {
            if (findRightColumnAction.target) {
              actionsData[action][findRightColumnAction.target?.id] = findRightColumnAction
              actionsAvailable[action][findRightColumnAction.target?.id] =
                getActionBoolean(findRightColumnAction)
            }
          })
        }

        return
      }

      actionsData[action] = findAction
      actionsAvailable[action] = getActionBoolean(findAction)
      actionHandlers[action] = data => handleAction(data, action)
    })

    return { actionsAvailable, actionsData, actionHandlers }
  }, [actionsData, onClickLink])
}
