const translations = {
  plan: 'Planned number',
  fact: 'Actual number',
} as const

export function addLabels<T extends { dataKey: keyof typeof translations }>(series: T[]) {
  return series.map(item => ({
    ...item,
    label: translations[item.dataKey],
    valueFormatter: (v: number | null) => (v ? `${v.toLocaleString()} workers` : '-'),
  }))
}
