import { POSTDefaultCriteria } from '@types'

import { FormDefaultCriterias } from '../hooks'

/**
 * Получение из объекта формы массив заполненных критериев
 * @param data Объект значений из формы (useForm)
 * @returns массив дефолтных критериев
 */
export const getTransformDefaultCriteriasByForm = (
  data: FormDefaultCriterias
): POSTDefaultCriteria[] => {
  return Object.values(data)
    .filter(Boolean)
    .map(values =>
      values.map(({ id }, order) => ({
        id,
        // default: true,
        order,
      }))
    )
    .flat()
}
