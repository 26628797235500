import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { POSTCopySearchAssistantParams } from 'src/types'
import { FormGenerator, ModalWrapper } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { GENERATOR_INPUT_TYPE, REGEX } from '@constants'

type CopyDialogProps = {
  isShow: true
  onClose: () => void
  copyData: POSTCopySearchAssistantParams
  onCopy: (data: POSTCopySearchAssistantParams) => void
  models: (string | number)[]
  dialogTitle: string
  loading?: boolean
}

export type DialogCopyModelType = {
  internalId: string
  title: string
}

export const CopyDialog: FC<CopyDialogProps> = ({
  isShow,
  onClose,
  copyData,
  onCopy,
  models,
  dialogTitle,
  loading,
}) => {
  const methods = useForm<DialogCopyModelType>()
  const { t } = useTranslation()

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods

  useEffect(() => {
    reset({ title: copyData.title })
  }, [copyData])

  const handleSave = (formValue: DialogCopyModelType) => {
    const { internalId, title } = formValue

    onCopy({ internalId, title, id: copyData.id })
  }

  const handleClose = () => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }

    onClose()
  }

  return (
    <ModalWrapper
      containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      disabledSave={loading}
      isShow={isShow}
      title={dialogTitle}
      onClose={handleClose}
      onSave={handleSubmit(handleSave)}
    >
      <Box my={1}>
        <FormProvider {...methods}>
          <FormGenerator
            mainGridSpacing={0}
            inputs={[
              {
                name: 'internalId',
                inputType: GENERATOR_INPUT_TYPE.INPUT,
                label: t('searchAssistants.label.internalId'),
                placeholder: t('searchAssistants.placeholder.internalId'),
                replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
                rules: {
                  required: true,
                  validate: value => {
                    if (models.findIndex(model => model === value) !== -1) {
                      return `${value} ${t('columnInputs.errors.alreadyUsed')}`
                    }
                  },
                },
              },
              {
                name: 'title',
                inputType: GENERATOR_INPUT_TYPE.INPUT,
                label: t('searchAssistants.label.title'),
                placeholder: t('searchAssistants.placeholder.title'),
                rules: { required: true },
              },
            ]}
          />
        </FormProvider>
      </Box>
    </ModalWrapper>
  )
}
