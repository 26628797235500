export enum CATEGORIES {
  BINDING_ACTION = 'view_action_types',
  BINDING_EVENTS_ACTION = 'view_event_types',
  BINDING_EVENTS_ACTION_CONTEXT_MENU = 'view_context_menu_event_types',
  BINDING_EVENTS_ACTION_TOOLTIP = 'view_tooltip_event_types',
  BINDING_VIEW = 'view_row_binding_type',
  BINDING_TYPES_VIEW = 'view_row_binding_value_type',
  BINDING_COLUMN = 'view_column_value_binding_type',
  BINDING_TYPES_COLUMN = 'view_column_value_type',
  BINDING_RENDER_FIELD = 'form_field_rendering',
  BINDING_FIELD = 'form_field_value_types',
  BINDING_TYPES_FIELD = 'form_field_value_bindings_types',
  BINDING_RESOURCE_ACTION = 'gantt_action_resource_types',
  BINDING_EVENTS_RESOURCE_ACTION = 'gantt_event_resource_types',
  BINDING_TIMELINE_ACTION = 'gantt_action_timeline_types',
  BINDING_EVENTS_TIMELINE_ACTION = 'gantt_event_timeline_types',
}
