import { ACTION_CODE, EVENT_CODE } from '@constants'

import { Dictionary, GETShareColumn, Table, ViewActionRow } from '../types'

export const getAvailableActionEvents = (
  bindingValuesActionEvents?: Dictionary[],
  actionTable?: Table<ViewActionRow>,
  currentRow?: GETShareColumn | null,
  watchActionCode?: ACTION_CODE
) => {
  if (!bindingValuesActionEvents) {
    return []
  }

  const filteredActionEvents = filterActionEvents(
    bindingValuesActionEvents,
    actionTable,
    watchActionCode
  )

  if (currentRow) {
    if (currentRow.eventCode === EVENT_CODE.ON_COLUMN_RIGHT_CLICK) {
      return filteredActionEvents
    }

    const currentAction = bindingValuesActionEvents.find(
      actionEvent => actionEvent.code === currentRow.eventCode
    )

    if (currentAction) {
      filteredActionEvents.push(currentAction)
    }
  }

  return filteredActionEvents
}

const filterActionEvents = (
  bindingValuesActionEvents: Dictionary[],
  actionTable?: Table<ViewActionRow>,
  watchActionCode?: ACTION_CODE
): Dictionary[] => {
  let filteredActionEvents: Dictionary[]

  if (watchActionCode === ACTION_CODE.DELETE_OBJECT) {
    filteredActionEvents = bindingValuesActionEvents.filter(
      actionEvent =>
        actionEvent.code === EVENT_CODE.ON_ROW_DELETE_BUTTON_CLICK &&
        !actionTable?.rows.find(event => event.eventCode === actionEvent.code)
    )
  } else {
    filteredActionEvents = bindingValuesActionEvents.filter(
      actionEvent =>
        actionEvent.code !== EVENT_CODE.ON_ROW_DELETE_BUTTON_CLICK &&
        !actionTable?.rows.find(
          event =>
            event.eventCode === actionEvent.code &&
            event.eventCode !== EVENT_CODE.ON_COLUMN_RIGHT_CLICK
        )
    )
  }

  switch (watchActionCode) {
    case ACTION_CODE.OPEN_FORM_MODEL:
      return filteredActionEvents.filter(
        actionEvent => actionEvent.code !== EVENT_CODE.ON_ROW_ADDITIONAL_BUTTON_CLICK
      )
    case ACTION_CODE.OPEN_ANOTHER_FORM_MODEL:
      return filteredActionEvents.filter(
        actionEvent => actionEvent.code === EVENT_CODE.ON_ROW_ADDITIONAL_BUTTON_CLICK
      )
    default:
      return filteredActionEvents
  }
}
