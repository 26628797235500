import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

import {
  DIRTY_RULES,
  ENTITY,
  FIELD_VALUE_TYPE,
  QuickRuleType,
  SEARCH_RULE_TYPE,
} from '../../../constants'
import { EVENT_NAME, publish } from '../../../events'
import { changeGlobalCheckBoxInColumnHeader } from '../../../helpers'
import { ListQueryResponse, ScrollingParams, useSubscribeEvent } from '../../../hooks'
import {
  ALL_CHECKBOXES_IN_COLUMN_EVENT_DETAIL,
  CHECKBOX_IN_COLUMN_FOR_SEARCHING_EVENT_DETAIL,
  GLOBAL_CHECKBOX_IN_COLUMN_EVENT_DETAIL,
  ObjectDataRecord,
  ViewRow,
} from '../../../types'
import {
  getColumnFieldName,
  getInitialColumnVisibilityModel,
  getIsAvailableRuleByNames,
  getIsAvailableRuleByType,
  getSearchRuleForRequest,
  getSearchValueForRequest,
  getSortKeysForQuickSearchForRequest,
  getSortKeysOfColumnsForQuickSearch,
} from '../helpers'

type UseQuickSearchProps = {
  entityType: ENTITY
  searchRule: QuickRuleType
  sortKeysForQuickSearch: string[]
  valueSearchFilter: string
  viewColumns: ViewRow[]
  dialogId?: string
  setValueSearchFilter: Dispatch<SetStateAction<string>>
  setSortKeysForQuickSearch: Dispatch<SetStateAction<string[]>>
  setSearchRule: Dispatch<SetStateAction<QuickRuleType>>
  refreshObjectData: (
    params?: Omit<ScrollingParams, 'page'> | undefined,
    skip?: boolean | undefined
  ) => Promise<ListQueryResponse<ObjectDataRecord>> | undefined
}

export const useQuickSearch = ({
  entityType,
  searchRule,
  sortKeysForQuickSearch,
  valueSearchFilter,
  viewColumns,
  dialogId,
  setSearchRule,
  setValueSearchFilter,
  setSortKeysForQuickSearch,
  refreshObjectData,
}: UseQuickSearchProps) => {
  const [columnNameCheckBox, setColumnNameCheckBox] = useState<Record<string, boolean>>({})
  const [sortKeysColumnCheckBox, setSortKeysColumnCheckBox] = useState<Record<string, boolean>>({})
  // Состояние для выбора "режима" поиска: по умолчанию инпут с search полем типа string, или селект с
  // true/false в выпадающем списке для поиска boolean значений
  const [isBooleanSearchMode, setBooleanSearchMode] = useState(false)

  useEffect(() => {
    // для первоначальной инициализации строки быстрого поиска
    const isEmptyColumnNameCheckbox = isEmpty(columnNameCheckBox)
    const isEmptyColumnsSortKeysColumnCheckBox = isEmpty(sortKeysColumnCheckBox)

    if (isEmptyColumnNameCheckbox) {
      const initialColumnsNameCheckBox = getInitialColumnVisibilityModel(
        viewColumns,
        false,
        SEARCH_RULE_TYPE.CONTAIN
      )

      Object.keys(initialColumnsNameCheckBox).forEach(columnName => {
        const column = viewColumns.find(col => getColumnFieldName(col) === columnName)

        // Boolean колонки не выбираются в check all. Их можно выбрать только прямым нажатием.
        // При этом режим быстрого поиска поменяется на isBooleanSearchMode
        if (column?.valueType === FIELD_VALUE_TYPE.BOOLEAN) {
          initialColumnsNameCheckBox[columnName] = false
        }
      })

      setColumnNameCheckBox(initialColumnsNameCheckBox)
    }

    if (isEmptyColumnsSortKeysColumnCheckBox) {
      const initialColumnsSortKey = getInitialColumnVisibilityModel(
        viewColumns,
        true,
        SEARCH_RULE_TYPE.CONTAIN
      )

      if (entityType === ENTITY.DROP_DOWN_ENTITY) {
        const sortKeysOfColumnsForQuickSearch =
          getSortKeysOfColumnsForQuickSearch(initialColumnsSortKey)

        const sortKeysFilteredByAvailableSearchRules = sortKeysOfColumnsForQuickSearch.filter(
          sortKey => {
            const currentColumn = viewColumns.find(column => column.columnToSort === sortKey)

            if (!currentColumn) {
              return false
            }

            return !(
              !getIsAvailableRuleByNames(currentColumn, searchRule.name) ||
              !getIsAvailableRuleByType(currentColumn, searchRule.name)
            )
          }
        )

        setSortKeysForQuickSearch(sortKeysFilteredByAvailableSearchRules)

        return
      }

      setSortKeysColumnCheckBox(initialColumnsSortKey)
      setSortKeysForQuickSearch(getSortKeysOfColumnsForQuickSearch(initialColumnsSortKey))
    }
  }, [viewColumns])

  const handleSetBooleanMode = (value: boolean) => {
    setBooleanSearchMode(value)
  }

  const handleSetGlobalFilter = (value: string, skipRequest?: boolean) => {
    setValueSearchFilter(value)

    if (!isEmpty(sortKeysForQuickSearch)) {
      let booleanValue: string | boolean = ''

      if (isBooleanSearchMode) {
        if (value === 'true') {
          booleanValue = true
        }

        if (value === 'false') {
          booleanValue = false
        }
      }

      !skipRequest &&
        refreshObjectData({
          sortKeysForQuickSearch: getSortKeysForQuickSearchForRequest(
            sortKeysForQuickSearch,
            value,
            searchRule.name
          ),
          searchValue: isBooleanSearchMode
            ? booleanValue
            : getSearchValueForRequest(value, searchRule.name),
          searchRule: getSearchRuleForRequest(value, searchRule.name),
        })
    }
  }

  const handleSetSortKeysForQuickSearch = (value: string[]) => setSortKeysForQuickSearch(value)
  const handleSetSortKeysColumnCheckBox = (value: Record<string, boolean>) =>
    setSortKeysColumnCheckBox(value)

  const handleSetColumnNameCheckBox = (value: Record<string, boolean>) =>
    setColumnNameCheckBox(value)

  const handleSubscibeCheckboxInColumnForSearching = useCallback(
    (data: { detail: CHECKBOX_IN_COLUMN_FOR_SEARCHING_EVENT_DETAIL }) => {
      const {
        searchValue,
        searchRule,
        sortKeys,
        titleColumns,
        value,
        sortKey,
        columnName,
        scrollingParams,
        dialogId: detailDialogId,
        wasSearchModeChanged,
        visibleColumns,
      } = data.detail

      if (detailDialogId !== dialogId) {
        return
      }

      const sortKeysCheckbox = { ...sortKeys }
      const columnNameCheckBox = { ...titleColumns }

      columnNameCheckBox[columnName] = value

      // TODO: Выглядит как костыль, сделал, чтобы не тратить много часов на дефект
      // Обработка случая, чтобы две колонки с одинаковым sortKey не влияли друг на друга
      // Например, когда выбраны две колонки и одну отключаем, чтобы sortKeys не сбрасывался
      // и отправлялся дальше на бэк
      const isAlreadyUsedOtherColumn = Object.keys(columnNameCheckBox).some(key => {
        if (key === columnName) {
          return false
        }

        const findColumn = visibleColumns.find(col => key === col.code)

        if (findColumn?.columnToSort === sortKey && columnNameCheckBox[key]) {
          return true
        }
      })

      if (!isAlreadyUsedOtherColumn) {
        sortKeysCheckbox[sortKey] = value
      }

      const newSortKeysForQuickSearch = getSortKeysOfColumnsForQuickSearch(sortKeysCheckbox)

      setColumnNameCheckBox(columnNameCheckBox)
      setSortKeysForQuickSearch(newSortKeysForQuickSearch)
      setSortKeysColumnCheckBox(sortKeysCheckbox)

      const isEmptySearchValue = !searchValue && DIRTY_RULES.includes(searchRule.name)

      if (!isEmpty(newSortKeysForQuickSearch) && (!isEmptySearchValue || wasSearchModeChanged)) {
        refreshObjectData(
          {
            ...scrollingParams,
            sortKeysForQuickSearch: getSortKeysForQuickSearchForRequest(
              newSortKeysForQuickSearch,
              searchValue,
              searchRule.name
            ),
            searchValue: getSearchValueForRequest(searchValue, searchRule.name),
            searchRule: getSearchRuleForRequest(searchValue, searchRule.name),
          },
          false
        )
      }

      // Условие для случая, когда не выбрано ни одного чекбокса
      if (isEmpty(newSortKeysForQuickSearch)) {
        refreshObjectData(
          {
            ...scrollingParams,
            sortKeysForQuickSearch: getSortKeysForQuickSearchForRequest(
              newSortKeysForQuickSearch,
              searchValue,
              searchRule.name
            ),
            searchValue: getSearchValueForRequest(searchValue, searchRule.name),
            searchRule: getSearchRuleForRequest(searchValue, searchRule.name),
          },
          false
        )
      }
    },
    []
  )

  const handleSetSearchRule = (
    { exception, name: newSearchRuleName }: QuickRuleType,
    allCheckCheckbox: boolean
  ) => {
    setSearchRule({ name: newSearchRuleName, exception })

    const emptyOrIsNotEmptyRule =
      newSearchRuleName === SEARCH_RULE_TYPE.EMPTY ||
      newSearchRuleName === SEARCH_RULE_TYPE.NOT_EMPTY

    if (exception) {
      let searchValue = valueSearchFilter
      let newSortKeysForQuickSearch = getSortKeysOfColumnsForQuickSearch(sortKeysColumnCheckBox)
      const isEmptySearchValue = !searchValue && DIRTY_RULES.includes(searchRule.name)

      if (allCheckCheckbox) {
        const { newColumnNameCheckbox, newSortKeysColumnCheckBox } =
          changeGlobalCheckBoxInColumnHeader(
            columnNameCheckBox,
            sortKeysColumnCheckBox,
            true,
            setColumnNameCheckBox,
            setSortKeysColumnCheckBox,
            newSearchRuleName,
            viewColumns,
            isBooleanSearchMode
          )

        newSortKeysForQuickSearch = getSortKeysOfColumnsForQuickSearch(newSortKeysColumnCheckBox)

        const globalCheckboxInColumnDetail: GLOBAL_CHECKBOX_IN_COLUMN_EVENT_DETAIL = {
          columnNameCheckbox: newColumnNameCheckbox,
          dialogId,
        }

        setSortKeysForQuickSearch(newSortKeysForQuickSearch)

        publish(EVENT_NAME.GLOBAL_CHECKBOX_IN_COLUMN, globalCheckboxInColumnDetail)
      }

      // если выбрано правило того же типа, что и предыдущее, то ничего не делаем
      if (
        searchRule.exception === exception &&
        isEmpty(newSortKeysForQuickSearch) &&
        isEmptySearchValue
      ) {
        return
      }

      // т.к. может не успеть обновиться setState
      // при новом exception сбрасываем строку поиска для EMPTY и NOT_EMPTY
      if (!DIRTY_RULES.includes(newSearchRuleName)) {
        searchValue = ''
        setValueSearchFilter(searchValue)
      }

      if (emptyOrIsNotEmptyRule || (!isEmpty(newSortKeysForQuickSearch) && isEmptySearchValue)) {
        refreshObjectData(
          {
            sortKeysForQuickSearch: getSortKeysForQuickSearchForRequest(
              newSortKeysForQuickSearch,
              searchValue,
              newSearchRuleName
            ),
            searchValue: getSearchValueForRequest(searchValue, newSearchRuleName),
            searchRule: getSearchRuleForRequest(searchValue, newSearchRuleName),
          },
          false
        )
      }
    }
  }

  useSubscribeEvent(
    EVENT_NAME.ALL_CHECKBOXES_IN_COLUMN,
    (data: { detail: ALL_CHECKBOXES_IN_COLUMN_EVENT_DETAIL }) => {
      const {
        searchRule,
        searchValue,
        sortKeys,
        scrollingParams,
        dialogId: detailDialogId,
      } = data.detail

      if (detailDialogId !== dialogId) {
        return
      }

      const newSortKeysForQuickSearch = getSortKeysOfColumnsForQuickSearch(sortKeys)
      setSortKeysForQuickSearch(newSortKeysForQuickSearch)

      const isEmptySearchValue = !searchValue && DIRTY_RULES.includes(searchRule.name)

      if (!isEmpty(newSortKeysForQuickSearch) && !isEmptySearchValue) {
        refreshObjectData({
          ...scrollingParams,
          sortKeysForQuickSearch: getSortKeysForQuickSearchForRequest(
            newSortKeysForQuickSearch,
            searchValue,
            searchRule.name
          ),
          searchValue: getSearchValueForRequest(searchValue, searchRule.name),
          searchRule: getSearchRuleForRequest(searchValue, searchRule.name),
        })
      }

      // Условие для случая, когда не выбрано ни одного чекбокса
      if (isEmpty(newSortKeysForQuickSearch)) {
        refreshObjectData(
          {
            ...scrollingParams,
            sortKeysForQuickSearch: getSortKeysForQuickSearchForRequest(
              newSortKeysForQuickSearch,
              searchValue,
              searchRule.name
            ),
            searchValue: getSearchValueForRequest(searchValue, searchRule.name),
            searchRule: getSearchRuleForRequest(searchValue, searchRule.name),
          },
          false
        )
      }
    }
  )

  useSubscribeEvent(
    EVENT_NAME.CHECKBOX_IN_COLUMN_FOR_SEARCHING,
    handleSubscibeCheckboxInColumnForSearching
  )

  return {
    state: {
      columnNameCheckBox,
      sortKeysColumnCheckBox,
      isBooleanSearchMode,
    },
    handlers: {
      handleSetGlobalFilter,
      handleSetSearchRule,
      handleSetSortKeysForQuickSearch,
      handleSetSortKeysColumnCheckBox,
      handleSetColumnNameCheckBox,
      handleSetBooleanMode,
    },
  }
}
