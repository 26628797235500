import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Tooltip } from '@mui/material'

export const displayToggleOptions = [
  {
    value: true,
    icon: (
      <Tooltip title='Show'>
        <VisibilityIcon />
      </Tooltip>
    ),
  },
  {
    value: false,
    icon: (
      <Tooltip title='Hide'>
        <VisibilityOffIcon />
      </Tooltip>
    ),
  },
  {
    value: '',
    icon: (
      <Tooltip title='Inherit'>
        <SettingsBackupRestoreIcon />
      </Tooltip>
    ),
  },
]
