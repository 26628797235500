import { useEffect, useState } from 'react'
import { isArray, range } from 'lodash'
import moment from 'moment'

import { getFactDutyRequestParams } from '@pages/BarChartPage/helpers/getFactDutyRequestParams'
import { getHourIntervalLabel } from '@pages/BarChartPage/helpers/getHourIntervalLabel'
import { getHoursInterval } from '@pages/BarChartPage/helpers/getHoursInterval'
import { getPlanDutyRequestParams } from '@pages/BarChartPage/helpers/getPlanDutyRequestParams'
import { mergePlanAndFactDatasets } from '@pages/BarChartPage/helpers/mergePlanAndFactDatasets'

import { useFetchStorageAggregateMutation, useFetchStorageMutation } from '@redux/api'

import { AutocompleteOption } from '@types'

const hoursKeys = range(24).map(i => `h${i}`)

export type DatasetParams = {
  planDay: string
  department: AutocompleteOption<string> | null
  qual: AutocompleteOption<string> | null
  // planQual: AutocompleteOption<string> | null
  // staffQual: AutocompleteOption<string>[]
}

type DatasetType = {
  hour: string
  from: string
  to: string
  fact: number
  plan: number
}

export const useDataset = (datasetParams: DatasetParams) => {
  const [dataset, setDataset] = useState<DatasetType[]>([])
  const [loading, setLoading] = useState(false)

  const [fetchStorage] = useFetchStorageMutation()
  const [fetchStorageAggregate] = useFetchStorageAggregateMutation()

  useEffect(() => {
    setLoading(true)

    fetchStorage(
      getPlanDutyRequestParams({
        planDay: datasetParams.planDay,
        planQual: datasetParams.qual?.id || '',
      })
    ) // Загружаем плановый список
      .unwrap()
      .then(staffPlanArray => {
        if (isArray(staffPlanArray)) {
          // План на день
          const staffPlanForDay = staffPlanArray[0] // Пока берём первый день, но по итогу нужно фильтровать по конкретному дню в запросе

          const hasNoPlanForDay = staffPlanForDay == undefined
          if (hasNoPlanForDay) {
            setDataset([])
            setLoading(false)

            return
          }
          const currentDay = moment(staffPlanForDay.day)

          const staffPlanDataset: any[] = []

          if (!staffPlanForDay) {
            setLoading(false)

            return
          }

          const requests = hoursKeys.map((hourKey, index) => {
            const { startHourInterval, endHourInterval } = getHoursInterval(currentDay, index)

            const plan = staffPlanForDay[hourKey] || 0

            staffPlanDataset.push({
              hour: getHourIntervalLabel(startHourInterval, endHourInterval),
              from: startHourInterval.toISOString(),
              to: endHourInterval.toISOString(),
              plan,
            })

            return fetchStorageAggregate(
              getFactDutyRequestParams(
                startHourInterval.toISOString(),
                endHourInterval.toISOString(),
                datasetParams.department?.id || '',
                // TODO Харкодно фильтруем по квалификации сотрудника 'ALL' (потому-что такой у него нет)
                datasetParams.qual?.id ? [datasetParams.qual.id].filter(qual => qual !== 'ALL') : []
                // datasetParams.staffQual.map(qual => qual.id)
              )
            ).unwrap()
          })

          Promise.all(requests)
            .then(staffFactDataset => {
              const staffDataset = mergePlanAndFactDatasets(staffPlanDataset, staffFactDataset)
              setDataset(staffDataset)
            })
            .catch(err => {
              setDataset([])
              console.error(err)
            })
            .finally(() => {
              setLoading(false)
            })
        }
      })
  }, [datasetParams])

  return { dataset, loading }
}
