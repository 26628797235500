import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { AutocompleteOption } from '@types'

type ResetObjectValueOnValueNameChangeOptions = {
  watchedValueName: AutocompleteOption | null
  objectValueInputName: string
  fieldTypeInputName: string
}

export const useResetValuesOnValueNameChange = ({
  watchedValueName,
  objectValueInputName,
  fieldTypeInputName,
}: ResetObjectValueOnValueNameChangeOptions) => {
  const {
    setValue,
    formState: { isDirty },
  } = useFormContext()

  useEffect(() => {
    if (isDirty) {
      setValue(objectValueInputName, '')
      setValue(fieldTypeInputName, null)
    }
  }, [watchedValueName])
}
