import { FC } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Button, FormInput } from '@microservices/wiskey-react-components'
import { Box, Grid, Typography } from '@mui/material'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { PageTitle } from '@components/PageTitle'

import { GENERATOR_INPUT_TYPE, VISIBLE_HIDDEN } from '@constants'

import { CopyDialog } from './components/CopyDialog'
import { useConfigTooltips } from './hooks/useConfigTooltips'
import { AllTooltipsTable } from './AllTooltipsTable'

export const ConfigTooltips: FC = () => {
  const { t } = useTranslation()

  const {
    state: { methods, allTooltips, showCopyDialog, copyTooltipData, isLoadingCopyTooltip },
    data: { isAllTooltips, isLoadingTooltips },
    handlers: {
      handleShowDialog,
      handleCloseDialog,
      handleCopyTooltip,
      handleCreateTooltip,
      handleEditTooltip,
      handleDeleteTooltip,
    },
  } = useConfigTooltips()

  return (
    <>
      {showCopyDialog && copyTooltipData && (
        <CopyDialog
          copyData={copyTooltipData}
          dialogTitle={t('copying.context.dialogTitle')}
          isShow={showCopyDialog}
          loading={isLoadingCopyTooltip}
          models={allTooltips.map(form => form.code)}
          onClose={handleCloseDialog}
          onCopy={handleCopyTooltip}
        />
      )}
      <Outlet />
      <Box sx={{ ...(!isAllTooltips && VISIBLE_HIDDEN) }}>
        <PageTitle
          end={false}
          start={
            <>
              <Typography display='inline' sx={{ mr: 1, ml: 1 }} variant='h6'>
                {t('pageTitle.tooltips')}
              </Typography>
            </>
          }
        />
        <PageContentLayout>
          <Grid container item alignItems='center' flexWrap='nowrap' gap={1} p={1}>
            <Grid item>
              <Button sx={{ mb: 1 }} variant='contained' onClick={handleCreateTooltip}>
                {t(`tooltips.button.addTooltip`)}
              </Button>
            </Grid>
            <Grid item flexGrow={1}>
              <FormProvider {...methods}>
                <form>
                  <Grid item sm={12} xs={12}>
                    <FormInput
                      inputType={GENERATOR_INPUT_TYPE.INPUT}
                      name='searchPattern'
                      placeholder={t(`tooltips.placeholder.search`)}
                      size='small'
                    />
                  </Grid>
                </form>
              </FormProvider>
            </Grid>
          </Grid>
          <AllTooltipsTable
            loading={isLoadingTooltips}
            rows={allTooltips}
            onCopy={handleShowDialog}
            onDelete={handleDeleteTooltip}
            onEdit={handleEditTooltip}
          />
        </PageContentLayout>
      </Box>
    </>
  )
}
