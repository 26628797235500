import { SelectOption } from '@microservices/wiskey-react-components'

export enum ALIGNMENTS {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  // CENTER: 'CENTER'
}

export const ALIGNMENTS_OPTIONS: SelectOption[] = [
  {
    id: 0,
    name: ALIGNMENTS.LEFT,
    value: ALIGNMENTS.LEFT,
  },
  {
    id: 1,
    name: ALIGNMENTS.RIGHT,
    value: ALIGNMENTS.RIGHT,
  },
  // {
  //   id: 2,
  //   name: ALIGNMENTS.CENTER,
  //   value: ALIGNMENTS.CENTER,
  // },
]
