import { useContext } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import i18next from 'i18next'
import { FormInputProps, SelectOption } from '@microservices/wiskey-react-components'

import { PageContext } from '@pages/EntityCreateOrEdit'

import { ACTION_CODE, EVENT_CODE, GENERATOR_INPUT_TYPE, REGEX } from '@constants'
import { AutocompleteOption } from '@types'

export type ActionForm = {
  id?: number | string
  code: string
  title: string
  actionCode?: ACTION_CODE
  actionTitle?: string
  eventCode?: EVENT_CODE
  eventTitle?: string
  params?: string
  columnToSort?: AutocompleteOption | null
  order?: string | number
  json?: boolean
  objectFormCode?: AutocompleteOption | null
  targetColumn?: AutocompleteOption | null
  formObject: AutocompleteOption | null
  contextMenu?: AutocompleteOption<number> | null
  tooltip?: AutocompleteOption<number> | null
}

type useInputsParams = {
  setValue: UseFormSetValue<ActionForm>
  actionTypeOptions: SelectOption[]
  contextMenusOptions: AutocompleteOption[]
  tooltipOptions: AutocompleteOption[]
  targetColumnsOptions: AutocompleteOption[]
  eventOptions: SelectOption[]
  objectOptions: AutocompleteOption[]
  watchJson?: boolean
  watchEvent?: EVENT_CODE
  watchActionCode?: ACTION_CODE
  watchFormObject?: AutocompleteOption | null
  formCodeOptions: AutocompleteOption[]
  loadingAllForm?: boolean
  loadingBindingValuesEvents?: boolean
  isAllObjectsLoading: boolean
  isOpenFormAction: boolean
}

export const useInputs = ({
  setValue,
  actionTypeOptions,
  eventOptions,
  objectOptions,
  watchJson,
  watchEvent,
  watchActionCode,
  watchFormObject,
  formCodeOptions,
  loadingAllForm,
  targetColumnsOptions,
  contextMenusOptions,
  tooltipOptions,
  loadingBindingValuesEvents,
  isAllObjectsLoading,
  isOpenFormAction,
}: useInputsParams) => {
  const { modalType } = useContext(PageContext)

  const isRightColumnClick = [
    EVENT_CODE.ON_COLUMN_RIGHT_CLICK,
    EVENT_CODE.ON_HEADER_TOOLTIP,
    EVENT_CODE.ON_CELL_TOOLTIP,
  ].includes(watchEvent as EVENT_CODE)

  const isContextMenu = watchActionCode === ACTION_CODE.OPEN_CONTEXT_MENU
  const isTooltip = watchActionCode === ACTION_CODE.OPEN_TOOLTIP
  const isEmptyActionCode = watchActionCode === i18next.t('placeholder.actionType')

  const targetInput = isRightColumnClick
    ? [
        {
          name: 'targetColumn',
          inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
          placeholder: i18next.t('entityActionDialog.targetColumn.placeholder'),
          label: i18next.t('entityActionDialog.targetColumn.label'),
          autocompleteOptions: targetColumnsOptions,
          loading: loadingAllForm,
          rules: { required: true },
        },
      ]
    : []

  const contextMenuOrTooltipInput = isContextMenu
    ? [
        ...targetInput,
        {
          name: 'contextMenu',
          inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
          placeholder: i18next.t('entityActionDialog.contextMenu.placeholder'),
          label: i18next.t('entityActionDialog.contextMenu.label'),
          autocompleteOptions: contextMenusOptions,
          loading: loadingAllForm,
          rules: { required: true },
        },
      ]
    : [
        ...targetInput,
        {
          name: 'tooltip',
          inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
          placeholder: i18next.t('entityActionDialog.tooltip.placeholder'),
          label: i18next.t('entityActionDialog.tooltip.label'),
          autocompleteOptions: tooltipOptions,
          loading: loadingAllForm,
          rules: { required: true },
        },
      ]

  const actionValueInputs = [
    {
      name: 'formCodeRow',
      inputs: [
        ...(isOpenFormAction && !watchJson
          ? [
              {
                name: 'formObject',
                inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                label: i18next.t('actionInputs.formObject.label'),
                placeholder: i18next.t('actionInputs.formObject.placeholder'),
                autocompleteOptions: objectOptions,
                loading: isAllObjectsLoading,
                rules: {
                  required: true,
                  validate: (value: string) =>
                    value !== i18next.t('actionInputs.formObject.placeholder'),
                },
                onChangeAutocomplete: () => {
                  setValue('objectFormCode', null)
                },
              },
              {
                name: 'objectFormCode',
                inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
                placeholder: i18next.t('placeholder.objectFormCode'),
                label: i18next.t('label.objectFormCode'),
                autocompleteOptions: formCodeOptions,
                loading: loadingAllForm,
                rules: { required: true },
                disabled: !watchFormObject,
              },
            ]
          : []),
      ],
    },
    {
      name: 'json',
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: i18next.t('label.json'),
      labelPlacement: 'end',
    },
    ...(watchJson
      ? [
          {
            name: 'params',
            inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
            label: i18next.t('actionInputs.params.label'),
            placeholder: i18next.t('actionInputs.params.placeholder'),
            rules: { required: true, validate: value => value.trim().length !== 0 },
          },
        ]
      : []),
  ]

  const actionInputs: FormInputProps[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('actionInputs.code.placeholder'),
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      label: i18next.t('actionInputs.code.label'),
      disabled: modalType === 'edit',
      rules: { required: true, validate: value => value.trim().length !== 0 },
    },
    {
      name: 'title',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('actionInputs.title.placeholder'),
      label: i18next.t('actionInputs.title.label'),
      rules: { required: true, validate: value => value.trim().length !== 0 },
    },
    {
      name: 'actionCode',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      label: i18next.t('actionInputs.actionCode.label'),
      placeholder: i18next.t('placeholder.actionType'),
      selectOptions: actionTypeOptions,
      rules: { required: true, validate: value => value !== i18next.t('placeholder.actionType') },
      onChangeSelect: value => {
        if (value !== watchActionCode) {
          setValue('eventCode', i18next.t('placeholder.event'), { shouldDirty: true })
        }

        setValue('objectFormCode', null)
        setValue('formObject', null)
      },
    },
    {
      name: 'eventCode',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      label: i18next.t('actionInputs.eventCode.label'),
      placeholder: i18next.t('placeholder.event'),
      selectOptions: isEmptyActionCode ? [] : eventOptions,
      loading: loadingBindingValuesEvents,
      rules: { required: true, validate: value => value !== i18next.t('placeholder.event') },
    },
    ...(isContextMenu || isTooltip ? contextMenuOrTooltipInput : actionValueInputs),
  ]

  return actionInputs
}
