import { ACTION_CODE } from '@constants'

import { ViewActionRow } from '../types'

// This helper tells whether action can be executed or not (usage: views/ConviguredView)
export const getActionBoolean = (
  action: ViewActionRow | null | undefined,
  actionCode?: ACTION_CODE
): boolean => {
  const formInParams = Boolean(action && action.params && Object.keys(action.params).length > 0)
  const hasContextMenu = Boolean(action?.contextMenu)
  const hasTooltip = Boolean(action?.tooltip)

  if (actionCode && actionCode === ACTION_CODE.DELETE_OBJECT) {
    return true
  }

  if (formInParams) {
    return true
  }

  if (action && Boolean(action.objectFormCode)) {
    return true
  }

  if (hasContextMenu || hasTooltip) {
    return true
  }

  return false
}
