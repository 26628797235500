import { useAddDefaultCriteriaMutation } from '@redux/api'

import { POSTDefaultCriteria } from '@types'

export const useHandlers = () => {
  const [addDefaultCriterias] = useAddDefaultCriteriaMutation()

  const handleAddDefaultCriterias = (values: POSTDefaultCriteria[]) => addDefaultCriterias(values)

  return {
    handlers: {
      handleAddDefaultCriterias,
    },
  }
}
