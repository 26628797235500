import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { FormGenerator, ModalWrapper } from '@microservices/wiskey-react-components'
import { Box, CircularProgress, Grid } from '@mui/material'

import { BindTypeInputs } from '@components/BindTypeInputs'
import { ModalFormContainer } from '@components/ModalFormContainer'

import { ACTION_CODE, EVENT_CODE_GANTT } from '@constants'
import { SelectOption } from '@types'

import { AccordionConfigExamples, BtnCopyConfigExample } from '@gantt/components/ConfigExamples'
import {
  AvailableVariables,
  CONTEXT_MENU_BIND_TYPE,
  GANTT_BIND_TYPE_LIST,
  OPEN_MODAL_BIND_TYPE,
  PageContext,
  TOOLTIP_BINDING_TYPE,
} from '@gantt/components/GanttCreateOrEdit'
import {
  contextMenuAvailableKeys,
  HEADER_MAIN_DATA,
  initialAvailableVariables,
  JS_FUN_ARGS,
  tooltipAvailableKeys,
} from '@gantt/constants'
import {
  checkValidGanttJS,
  checkValidGanttJSON,
  getBindTypeOptionsByEnum,
  getOptionByStr,
} from '@gantt/helpers'
import { GANTT_SCRIPT_RETURN_TYPE } from '@gantt/types'

import { VariablesList } from '../GanttConfiguration/components/VariablesList'

import { useAddGanttActionDialog } from './hooks/useAddGanttActionDialog'

const getHints = (actionCode: ACTION_CODE) => {
  return {
    json:
      actionCode === ACTION_CODE.OPEN_CONTEXT_MENU
        ? i18next.t('ganttCreate.resourceForm.hint.contextMenuJsonHint')
        : undefined,
  }
}

const tooltipHints = {
  js: i18next.t('ganttCreate.resourceForm.hint.tooltipScriptHint'),
  json: i18next.t('ganttCreate.resourceForm.hint.tooltipJsonHint'),
}

const tooltipPlaceholders = {
  js: i18next.t('ganttCreate.resourceForm.placeholder.tooltipJS'),
}

const commandPlaceholders = {
  command: i18next.t('placeholder.selectCommand'),
}

const formPlaceholders = {
  form: i18next.t('placeholder.objectFormCode'),
}

export const AddGanttActionDialog = () => {
  const {
    isDirty,
    isLoading,
    key,
    actionInputs,
    formCodeOptions,
    methods,
    handleSave,
    handleSubmit,
    watchActionFieldBindType,
  } = useAddGanttActionDialog()

  const { t } = useTranslation()
  const {
    showDialog,
    handleCloseModal,
    watchedObjectResource,
    availableVariables,
    currentActionResourceList,
  } = useContext(PageContext)
  const { watch } = methods
  const watchActionCode = watch('actionCode')
  const watchEventCode = watch('eventCode')
  const [isInvalidScript, setIsInvalidScript] = useState(false)

  const bindings: SelectOption[] = useMemo(() => {
    if (watchActionCode === ACTION_CODE.OPEN_CONTEXT_MENU) {
      return getBindTypeOptionsByEnum(CONTEXT_MENU_BIND_TYPE)
    }

    if (watchActionCode === ACTION_CODE.OPEN_FORM_MODEL) {
      return [
        getOptionByStr(OPEN_MODAL_BIND_TYPE.FORM, t('ganttCreate.resourceForm.bindTypeForm')),
        getOptionByStr(OPEN_MODAL_BIND_TYPE.JSON),
      ]
    }

    if (watchActionCode === ACTION_CODE.EXECUTE_COMMAND) {
      return [getOptionByStr(GANTT_BIND_TYPE_LIST.COMMANDS)]
    }

    if (watchActionCode === ACTION_CODE.OPEN_TOOLTIP) {
      return [
        getOptionByStr(TOOLTIP_BINDING_TYPE.JSON),
        getOptionByStr(TOOLTIP_BINDING_TYPE.JS),
        getOptionByStr(TOOLTIP_BINDING_TYPE.STATIC),
      ]
    }

    return []
  }, [watchActionCode])

  const availableVariablesByEventCode: AvailableVariables = useMemo(() => {
    const data = initialAvailableVariables()

    switch (watchEventCode) {
      case EVENT_CODE_GANTT.ON_TOOLTIP_ROW:
        return availableVariables
      case EVENT_CODE_GANTT.ON_TOOLTIP_HEADER:
        data[JS_FUN_ARGS.mainData] = HEADER_MAIN_DATA[JS_FUN_ARGS.mainData].concat(
          availableVariables[JS_FUN_ARGS.mainData]
        )
        data[JS_FUN_ARGS.metaData] = availableVariables[JS_FUN_ARGS.metaData]
        data[JS_FUN_ARGS.restData] = availableVariables[JS_FUN_ARGS.restData]
        break
      default:
        return availableVariables
    }

    return { ...data }
  }, [watchActionCode, watchEventCode, availableVariables])

  const validateValue = useMemo(
    () =>
      (
        value: string,
        returnValueType: GANTT_SCRIPT_RETURN_TYPE,
        keys?: string[],
        bindType?: GANTT_BIND_TYPE_LIST.JS | GANTT_BIND_TYPE_LIST.JSON
      ) => {
        if (bindType === GANTT_BIND_TYPE_LIST.JSON) {
          return checkValidGanttJSON(value, returnValueType, keys)
        }

        return checkValidGanttJS(value, availableVariablesByEventCode, returnValueType, keys)
      },
    [availableVariablesByEventCode, watchActionFieldBindType]
  )

  const funValidator = useCallback(
    (value: string, availableKeys: string[]) => {
      if (
        GANTT_BIND_TYPE_LIST.JSON === watchActionFieldBindType ||
        GANTT_BIND_TYPE_LIST.JS === watchActionFieldBindType
      ) {
        return validateValue(
          value,
          GANTT_SCRIPT_RETURN_TYPE.ARRAY_OF_OBJECTS,
          availableKeys,
          watchActionFieldBindType
        )
      }
    },
    [watchActionFieldBindType]
  )

  const funValidatorContextMenu = useCallback(
    (value: string) => funValidator(value, contextMenuAvailableKeys),
    [watchActionFieldBindType]
  )

  const funValidatorTooltip = useCallback(
    (value: string) => funValidator(value, tooltipAvailableKeys),
    [watchActionFieldBindType]
  )

  const handleClose = useCallback(() => {
    handleCloseModal(isDirty)
  }, [handleCloseModal, isDirty])

  const handleAfterValidationScript = useCallback((state: boolean) => {
    setIsInvalidScript(state)
  }, [])

  useEffect(() => {
    handleAfterValidationScript(false)
  }, [watchActionFieldBindType])

  const formInput = useMemo(() => {
    const selectedForms = currentActionResourceList
      .map(action => {
        if (action.actionField.bindType === GANTT_BIND_TYPE_LIST.FORM)
          return action.actionField.form.id

        return ''
      })
      .filter(item => item)

    return {
      selectedOptions: selectedForms,
    }
  }, [currentActionResourceList])

  return (
    <ModalWrapper
      btnText={t('ganttCreate.common.apply')}
      disabledSave={!isDirty || isInvalidScript}
      isShow={showDialog}
      loading={isLoading}
      onClose={handleClose}
      onSave={handleSubmit(handleSave)}
    >
      {isLoading ? (
        <Grid container alignItems='center' justifyContent='center' my={3}>
          <CircularProgress />
        </Grid>
      ) : (
        <ModalFormContainer
          key={`AddGanttActionDialog_${String(key)}`}
          maxHeight={500}
          minWidth={900}
        >
          <FormProvider {...methods}>
            <form>
              <FormGenerator inputs={actionInputs} />
              {watchActionCode === ACTION_CODE.OPEN_TOOLTIP && (
                <>
                  <BindTypeInputs
                    bindTypeOptions={bindings}
                    containerName='actionField'
                    hintDict={tooltipHints}
                    object={watchedObjectResource}
                    placeholderDict={tooltipPlaceholders}
                    scriptEditor={{
                      validator: funValidatorTooltip,
                      onAfterValidation: handleAfterValidationScript,
                    }}
                  />
                  {watchActionFieldBindType &&
                    [GANTT_BIND_TYPE_LIST.JS, GANTT_BIND_TYPE_LIST.JSON].includes(
                      watchActionFieldBindType
                    ) && (
                      <AccordionConfigExamples>
                        <VariablesList variables={availableVariables} />
                      </AccordionConfigExamples>
                    )}
                </>
              )}
              {watchActionCode === ACTION_CODE.EXECUTE_COMMAND && (
                <BindTypeInputs
                  bindTypeOptions={bindings}
                  containerName='actionField'
                  object={watchedObjectResource}
                  placeholderDict={commandPlaceholders}
                  valueInputLabel={t('ganttCreate.resourceForm.command')}
                  commandPicker={{
                    isTypeHidden: true,
                  }}
                />
              )}
              {[ACTION_CODE.OPEN_CONTEXT_MENU, ACTION_CODE.OPEN_FORM_MODEL].includes(
                watchActionCode
              ) && (
                <>
                  <BindTypeInputs
                    bindTypeOptions={bindings}
                    containerName='actionField'
                    formInput={formInput}
                    hintDict={getHints(watchActionCode)}
                    isShownBinding={Boolean(bindings?.length)}
                    placeholderDict={formPlaceholders}
                    valueOptions={formCodeOptions}
                    scriptEditor={{
                      validator: funValidatorContextMenu,
                      onAfterValidation: handleAfterValidationScript,
                    }}
                    valueInputLabel={
                      watchActionCode === ACTION_CODE.OPEN_FORM_MODEL ? t('label.code') : undefined
                    }
                  />
                  {watchActionFieldBindType &&
                    [GANTT_BIND_TYPE_LIST.JS, GANTT_BIND_TYPE_LIST.JSON].includes(
                      watchActionFieldBindType
                    ) && (
                      <AccordionConfigExamples>
                        <VariablesList variables={availableVariables} />
                      </AccordionConfigExamples>
                    )}
                </>
              )}
            </form>
          </FormProvider>
          <Box alignItems='center' display='flex' flexWrap='wrap' mb={1}>
            <Box pr={1}>{t('ganttCreate.configExamples.blockTitle')}</Box>
            <BtnCopyConfigExample name={t('ganttCreate.configExamples.contextHeaderMenuY')} />
            <BtnCopyConfigExample name={t('ganttCreate.configExamples.contextMenuWithCommand')} />
            <BtnCopyConfigExample name={t('ganttCreate.configExamples.simpleTooltip')} />
            <BtnCopyConfigExample name={t('ganttCreate.configExamples.simpleTooltip')} />
            <BtnCopyConfigExample name={t('ganttCreate.configExamples.groupTooltip')} />
          </Box>
        </ModalFormContainer>
      )}
    </ModalWrapper>
  )
}
