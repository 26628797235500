import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

import { transformResponseWithPagination } from '@helpers'
import { URLS } from '@constants'
import {
  FormShortDTO,
  GETForm,
  GETFormData,
  GETObjectData,
  GETObjectDataFormDropDownDefaultByIdParams,
  GETObjectDataFormDropDownDefaultParams,
  GETObjectDataParams,
  GETObjectDataRecordByFormID,
  GETOptionGroupType,
  GETPreFillObjectDataRecordByFormID,
  ObjectDataRecord,
  POSTCopyForm,
  POSTForm,
  POSTObjectData,
  POSTOptionGroupType,
  PUTObjectData,
} from '@types'

import { commonAPI } from './common.api'

export const formApi = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllForm: build.query<GETFormData, GETObjectDataParams | void>({
      query: params => ({
        url: URLS.FRONT_CFG + '/form',
        params: {
          page: params?.page,
          size: params?.size,
          sort: params?.sort,
          objectCode: params?.objectCode,
          searchPattern: params?.searchPattern,
        },
      }),
      transformResponse: (output: FormShortDTO[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<FormShortDTO[]>(output, meta),
      providesTags: ['AllForms'],
    }),
    fetchAllFormUncertain: build.query<GETFormData, GETObjectDataParams | void>({
      query: params => ({
        url: URLS.FRONT_CFG + '/form/uncertain',
        params: {
          page: params?.page,
          size: params?.size,
          sort: params?.sort,
        },
      }),
      transformResponse: (output: FormShortDTO[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<FormShortDTO[]>(output, meta),
      providesTags: ['UncertainForms'],
    }),
    fetchAllFormByObjectCode: build.query<FormShortDTO[], string>({
      query: objectCode => ({
        url: URLS.FRONT_CFG + `/form/object/code/${objectCode}`,
      }),
      providesTags: ['AllForms'],
    }),
    fetchForm: build.query<GETForm, string | void | null>({
      query: code => ({
        url: URLS.FRONT_CFG + `/form/code/${code}`,
      }),
      providesTags: ['SingleForm'],
    }),
    createForm: build.mutation<GETForm, POSTForm>({
      query: form => ({
        url: URLS.FRONT_CFG + '/form',
        method: 'POST',
        body: form,
      }),
      invalidatesTags: ['UndefinedViews', 'SingleForm', 'AllForms', 'UncertainForms'],
    }),
    updateForm: build.mutation<GETForm, POSTForm>({
      query: form => ({
        url: URLS.FRONT_CFG + '/form',
        method: 'POST',
        body: form,
      }),
      invalidatesTags: [
        'UndefinedViews',
        'SingleForm',
        'UncertainForms',
        'AllForms',
        'FormContainers',
      ],
    }),
    deleteForm: build.mutation<GETForm, number | string>({
      query: id => ({
        url: URLS.FRONT_CFG + `/form/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UncertainForms', 'GroupForms', 'AllForms'],
    }),
    fetchFormGroups: build.query<GETOptionGroupType[], void>({
      query: () => ({
        url: URLS.FRONT_CFG + 'group/form',
      }),
      providesTags: ['GroupForms'],
    }),
    deleteFormByCode: build.mutation<GETForm, string>({
      query: code => ({
        url: URLS.FRONT_CFG + `/form/code/${code}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UncertainForms', 'GroupForms', 'AllForms'],
    }),
    updateFormGroups: build.mutation<GETOptionGroupType[], POSTOptionGroupType[]>({
      query: newGroups => ({
        url: URLS.FRONT_CFG + '/group/form',
        method: 'POST',
        body: newGroups,
      }),
      invalidatesTags: ['UncertainForms'],
    }),
    copyForm: build.mutation<GETForm, POSTCopyForm>({
      query: formData => ({
        url: URLS.FRONT_CFG + '/form/copy',
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['UncertainForms', 'AllForms'],
    }),
    // TODO Использовать вместо fetchObjectDataRecordEnrichedById
    fetchObjectDataByFormCode: build.mutation<ObjectDataRecord, GETObjectDataRecordByFormID>({
      query: ({ formCode, objectId }) => ({
        url: URLS.FRONT_API + `/data/form/code/${formCode}/${objectId}`,
      }),
    }),
    fetchPreFillObjectDataByFormCode: build.mutation<
      ObjectDataRecord,
      GETPreFillObjectDataRecordByFormID
    >({
      query: ({ formCode, objectCode, objectId }) => ({
        url:
          URLS.FRONT_API +
          `/data/form/code/${formCode}/preFill/objectCode/${objectCode}/objectId/${objectId}`,
      }),
    }),
    createObjectDataRecord: build.mutation<ObjectDataRecord, POSTObjectData>({
      query: ({ formId, record }) => ({
        url: URLS.FRONT_API + `/data/form/${formId}`,
        method: 'POST',
        body: { ...record, _id: undefined },
      }),
      invalidatesTags: ['ObjectData'],
    }),
    updateObjectDataRecord: build.mutation<ObjectDataRecord, PUTObjectData>({
      query: ({ formId, record }) => ({
        url: URLS.FRONT_API + `/data/form/${formId}`,
        method: 'PUT',
        body: record,
      }),
      invalidatesTags: ['ObjectData', 'AuditData'],
    }),
    fetchObjectDataFormDropDownDefaultById: build.query<
      ObjectDataRecord,
      GETObjectDataFormDropDownDefaultByIdParams
    >({
      query: ({ formId, objectField, objectId }) => ({
        // url: `router/storage/object/form/objectCode/${objectCode}/objectId/${objectId}/default`,
        url:
          URLS.FRONT_API +
          `/data/form/formId/${formId}/objectField/${objectField}/objectId/${objectId}`,
      }),
    }),
    fetchObjectDataRecordFormDropDownDefault: build.mutation<
      GETObjectData,
      GETObjectDataFormDropDownDefaultParams
    >({
      query: ({ objectCode, fieldId, filter, sort, page, size }) => ({
        url: URLS.FRONT_API + `/data/dropDown/default/${fieldId}`,
        params: { objectCode, filter, sort, page, size },
      }),
      transformResponse: (output: ObjectDataRecord[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ObjectDataRecord[]>(output, meta),
    }),
    fetchObjectDataRecordFormDropDownDefaultByFields: build.mutation<
      GETObjectData,
      GETObjectDataFormDropDownDefaultParams
    >({
      query: ({ objectCode, fields, filter, sort, page, size }) => ({
        url: URLS.FRONT_API + `/data/dropDown/default`,
        params: { objectCode, fields, filter, sort, page, size },
      }),
      transformResponse: (output: ObjectDataRecord[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ObjectDataRecord[]>(output, meta),
    }),
  }),
})

export const {
  useFetchAllFormQuery,
  useFetchAllFormByObjectCodeQuery,
  useFetchFormQuery,
  useDeleteFormMutation,
  useCreateFormMutation,
  useUpdateFormMutation,
  useFetchAllFormUncertainQuery,
  useDeleteFormByCodeMutation,
  useFetchFormGroupsQuery,
  useUpdateFormGroupsMutation,
  useCopyFormMutation,
  useFetchObjectDataByFormCodeMutation,
  useFetchObjectDataRecordFormDropDownDefaultMutation,
  useFetchObjectDataRecordFormDropDownDefaultByFieldsMutation,
  useFetchObjectDataFormDropDownDefaultByIdQuery,
  useCreateObjectDataRecordMutation,
  useUpdateObjectDataRecordMutation,
  useFetchPreFillObjectDataByFormCodeMutation,
} = formApi
