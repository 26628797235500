import { forwardRef } from 'react'
import { InputAttributes, NumberFormatValues, NumericFormat } from 'react-number-format'

type ConstFormatProps = {
  onChange: (event: { target: { value: number | string | null | undefined } }) => void
  name: string
  decimalScale?: number
  max?: number
  value?: number | string | null
}

export const ConstFormat = forwardRef<typeof NumericFormat<InputAttributes>, ConstFormatProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, decimalScale = 2, max = 999999999999999, value, ...other } = props
    const withValueLimit = ({ floatValue, formattedValue }: NumberFormatValues) => {
      if (floatValue == null) return formattedValue === ''
      else return floatValue <= max
    }

    return (
      <NumericFormat
        {...other}
        allowLeadingZeros={false}
        allowNegative={false}
        decimalScale={decimalScale}
        decimalSeparator={','}
        getInputRef={ref}
        isAllowed={withValueLimit}
        thousandSeparator={' '}
        value={value}
        valueIsNumericString={true}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value ? values.value : '',
            },
          })
        }}
      />
    )
  }
)
