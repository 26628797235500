import { MutableRefObject } from 'react'
import { GridApi, GridSortModel } from '@microservices/wiskey-react-table-component'

import { SortType } from '@hooks'

export const getSortParam = (
  value: GridSortModel,
  apiRef: MutableRefObject<GridApi>
): SortType[] => {
  return value.map(sortModel => ({
    ...sortModel,
    sortKey: apiRef.current.getColumn(sortModel.field).sortKey || '',
  }))
}
