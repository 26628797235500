import { FC, useContext } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, FormInput } from '@microservices/wiskey-react-components'
import { GridColDef } from '@microservices/wiskey-react-table-component'
import { Grid } from '@mui/material'

import { PageContext } from '@pages/ConfigSearchAssistants'
import { TableWithActions } from '@pages/ConfigSearchAssistants/components/TableWithActions/TableWithActions'

import { GENERATOR_INPUT_TYPE, SEARCH_ASSISTANTS_COLUMNS } from '@constants'

type AllSearchAssistantsProps = {
  methods: UseFormReturn<any>
}

export const AllSearchAssistants: FC<AllSearchAssistantsProps> = ({ methods }) => {
  const { t } = useTranslation()

  const {
    onCreateSearchAssistant,
    onDeleteSearchAssistant,
    onEditSearchAssistant,
    onCopySearchAssistant,
    isLoading,
    allSearchAssistants,
    onShowCopyDialog,
  } = useContext(PageContext)

  return (
    <>
      <Grid container item alignItems='center' flexWrap='nowrap' gap={1} p={1}>
        <Grid item>
          <Button sx={{ mb: 1 }} variant='contained' onClick={onCreateSearchAssistant}>
            {t(`searchAssistants.addButton.searchAssistants`)}
          </Button>
        </Grid>
        <Grid item flexGrow={1}>
          <FormProvider {...methods}>
            <form>
              <Grid item sm={12} xs={12}>
                <FormInput
                  inputType={GENERATOR_INPUT_TYPE.INPUT}
                  name='searchPattern'
                  placeholder={t(`contexts.placeholder.search`)}
                  size='small'
                />
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
      <TableWithActions
        columns={SEARCH_ASSISTANTS_COLUMNS as GridColDef<any>[]}
        loading={isLoading}
        rows={allSearchAssistants}
        onCopy={onShowCopyDialog}
        onDelete={onDeleteSearchAssistant}
        onEdit={onEditSearchAssistant}
      />
    </>
  )
}
