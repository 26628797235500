import { FC, ReactNode } from 'react'
import { v4 as uuid } from 'uuid'
import { Box, Interpolation, SxProps, Theme, useTheme } from '@mui/material'

import { backgroundTableByMode, borderTableByMode } from '@helpers'
import {
  DATAGRID_HEADER_HEIGHT,
  DATAGRID_HEADER_WINDOW_HEIGHT,
  DATAGRID_HEADERS_SHADOW,
  DATAGRID_SCROLL_WIDTH,
  DEFAULT_HEADER_STYLES_CLASSNAME,
  ENTITY,
} from '@constants'
import { ObjectDataRecord } from '@types'

type RowStylesWrapperProps = {
  currentPage?: number
  objectData: ObjectDataRecord[]
  pageSize: number
  children: ReactNode
  type?: ENTITY
  globalStyles?: Interpolation<Theme>
  isDialogWindow?: boolean
  hasScrollbar: boolean
}

const wrapperClassName = `configured-view-wrapper-${uuid()}`
const borderWidth = 1

export const RowStylesWrapper: FC<RowStylesWrapperProps> = ({
  currentPage = 0,
  pageSize,
  objectData,
  children,
  globalStyles,
  type,
  isDialogWindow = false,
  hasScrollbar,
}) => {
  // TODO: Найти лучший способ отслеживания padding-right.
  // На январь 2023, это проблема самой библиотеки:
  // https://material-ui-docs.netlify.app/x/react-data-grid/#commercial-version
  // (для воспроизведения бага в одном из примеров нужно вручную закрепить колонку)
  // const [rightPadding, setRightPadding] = useState<number>(getScrollbarWidth())
  const theme = useTheme()

  // useEffect(() => {
  //   if (!objectData || !pageSize) {
  //     return
  //   }

  //   if (objectData.length === 0) {
  //     return
  //   }

  //   if (objectData?.length > pageSize) {
  //     setRightPadding(getScrollbarWidth())
  //     return
  //   }

  //   if (currentPage > 1 || objectData?.length < pageSize) {
  //     setRightPadding(0)
  //   }
  // }, [objectData, pageSize])

  const dataGridHeaderHeight = isDialogWindow
    ? DATAGRID_HEADER_WINDOW_HEIGHT
    : DATAGRID_HEADER_HEIGHT

  const stylesForCustomHeaders: SxProps<Theme> = {
    ' & .MuiDataGrid-columnHeaders': {
      minHeight: `${dataGridHeaderHeight}px !important`,
      maxHeight: `${dataGridHeaderHeight}px !important`,
      boxShadow: DATAGRID_HEADERS_SHADOW,
      top: '-4px',
    },
    ' & .MuiDataGrid-virtualScroller': {
      // marginTop: `${dataGridHeaderHeight}px !important`,
      // paddingTop: `${DATAGRID_HEADERS_MARGIN}px`,
      background: theme => theme.palette.common.white,
    },
    [`& .${DEFAULT_HEADER_STYLES_CLASSNAME}`]: {
      minHeight: `${dataGridHeaderHeight}px !important`,
      maxHeight: `${dataGridHeaderHeight}px !important`,
    },
  }

  return (
    <Box
      sx={{
        '& .MuiDataGrid-row': {
          color: `${theme.palette.color} !important`,
          transition: 'background-color 0.3s linear',
        },
        [`.MuiDataGrid-pinnedColumnHeaders--right`]: {
          // TODO pinnedColumnHeaders Возможно удалён
          paddingRight:
            objectData?.length > pageSize
              ? `${hasScrollbar ? DATAGRID_SCROLL_WIDTH : 0}px !important`
              : `0px !important`,
        },
        '& .MuiDataGrid-pinnedColumns': {
          bgcolor: `${theme.palette.background.pinned} !important`,
          right: '-1px',
        },
        [`.${wrapperClassName} .MuiDataGrid-pinnedColumnHeaders--right`]: {
          // pinnedColumnHeaders TODO Возможно удалён
          paddingRight: `${hasScrollbar ? DATAGRID_SCROLL_WIDTH : 0}px !important`,
        },
        ['& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-iconButtonContainer']: {
          width: 40,
        },
        ['& .MuiDataGrid-columnHeader .MuiBadge-badge']: {
          color: theme => `${theme.palette.color.default}`,
          fontSize: '0.6rem',
          top: 12,
          right: 4,
        },
        ['& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon']: {
          fill: theme => `${theme.palette.color.default}`,
          fontSize: '1rem',
        },
        ['& .MuiDataGrid-columnHeaderTitle']: {
          pr: 1,
        },
        [`.${wrapperClassName} .MuiDataGrid-virtualScroller::-webkit-scrollbar`]: {
          height: '10px',
          width: `${hasScrollbar ? DATAGRID_SCROLL_WIDTH : 0}px`,
          background: theme.palette.background.hovered,
        },
        [`.${wrapperClassName} > .MuiGrid-root`]: {
          mb: 0,
        },
        [`.${wrapperClassName} .MuiDataGrid-virtualScroller::-webkit-scrollbar-track`]: {
          background: theme.palette.background.hovered,
        },
        [`.${wrapperClassName} .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb`]: {
          background: theme.palette.color.scroll,
          border: theme.palette.border.scroll,
        },
        '& .MuiDataGrid-pinnedColumns--left, .MuiDataGrid-pinnedColumnHeaders--left': {
          // TODO pinnedColumnHeaders Возможно удалён
          zIndex: 2,
        },
        ...stylesForCustomHeaders,
        ...backgroundTableByMode(wrapperClassName, theme),
        ...borderTableByMode(wrapperClassName, theme, globalStyles?.borderColor),
      }}
    >
      <Box className={wrapperClassName}>{children}</Box>
    </Box>
  )
}
