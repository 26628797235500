import { GridColumnVisibilityModel } from '@microservices/wiskey-react-table-component'

export const compareModels = (
  firstModel: GridColumnVisibilityModel,
  secondModel: GridColumnVisibilityModel
): GridColumnVisibilityModel => {
  const result: GridColumnVisibilityModel = {}

  Object.keys(firstModel).forEach(field => {
    if (firstModel[field] !== secondModel[field]) {
      result[field] = secondModel[field]
    }
  })

  return result
}
