import { useEffect, useState } from 'react'
import isArray from 'lodash/isArray'

import { useFetchStorageMutation } from '@redux/api'

import { AutocompleteOption } from '@types'

export const useRosterQualOptions = () => {
  const [fetchStorage] = useFetchStorageMutation()
  const [options, setOptions] = useState<AutocompleteOption[]>([])

  useEffect(() => {
    fetchStorage({ objectCode: 'roster-qual' }) // Загружаем плановый список
      .unwrap()
      .then(res => {
        if (isArray(res)) {
          setOptions(res.map(qual => ({ id: qual['qual_id'], label: qual['qual_name'] })))
        }
      })
  }, [])

  return options
}
