import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { getObjectCodeUrl } from '@pages/ConfiguredEntity/helpers/getObjectCodeUrl'

import { useCurrentRow } from '@components/DisplayForm/hooks/useCurrentRow'
import { useFormResetLogic } from '@components/DisplayForm/hooks/useFormResetLogic'
import { useHandleNavigation } from '@components/DisplayForm/hooks/useHandleNavigation'
import { useVerifyFormCode } from '@components/DisplayForm/hooks/useVerifyFormCode'

import { useFetchFormQuery, useFetchObjectByCodeQuery, useFetchViewByCodeQuery } from '@redux/api'
import { useFetchEnrichedContainerQuery } from '@redux/api/container.api'
import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'

import { useAppSelector, usePkField, usePrompt, useSubscription } from '@hooks'
import { getEntityTypeForRequest, transformCommonParametersToValues } from '@helpers'
import { COMMON_PARAMETERS, ENTITY, ENTITY_TYPES_REQUEST, PreFillType } from '@constants'

import {
  LinkFormHistory,
  LinkType,
  LinkViewHistory,
  ObjectDataRecord,
  PassedFormData,
} from '../../../types'
import { Row } from '../types'

import { useFetchRow } from './useFetchRow'
import { useFetchRowByUpdate } from './useFetchRowByUpdate'
import { useHandlers } from './useHandlers'
import { useSetBlockingUIOverlay } from './useSetBlockingUIOverlay'

type useDisplayFormParams = {
  passedParams: PassedFormData
  onChangeDirty?: (value: boolean) => void
  onBreadcrumbsMainLinkClick: (link?: LinkViewHistory) => void
  onObjectDataRecordSuccessCreating: (links: LinkType[], data: ObjectDataRecord) => void
  handleNavigateToDefaultPage: () => void
  handleChangeFormCode: (formCode: string, verifyFormCode: string) => void
  handleRemoveSamePageForm: (formDataId?: number, isDirty?: boolean) => void
  handleUpdateSamePageFormData: (
    oldObjectId: string,
    newFormCode: string,
    objectCode: string,
    objectId: string
  ) => void
  handleCloseCurrentFormTab: () => void
  entityType: ENTITY
  dialogId?: string
  preFill?: PreFillType
}

export const useDisplayForm = ({
  passedParams,
  onChangeDirty,
  onBreadcrumbsMainLinkClick,
  onObjectDataRecordSuccessCreating,
  handleNavigateToDefaultPage,
  handleChangeFormCode,
  handleRemoveSamePageForm,
  handleUpdateSamePageFormData,
  handleCloseCurrentFormTab,
  entityType,
  dialogId,
  preFill,
}: useDisplayFormParams) => {
  const { formCode, objectId, viewCode, path, globalId, viewId } = passedParams
  const withPassedParams = Boolean(passedParams?.id)

  const { objectsWithDirtyRecords } = useAppSelector(state => state.changedFormFields)

  const viewType = getEntityTypeForRequest(entityType)
  // TODO: хранить параметры в redux
  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })

  const initialCommonParameters = useMemo(
    () => transformCommonParametersToValues(commonParameters),
    [commonParameters]
  )

  const { data: view, isSuccess: isSuccessView } = useFetchViewByCodeQuery(
    { code: viewCode, viewType },
    {
      skip: !viewCode || viewType === ENTITY_TYPES_REQUEST.GANTT_CHART,
    }
  )

  const formHistory = useAppSelector(state => state.formHistory)[globalId] || {}
  const { links, selectedIndex } = formHistory
  const selectedLink = selectedIndex ? links[selectedIndex] : null
  const currentLink: LinkFormHistory = useMemo(
    () =>
      selectedLink && 'formCode' in selectedLink
        ? selectedLink
        : {
            event: passedParams.event,
            formCode,
            id: globalId,
            isWindow: false,
            objectCode: fetchedForm?.objectCode || '',
            objectId: objectId || null,
            title: formCode,
          },
    [formCode, globalId, fetchedForm?.objectCode, objectId, passedParams, selectedLink]
  )

  const formCodeToFetchBy = currentLink.formCode

  const {
    data: fetchedForm,
    isFetching: isFetchingForm,
    isError: isFetchingFormError,
  } = useFetchFormQuery(formCodeToFetchBy, {
    skip: !formCodeToFetchBy,
    refetchOnMountOrArgChange: true,
  })

  const { data: objectModel } = useFetchObjectByCodeQuery(fetchedForm?.objectCode, {
    skip: !fetchedForm?.objectCode,
  })

  const pkField = usePkField(objectModel?.fields)

  const fields = useMemo(() => fetchedForm?.fields || [], [fetchedForm])

  //
  // const [fetchRow, { data: row, isSuccess: isSuccessRow, isError: isErrorRow }] =
  //   useFetchObjectDataMutation({ fixedCacheKey: 'display-form-fetch-object-data' })

  const viewPath = path ?? ''

  const methods = useForm<Row>()
  const {
    setValue: setValueOnForm,
    reset,
    watch,
    getValues,
    formState: { isDirty, dirtyFields },
  } = methods

  const { data, state, handlers } = useHandlers({
    passedParams,
    fetchedForm,
    fields,
    methods,
    withPassedParams,
    currentLink,
    dialogId,
    onBreadcrumbsMainLinkClick,
    onObjectDataRecordSuccessCreating,
    handleRemoveSamePageForm,
    handleUpdateSamePageFormData,
    handleCloseCurrentFormTab,
    pkField,
    formCodeToFetchBy,
  })

  const { data: fetchedContainers } = useFetchEnrichedContainerQuery(formCodeToFetchBy, {
    skip: !formCodeToFetchBy,
    refetchOnMountOrArgChange: true,
  })

  const { handleObjectSubscribe } = handlers

  // const isObjectCreated = type === FORM_TYPE.CREATE && isSuccessObjectCreated
  usePrompt({ when: isDirty })

  useSubscription(getObjectCodeUrl(currentLink.objectCode), handleObjectSubscribe)

  const { isLoadingRow, row } = useFetchRow({
    // objectCode: currentLink.objectCode,
    formCode: formCodeToFetchBy,
    objectId: currentLink.objectId || undefined,
    globalId,
    preFill,
  })

  useFetchRowByUpdate({
    objectCode: currentLink.objectCode,
    formCode: formCodeToFetchBy,
    objectId: currentLink.objectId || undefined,
    objectsWithDirtyRecords,
    globalId,
  })

  useVerifyFormCode({
    newEvent: currentLink.event,
    view,
    isSuccessView,
    withPassedParams,
    formCode,
    newFormCode: currentLink.formCode,
    handleChangeFormCode,
    globalId,
  })

  const { currentRow, setCurrentRow } = useCurrentRow(globalId)

  useHandleNavigation({
    handleNavigateToDefaultPage,
    setCurrentRow,
    withPassedParams,
    newEvent: currentLink.event,
    globalId,
  })

  useSetBlockingUIOverlay({
    form: fetchedForm,
    dialogId,
    formEvent: currentLink.event,
  })

  const { defaultValues } = useFormResetLogic({
    methods,
    type: currentLink.event,
    globalId,
    formCode: formCodeToFetchBy,
    preFill,
  })

  useEffect(() => {
    if (onChangeDirty) {
      onChangeDirty(isDirty)
    }
  }, [isDirty])

  return {
    data: {
      ...data,
      form: fetchedForm,
      viewCode,
      // TODO isLoadingForm
      isFetchingForm: isLoadingRow || isFetchingForm || state.isPendingCreateOrUpdateForm,
      isFetchingFormError,
      view,
      withPassedParams,
      fetchedContainers,
      viewPath,
      isDirty,
      methods,
      dirtyFields,
      formCodeToFetchBy,
    },
    handlers: {
      ...handlers,
      reset,
      setValueOnForm,
      watch,
    },
    state: {
      ...state,
      currentRow: row,
      currentLink,
      defaultValues,
      initialCommonParameters,
      isLoadingRow,
    },
  }
}
