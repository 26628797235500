import { useEffect, useState } from 'react'

import { showMessage } from '@redux/reducers/snackbar.reducer'

import { useAppDispatch } from '@hooks'

const LS_BAR_CHART_CONFIG = 'bar_chart_config'

export const useScriptEditorConfig = () => {
  const dispatch = useAppDispatch()

  const [editorValue, setEditorValue] = useState<string>(
    '{"entityCode": "entityCode", "entityId": 0}'
  )

  const [barChartConfig, setBarChartConfig] = useState<Record<string, any>>({})

  useEffect(() => {
    const storageConfig = localStorage.getItem(LS_BAR_CHART_CONFIG)
    if (!storageConfig) {
      return
    }

    try {
      const config: Record<string, any> = JSON.parse(storageConfig)
      setEditorValue(JSON.stringify(config))
      setBarChartConfig(config)
    } catch {
      localStorage.removeItem(LS_BAR_CHART_CONFIG)
    }
  }, [])

  const handleEditorValueChange = (config: string) => {
    return setEditorValue(config)
  }

  const handleEditorConfigSave = () => {
    if (!editorValue) {
      return
    }

    try {
      const config = JSON.parse(editorValue)
      setBarChartConfig(config)
      localStorage.setItem(LS_BAR_CHART_CONFIG, JSON.stringify(config, null, '\t'))

      dispatch(showMessage({ type: 'success', text: 'Successfully Saved!' }))
    } catch (err) {
      dispatch(showMessage({ type: 'error', text: err as string }))
    }
  }

  return { editorValue, barChartConfig, handleEditorValueChange, handleEditorConfigSave }
}
