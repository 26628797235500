import { Moment } from 'moment'

export const getHourIntervalLabel = (
  startHourInterval: Moment,
  endHourInterval: Moment
): string => {
  const format = 'HH:mm'

  return `${startHourInterval.format(format)} - ${endHourInterval.format(format)}`
}
