import { ChangeEvent, FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField } from '@microservices/wiskey-react-components'
import { FormatColorFill, FormatColorReset } from '@mui/icons-material'
import { Box, Grid, IconButton, SxProps, Theme, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { ColorPicker } from '@components/ColorPicker'
import { INITIAL_TEXT_STATE } from '@components/TextSettings/TextSettings'

import { REGEX, THEME_MODE } from '@constants'

type ColorSettingsProps = {
  label: string
  color?: string
  onChangeColor: (value: string | undefined) => void
  isEdit: boolean
  labelPlacement?: 'top' | 'left'
  labelMaxWidth?: string | number
  paddingRight?: string | number
  clearableColor?: boolean
  labelNoWrap?: boolean
  onClearColor?: (onChangeColor: (value: string | undefined) => void) => void
}

export const ColorSettings: FC<ColorSettingsProps> = ({
  label,
  color = INITIAL_TEXT_STATE.bgFontColor,
  onChangeColor,
  isEdit,
  labelPlacement = 'left',
  labelMaxWidth = 200,
  paddingRight,
  clearableColor = false,
  labelNoWrap = false,
  onClearColor,
}) => {
  const { t } = useTranslation()
  const [isOpenColorPicker, setOpenColorPicker] = useState(false)
  const [disabledRemoveColor, setDisabledRemoveColor] = useState(false)

  const handleChangeColor = (hexColor: string) => {
    if (clearableColor) {
      setDisabledRemoveColor(false)
    }

    hexColor !== '#NaNNaNNaN' && onChangeColor(hexColor)
  }

  const handleClickIconPicker = () => {
    setOpenColorPicker(true)
  }

  const handleCloseColorPicker = useCallback(() => {
    setOpenColorPicker(false)
  }, [])

  const iconButtonColorSx: SxProps<Theme> = {
    background: theme =>
      theme.palette.mode === THEME_MODE.DARK
        ? theme.palette.grey['700']
        : theme.palette.grey['300'],
  }

  const handleChangeHexColor = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    let hexColor = value

    if (!hexColor.startsWith('#')) {
      hexColor = '#' + value
    }

    if (clearableColor) {
      setDisabledRemoveColor(false)
    }

    if (REGEX.HEX_COLORS.test(hexColor)) {
      onChangeColor(hexColor.toLowerCase())
    }
  }

  const handleClickRemoveColor = () => {
    onClearColor ? onClearColor(onChangeColor) : onChangeColor('none')
    setDisabledRemoveColor(true)
  }

  const boxBackground = () => {
    if (color?.toLowerCase() === 'none') {
      return `
        linear-gradient(45deg, transparent calc(50% - 3px), #8B0000 calc(50% - 3px), #8B0000 calc(50% + 3px), transparent calc(50% + 3px)), 
        linear-gradient(-45deg, transparent calc(50% - 3px), #8B0000 calc(50% - 3px), #8B0000 calc(50% + 3px), transparent calc(50% + 3px))
      `
    }

    return color
  }

  return (
    <Grid
      container
      item
      alignItems={labelPlacement === 'left' ? 'center' : 'flex-start'}
      flexDirection={labelPlacement === 'left' ? 'row' : 'column'}
      mb={0.5}
    >
      <Grid item flex={1} maxWidth={labelMaxWidth} mb={labelPlacement === 'top' ? 0.5 : 0}>
        <Typography color={'GrayText'} noWrap={labelNoWrap}>
          {label}
        </Typography>
      </Grid>
      <Grid item position={'relative'}>
        <ColorPicker
          color={color}
          open={isOpenColorPicker}
          onChange={handleChangeColor}
          onClickOutside={handleCloseColorPicker}
        />
      </Grid>
      <Grid container item alignItems='center' flex={2} gap={1} pr={paddingRight}>
        <Grid item>
          <IconButton
            disabled={!isEdit}
            sx={{
              ...iconButtonColorSx,
              '&.Mui-disabled': {
                ...iconButtonColorSx,
                opacity: 0.7,
              },
            }}
            onClick={handleClickIconPicker}
          >
            <FormatColorFill />
          </IconButton>
        </Grid>
        {clearableColor ? (
          <Grid item>
            <IconButton
              disabled={!isEdit || disabledRemoveColor || color?.toLowerCase() === 'none'}
              sx={{
                ...iconButtonColorSx,
                '&.Mui-disabled': {
                  ...iconButtonColorSx,
                  opacity: 0.7,
                },
              }}
              onClick={handleClickRemoveColor}
            >
              <FormatColorReset />
            </IconButton>
          </Grid>
        ) : null}
        <Grid container item border='1px solid' borderColor={grey[400]} flexGrow={1} width='auto'>
          <Grid container item alignItems='center' width='auto'>
            <TextField
              fullWidth
              disabled={!isEdit}
              size='small'
              value={color?.toUpperCase()}
              InputProps={{
                sx: { pl: 0 },
                startAdornment: (
                  <Box
                    sx={{
                      background: boxBackground,
                      minWidth: 40,
                      minHeight: 40,
                      width: 40,
                      height: 40,
                      mr: 1,
                    }}
                  />
                ),
              }}
              sx={{
                color: theme => theme.palette.grey[600],
                border: 'none',
                '& fieldset': { border: 'none' },
              }}
              onChange={handleChangeHexColor}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
