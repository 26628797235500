import { useEffect, useState } from 'react'

import { ALIGNMENTS } from '@constants'
import { NavOptionType } from '@types'

// хук для позиционирования элементов в контрол панели
export const useAlignmentsNavList = (
  option: NavOptionType,
  itemsLine: NavOptionType[] | undefined
) => {
  const [order, setOrder] = useState<number | undefined>(undefined)
  const [margin, setMargin] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (itemsLine) {
      const leftItems = itemsLine.filter(item => item.alignment === ALIGNMENTS.LEFT)
      const rightItems = itemsLine.filter(item => item.alignment === ALIGNMENTS.RIGHT)

      // Обработка левых элементов
      // Установка margin только для последнего элемента с левым позиционированием
      leftItems.forEach((item, index) => {
        if (item.id === option.id) {
          setOrder(index + 1)
          setMargin(index === leftItems.length - 1 ? 'mr-auto' : '')
        }
      })

      // Обработка правых элементов
      // Установка margin только для первого элемента с правым позиционированием
      // Чтобы элементы с правым позиционированием шли наоборот справа налево 5,4,3,2,1 относительно,
      // настраиваемого списка, в setOrder помещается вся длина линии элементов (itemsLine) - index
      rightItems.forEach((item, index) => {
        if (item.id === option.id) {
          setOrder(itemsLine.length - index)
          setMargin(index === rightItems.length - 1 ? 'ml-auto' : '')
        }
      })
    }
  }, [option, itemsLine])

  return {
    order,
    margin,
  }
}
