import { useForm } from 'react-hook-form'
import { matchPath, useLocation } from 'react-router-dom'

import { useFetchAllTooltipsQuery } from '@redux/api/tooltips.api'

import { useDebounce } from '@hooks'
import { ROUTES, SEARCH_PATTERN_DELAY } from '@constants'

import { useHandlers } from './useHandlers'

export const useConfigTooltips = () => {
  const { pathname } = useLocation()
  const isAllTooltips = matchPath(ROUTES.CONFIG_TOOLTIPS, pathname)

  const methods = useForm<{ searchPattern: string }>({ defaultValues: { searchPattern: '' } })
  const { watch } = methods
  const searchPattern = watch('searchPattern')

  const { state, handlers } = useHandlers()

  const debouncedSearchPattern = useDebounce(searchPattern, SEARCH_PATTERN_DELAY)

  const { data: tooltipsData, isFetching: isLoadingTooltips } = useFetchAllTooltipsQuery(
    {
      searchPattern: debouncedSearchPattern ? debouncedSearchPattern : undefined,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  return {
    state: {
      ...state,
      allTooltips: tooltipsData || [],
      methods,
    },
    data: {
      isAllTooltips,
      isLoadingTooltips,
    },
    handlers: {
      ...handlers,
    },
  }
}
