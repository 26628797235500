import { Dispatch, ReactNode, SetStateAction, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DoneAll } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { ObjectValueOptionsType } from '@pages/EntityCreateOrEdit/components/AddColumnDialog/hooks'

import { getObjectValueName } from '@helpers'
import { FIELD_BIND_TYPE, GENERATOR_INPUT_TYPE, MAX_INPUT_LENGTH } from '@constants'
import { ObjectFieldDTO, ObjectFieldWithUsed, TooltipPointRowType } from '@types'

import { AddTooltipPointForm } from '../AddPointDialog'

type UseInputsParams = {
  methods: UseFormReturn<AddTooltipPointForm, any, undefined>
  points: TooltipPointRowType[]
  objectFields: ObjectFieldDTO[]
  currentRowId: number | null
  handleChangeBinding: () => void
  setShowScriptValueDialogTitle: (value: boolean) => void
  setCurrentNameScriptDialog: Dispatch<SetStateAction<'conditionsHidden' | 'value' | null>>
}

const BIND_TYPES_OPTIONS = [
  { id: FIELD_BIND_TYPE.FIELD, label: FIELD_BIND_TYPE.FIELD },
  { id: FIELD_BIND_TYPE.STATIC, label: FIELD_BIND_TYPE.STATIC },
  { id: FIELD_BIND_TYPE.JS, label: FIELD_BIND_TYPE.JS },
]

export const useInputs = ({
  methods,
  points,
  objectFields,
  currentRowId,
  handleChangeBinding,
  setShowScriptValueDialogTitle,
  setCurrentNameScriptDialog,
}: UseInputsParams) => {
  const { t } = useTranslation()

  const { watch } = methods

  const watchValue = watch('value')
  const watchBinding = watch('bindType')

  const filterObjectFields = (fields?: ObjectFieldDTO[]): ObjectFieldWithUsed[] =>
    fields?.map(field => {
      const isAlreadyUsed = Boolean(
        points?.find(
          point => point.bindType === FIELD_BIND_TYPE.FIELD && point.value === field.name
        )
      )

      return {
        ...field,
        isAlreadyUsed,
      }
    }) || []

  const filteredObjectFields = useMemo(
    () => filterObjectFields(objectFields),
    [objectFields, points]
  )

  const valueOptions: ObjectValueOptionsType[] = useMemo(
    () =>
      filteredObjectFields.map(({ id, name, isAlreadyUsed, type, isPk }) => ({
        id,
        name,
        isAlreadyUsed,
        fieldType: type,
        isPk,
      })),
    [filteredObjectFields]
  )

  const titleInput = [
    {
      name: 'title',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      label: t('tooltips.tooltipPointModal.title.label'),
      placeholder: t('tooltips.tooltipPointModal.title.placeholder'),
      rules: {
        required: true,
        validate: (value: string) => {
          if (
            points.findIndex(point => point.title === value && point.id !== currentRowId) !== -1
          ) {
            return `${value} ${t('columnInputs.errors.alreadyUsed')}`
          }

          if (value.trim().length === 0) {
            return false
          }
        },
      },
      labelSx: { minWidth: 150 },
    },
  ]

  const bindingInput = [
    {
      autocompleteOptions: BIND_TYPES_OPTIONS,
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      label: t('tooltips.tooltipPointModal.binding.label'),
      labelSx: { minWidth: 150 },
      name: 'bindType',
      placeholder: t('tooltips.tooltipPointModal.binding.placeholder'),
      rules: { required: true },
      onChangeAutocomplete: handleChangeBinding,
    },
  ]

  const valueFieldInput = [
    {
      name: 'value',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      label: t('tooltips.tooltipPointModal.value.label'),
      placeholder: t('tooltips.tooltipPointModal.value.placeholderSelect'),
      disabled: !watchBinding,
      selectOptions: valueOptions,
      renderOptionSelect: (option: ObjectValueOptionsType) => {
        return (
          <Box display='flex' justifyContent='space-between' width='100%'>
            <Box alignItems='center' display='flex'>
              {option.isAlreadyUsed && (
                <DoneAll sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }} />
              )}
              <Typography ml={1}>
                {getObjectValueName(option.name, option.fieldType, option.isPk)}
              </Typography>
            </Box>
          </Box>
        )
      },
      ...(watchValue !== t('placeholder.value') && {
        renderValueSelect: (value: ReactNode) => {
          const option = valueOptions.find(el => el.name === value)

          if (option) {
            const nameWithPostfix = getObjectValueName(option.name, option.fieldType, option.isPk)

            return (
              <Box title={option.name !== nameWithPostfix ? nameWithPostfix : undefined}>
                {value as ReactNode}
              </Box>
            )
          }

          return value
        },
      }),
      MenuProps: { PaperProps: { sx: { maxHeight: 300 } } },
      rules: {
        required: true,
        validate: (value: string) => value !== t('placeholder.value'),
      },
      labelSx: { minWidth: 150 },
    },
  ]

  const valueStaticInput = [
    {
      name: 'value',
      label: t('tooltips.tooltipPointModal.value.label'),
      placeholder: t('tooltips.tooltipPointModal.value.placeholder'),
      inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
      maxLengthInput: MAX_INPUT_LENGTH,
      labelSx: { minWidth: 150 },
      rules: {
        maxLength: MAX_INPUT_LENGTH,
      },
      rows: 1,
      sx: {
        '& .MuiInputBase-root': {
          py: '8.5px',
        },
      },
    },
  ]

  const valueJsInput = [
    {
      name: 'value',
      label: t('tooltips.tooltipPointModal.value.label'),
      placeholder: t('tooltips.tooltipPointModal.value.placeholder'),
      inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
      maxLengthInput: MAX_INPUT_LENGTH,
      labelSx: { minWidth: 150 },
      formLabelSx: { pr: 2 },
      readOnly: true,
      rules: {
        maxLength: MAX_INPUT_LENGTH,
      },
      additionalBtn: {
        isEnabled: true,
        text: 'edit',
        color: 'primary',
        variant: 'contained',
        onClick: () => {
          setCurrentNameScriptDialog('value')
          setShowScriptValueDialogTitle(true)
        },
      },
      rows: 1,
      sx: {
        '& .MuiInputBase-root': {
          py: '8.5px',
        },
      },
    },
  ]

  const valueInput = [
    ...(!watchBinding || watchBinding?.id === FIELD_BIND_TYPE.FIELD ? valueFieldInput : []),
    ...(watchBinding?.id === FIELD_BIND_TYPE.STATIC ? valueStaticInput : []),
    ...(watchBinding?.id === FIELD_BIND_TYPE.JS ? valueJsInput : []),
  ]

  const conditionInput = [
    {
      name: 'conditionsHidden',
      label: t('tooltips.tooltipPointModal.conditionsHidden.label'),
      placeholder: t('tooltips.tooltipPointModal.conditionsHidden.placeholder'),
      inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
      maxLengthInput: MAX_INPUT_LENGTH,
      readOnly: true,
      labelSx: { minWidth: 150 },
      formLabelSx: { pr: 2 },
      rules: {
        maxLength: MAX_INPUT_LENGTH,
      },
      additionalBtn: {
        isEnabled: true,
        text: 'edit',
        color: 'primary',
        variant: 'contained',
        onClick: () => {
          setCurrentNameScriptDialog('conditionsHidden')
          setShowScriptValueDialogTitle(true)
        },
      },
      rows: 1,
      sx: {
        '& .MuiInputBase-root': {
          py: '8.5px',
        },
      },
    },
  ]

  return {
    inputs: {
      titleInput,
      bindingInput,
      valueInput,
      conditionInput,
    },
  }
}
