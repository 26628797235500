import type {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import AuthService from '@services/AuthService'

import { getResponseMessageText } from '@helpers'
import { ExtraOptions, ServerError } from '@types'

import { showMessage } from '../../reducers/snackbar.reducer'

const baseQuery = (args: FetchArgs, api: BaseQueryApi, extraOptions: ExtraOptions) => {
  // const isSlots = args.url.startsWith('api/slots/')

  return fetchBaseQuery({
    prepareHeaders: headers => {
      const token = AuthService.getToken()
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  })(args, api, extraOptions)
}

export const baseQueryWithNotifications: BaseQueryFn<
  FetchArgs,
  unknown,
  FetchBaseQueryError,
  ExtraOptions
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  const requestArgs = args as FetchArgs
  const isValidRequest = requestArgs?.body || requestArgs?.method === 'DELETE' || result.error
  const resultErrorData = result.error?.data as ServerError
  const showNotification = extraOptions?.showNotification ?? true
  if (result.meta?.response?.status === 401) {
    AuthService.doLogin()
  }
  if (isValidRequest && showNotification) {
    api.dispatch(
      showMessage({
        type: result.error && !result.meta?.response?.ok ? 'error' : 'success',
        text: getResponseMessageText(result.error, resultErrorData),
        statusCode: result.meta?.response?.status,
      })
    )
  }

  return result
}
