import { isNotEmpty } from '@helpers'
import { SEARCH_RULE_TYPE } from '@constants'

const isEmptyOrNotEmptyRule = (ruleName: string): boolean => {
  return ruleName === SEARCH_RULE_TYPE.EMPTY || ruleName === SEARCH_RULE_TYPE.NOT_EMPTY
}

export const getSortKeysForQuickSearchForRequest = (
  sortKeys: string[],
  searchValue: string,
  ruleName: string
): string[] | undefined => {
  if (ruleName && isEmptyOrNotEmptyRule(ruleName) && sortKeys) {
    return sortKeys
  }

  return isNotEmpty(searchValue) && sortKeys ? sortKeys : undefined
}

export const getSearchValueForRequest = (
  searchValue: string,
  ruleName: string
): null | string | undefined => {
  if (isEmptyOrNotEmptyRule(ruleName)) {
    return null
  }

  return isNotEmpty(searchValue) ? searchValue : undefined
}

export const getSearchRuleForRequest = (
  searchValue: string,
  ruleName: string
): string | undefined => {
  if (isEmptyOrNotEmptyRule(ruleName)) {
    return ruleName
  }

  return ruleName && isNotEmpty(searchValue) ? ruleName : undefined
}
