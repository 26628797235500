import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  useCopySearchAssistantMutation,
  useDeleteSearchAssistantMutation,
} from '@redux/api/searchAssistant.api'

import { MODAL_TYPE, ROUTES } from '@constants'
import { POSTCopySearchAssistantParams } from '@types'

export const useHandlers = () => {
  const navigate = useNavigate()

  const [copySearchAssistantData, setSearchAssistantDataData] =
    useState<POSTCopySearchAssistantParams | null>(null)
  const [showCopyDialog, setShowCopyDialog] = useState<boolean>(false)

  const [deleteSearchAssistant] = useDeleteSearchAssistantMutation()
  const [copySearchAssistant, { isLoading: loadingCopySearchAssistant }] =
    useCopySearchAssistantMutation()

  const handleCopySearchAssistant = (data: POSTCopySearchAssistantParams) => {
    const { internalId, title, id } = data

    copySearchAssistant({ internalId, title, id })
      .unwrap()
      .then(() => setShowCopyDialog(false))
  }

  const handleShowDialog = (data: POSTCopySearchAssistantParams) => {
    setSearchAssistantDataData(data)
    setShowCopyDialog(true)
  }

  const handleCloseDialog = () => {
    setSearchAssistantDataData(null)
    setShowCopyDialog(false)
  }

  const handleCreateSearchAssistant = () => {
    navigate(MODAL_TYPE.CREATE)
  }
  const handleEditSearchAssistant = (id: number) =>
    navigate(`${ROUTES.CONFIG_SEARCH_ASSISTANTS_EDIT}/${id}`)
  const handleDeleteSearchAssistant = (id: number) => {
    deleteSearchAssistant(id)
  }

  return {
    state: {},
    handlers: {
      handleCreateSearchAssistant,
      handleEditSearchAssistant,
      handleDeleteSearchAssistant,
      handleCopySearchAssistant,
      handleShowDialog,
      handleCloseDialog,
    },
    data: {
      loadingCopySearchAssistant,
      showCopyDialog,
      copySearchAssistantData,
    },
  }
}
