import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmModal } from '@microservices/wiskey-react-components'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridSortModel,
} from '@microservices/wiskey-react-table-component'
import { Grid } from '@mui/material'

import { generateDataGridActionsColumn } from '@helpers'
import { ENTITY } from '@constants'
import { ConfigRowsType } from '@types'

type EntityOrFormListProps<T> = {
  columns: GridColDef<ConfigRowsType>[]
  rows: T[]
  onDelete?: (id: string | number) => void
  onCopy?: (data: T) => void
  onEdit: (code: string, id: string | number) => void
  loading?: boolean
  onSetSorting: (value: GridSortModel | null) => void
  onCustomDelete?: (id: string | number) => void
  currentSort: GridSortModel
  type?: ENTITY
}

export const EntityOrFormList = <T extends { id: string | number; code?: string }>({
  columns,
  rows,
  onDelete,
  onCopy,
  onEdit,
  loading,
  onSetSorting,
  onCustomDelete,
  currentSort,
  type,
}: EntityOrFormListProps<T>) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [currentRowId, setCurrentRowId] = useState<string | number>()
  const { t } = useTranslation()

  const handleCopy = (params: GridCellParams<any, T>) => {
    onCopy?.(params.row)
  }

  const handleEdit = (params: GridCellParams<any, T>) => {
    onEdit?.(params.row.code, params.row.id)
  }

  const handleClickDelete = (params: GridCellParams<any, T>) => {
    if (params.row.id) {
      if (onCustomDelete) {
        onCustomDelete?.(params.row.id)

        return
      } else {
        setShowDeleteModal(true)
      }
      setCurrentRowId(params.row.id)
    }
  }

  const handleDelete = () => {
    if (currentRowId) {
      onDelete?.(currentRowId)
      setShowDeleteModal(false)
    }
  }

  const handleClose = () => setShowDeleteModal(false)

  const handleChangeModelSort = (value: GridSortModel | null) => {
    if (value?.length) {
      onSetSorting(value)

      return
    }
    onSetSorting(null)
  }

  const columnsWithActions = useMemo(
    () => [
      ...columns,
      generateDataGridActionsColumn({
        onEdit: handleEdit,
        onCopy: handleCopy,
        onDelete: handleClickDelete,
      }),
    ],
    [columns, type, onCustomDelete]
  )

  return (
    <Grid container spacing={2}>
      {showDeleteModal && (
        <ConfirmModal
          actionBtnText={t('modal.delete.btn')}
          containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
          isShow={showDeleteModal}
          text={t('modal.delete.warning')}
          title={t('modal.delete.title')}
          onClose={handleClose}
          onConfirm={handleDelete}
        />
      )}
      <Grid container item>
        <DataGrid
          disableMultipleColumnsSorting
          hideFooter
          columns={columnsWithActions}
          loading={loading}
          rows={rows}
          sortModel={currentSort}
          sortingMode={'server'}
          sx={{ borderRadius: 0 }}
          onSortModelChange={handleChangeModelSort}
        />
      </Grid>
    </Grid>
  )
}
