import { useContext } from 'react'
import i18next from 'i18next'
import { FormInputProps } from '@microservices/wiskey-react-components'

import { GENERATOR_INPUT_TYPE, REGEX } from '@constants'
import { SelectOption } from '@types'

import { SearchAssistantsCreateOrEditContext } from '../../../SearchAssistantsCreateOrEdit'

type useInputsParams = {
  objectOptions: SelectOption[]
}

export const useInputs = ({ objectOptions }: useInputsParams) => {
  const { editCriteria, templateData } = useContext(SearchAssistantsCreateOrEditContext)

  const searchFilterInputs: FormInputProps[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('searchFilterForm.id.placeholder'),
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      label: i18next.t('searchFilterForm.id.label'),
      rules: {
        required: true,
        validate: value => {
          if (!editCriteria && templateData?.data.find(data => data.code === value)) {
            return `${value} ${i18next.t('searchFilterForm.alreadyUsedCode')}`
          }
        },
      },
      disabled: Boolean(editCriteria),
    },
    {
      name: 'title',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('searchFilterForm.title.placeholder'),
      label: i18next.t('searchFilterForm.title.label'),
      rules: { required: true, validate: value => value.trim().length !== 0 },
    },
    {
      name: 'objectTitle',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      label: i18next.t('searchFilterForm.object.label'),
      placeholder: i18next.t('searchFilterForm.object.placeholder'),
      selectOptions: objectOptions,
      MenuProps: { PaperProps: { sx: { maxHeight: 300 } } },
      rules: {
        required: true,
        validate: value => value !== i18next.t('searchFilterForm.object.placeholder'),
      },
    },
    {
      name: 'isPinning',
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: i18next.t('searchFilterForm.pinning.label'),
      labelPlacement: 'end',
      formInputContainerSx: { mb: 0 },
    },
  ]

  return searchFilterInputs
}
