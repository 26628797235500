export enum MODAL_TYPE {
  EDIT = 'edit',
  CREATE = 'create',
}

export enum MODEL_TYPE {
  VIEW = 'view',
  FORM = 'forms',
  ACTION = 'action',
  COLUMN = 'column',
  FIELD = 'field',
  GANTT = 'gantt',
  LIST_CONTROL = 'listControl',
  GANTT_ACTION_RESOURCE = 'gantt_action_resource',
  GANTT_ACTION_TIMELINES = 'gantt_action_timelines',
  GANTT_SEGMENT = 'gantt_segment',
  DISPLAY_RULES = 'display_rules',
  TOOLTIP = 'tooltip',
}

export enum GANTT_PARTS {
  BACKLOG = 'backlog',
}

export enum ACTION_CODE {
  OPEN_FORM_MODEL = 'open_form_model',
  OPEN_ANOTHER_FORM_MODEL = 'open_another_form_model',
  OPEN_CONTEXT_MENU = 'open_context_menu',
  OPEN_TOOLTIP = 'open_tooltip',
  EXECUTE_COMMAND = 'execute_command',
  ALLOW_EXECUTE_UI_ACTION = 'allow_execute_ui_action',
  DELETE_OBJECT = 'delete_object',
}

export enum EVENT_CODE {
  ON_ROW_CLICK = 'on_row_click',
  ON_ROW_CREATE = 'on_row_create',
  ON_ROW_EDIT = 'on_row_edit',
  ON_ROW_ADDITIONAL_BUTTON_CLICK = 'on_row_additional_button_click',
  ON_COLUMN_RIGHT_CLICK = 'on_column_right_click',
  ON_ROW_RIGHT_CLICK = 'on_row_right_click',
  ON_ROW_DELETE_BUTTON_CLICK = 'on_row_delete_button_click',
  ON_HEADER_TOOLTIP = 'on_header_tooltip',
  ON_ROW_TOOLTIP = 'on_row_tooltip',
  ON_CELL_TOOLTIP = 'on_cell_tooltip',
}

export enum EVENT_CODE_GANTT {
  ON_ROW_CLICK = 'on_row_click',
  ON_CELL_CLICK = 'on_cell_click',
  ON_BAR_CLICK = 'on_bar_click',
  ON_SEGMENT_CLICK = 'on_segment_click',
  ON_BAR_RIGHT_CLICK = 'on_bar_right_click',
  ON_ROW_RIGHT_CLICK = 'on_row_right_click',
  ON_HEADER_RIGHT_CLICK = 'on_header_right_click',
  ON_TOOLTIP_HEADER = 'on_tooltip_header',
  ON_TOOLTIP_ROW = 'on_tooltip_row',
  ON_SEGMENT_RESIZE = 'on_segment_resize',
  ON_BACKLOG_SEGMENT_RESIZE = 'on_backlog_segment_resize',
  ON_SEGMENT_INNER_MOVE = 'on_segment_inner_move',
  ON_SEGMENT_OUTER_MOVE = 'on_segment_outer_move',
  ON_BACKLOG_SEGMENT_INNER_MOVE = 'on_backlog_segment_inner_move',
  ON_BACKLOG_SEGMENT_OUTER_MOVE = 'on_backlog_segment_outer_move',
}

export enum AVAILABLES {
  ON_SEGMENT_RESIZE = 'timeline',
  ON_BACKLOG_SEGMENT_RESIZE = 'backlog',
  ON_SEGMENT_INNER_MOVE = 'timeline-timeline',
  ON_SEGMENT_OUTER_MOVE = 'timeline-backlog',
  ON_BACKLOG_SEGMENT_INNER_MOVE = 'backlog-backlog',
  ON_BACKLOG_SEGMENT_OUTER_MOVE = 'backlog-timeline',
}
