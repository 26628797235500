import { createContext, FC } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ConfirmModal, FormInput } from '@microservices/wiskey-react-components'
import { Box, Button, Grid, Typography } from '@mui/material'

import { VariablesTab } from '@pages/ContextMenuCreateOrEdit/components/VariablesTab'
import { BtnCopyConfigExample } from '@pages/GanttChart/components/ConfigExamples'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { DataSource } from '@components/DataSource'
import { GeneralButtons } from '@components/GeneralButtons'
import { NewModel } from '@components/NewModel'
import { ScriptValueDialog } from '@components/ScriptValueDialog'
import { SwitchTable } from '@components/SwitchTable'
import { Tab, TabPanel, Tabs } from '@components/Tabs'

import {
  GENERATOR_INPUT_TYPE,
  MAX_INPUT_LENGTH,
  MODEL_TYPE,
  REGEX,
  TOOLTIP_POINT_COLUMNS,
} from '@constants'

import { FieldsConfig } from './components/ConfigFields/FieldsConfig'
import { useTooltipCreateOrEdit } from './hooks'
import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const TooltipCreateOrEdit: FC = () => {
  const { t } = useTranslation()
  const {
    state: {
      currentTab,
      currentRowId,
      disabledTab,
      isOpenDialog,
      isShowScriptValueDialogJson,
      isShowDeleteVariableModal,
    },
    data: {
      modelType,
      methods,
      copyId,
      id,
      isEdit,
      isDirty,
      objects,
      isLoadingObjects,
      isLoadingTooltipById,
      objectFields,
      variableFields,
      watchedIsJson,
      watchedJsonValue,
      watchedPoints,
      watchedVariables,
      isLoadingTooltipJSON,
    },
    handlers: {
      setShowScriptValueDialogJson,
      handleSave,
      handleCancel,
      handleTabChange,
      handleChangeJsonMode,
      handleChangeValueScriptJson,
      handleOpenPointDialog,
      handleClosePointDialog,
      handleSavePoint,
      handleEditPoint,
      handleDeletePoint,
      handleAddVariableItem,
      handleSaveVariable,
      handleDeleteVariable,
      handleOpenDeleteVariableModal,
      handleCloseDeleteVariableModal,
    },
  } = useTooltipCreateOrEdit()

  const contextValue: PageContextType = {
    isOpenDialog,
    objectFields,
    points: watchedPoints,
    variables: watchedVariables,
    currentRowId,
    handleSavePoint,
    handleClosePointDialog,
  }

  return (
    <PageContext.Provider value={contextValue}>
      <Box>
        <FormProvider {...methods}>
          <Box px={1} py={0}>
            <Grid container alignItems='center' justifyContent='space-between'>
              <Grid item my={1}>
                <Typography variant='h5'>{t(`tooltips.title.${modelType}`)}</Typography>
              </Grid>
            </Grid>
            <NewModel loading={isLoadingTooltipById} />
            <FormInput
              formInputContainerSx={{ mt: 0.5 }}
              inputType={GENERATOR_INPUT_TYPE.INPUT}
              label={t('tooltips.allTooltipsTable.width.label')}
              loading={isLoadingTooltipById}
              maxLengthInput={4}
              name='width'
              placeholder={t('tooltips.allTooltipsTable.width.placeholder')}
              replacePattern={REGEX.REPLACE_ONLY_NUMBER}
            />
            <DataSource
              isDisabledObject={Boolean(id || copyId)}
              isLoading={isLoadingTooltipById || isLoadingObjects}
              objects={objects}
            />
          </Box>
          <Grid container justifyContent='flex-end'>
            <GeneralButtons
              cancelText={t('viewOrFormCreate.cancelBtnText')}
              saveDisabled={!isDirty}
              saveText={t('viewOrFormCreate.saveBtnText')}
              onCancel={handleCancel}
              onSave={handleSave}
            />
          </Grid>
          <PageContentLayout key={currentTab} sx={{ pt: 1, px: 1 }}>
            {isShowScriptValueDialogJson && (
              <ScriptValueDialog
                windowBoundaries
                isShow={isShowScriptValueDialogJson}
                language='json'
                title={t('tooltips.title.editJson')}
                value={watchedJsonValue || ''}
                onClose={() => setShowScriptValueDialogJson(false)}
                onSave={handleChangeValueScriptJson}
              />
            )}
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Tabs value={currentTab} onChange={handleTabChange}>
                <Tab disabled={disabledTab} label={t('tooltips.tabs.tooltipPoints')} />
                <Tab disabled={disabledTab} label={t('tooltips.tabs.config')} />
                <Tab disabled={disabledTab} label={t('tooltips.tabs.variables')} />
              </Tabs>
              <Box display='flex'>
                <Box alignItems='center' display='flex' flexWrap='wrap'>
                  <BtnCopyConfigExample name={t('tooltips.title.example')} />
                </Box>
                <FormInput
                  disabled={!id}
                  formInputContainerSx={{ mb: 0 }}
                  formLabelSx={{ minWidth: 90, width: 95 }}
                  inputType={GENERATOR_INPUT_TYPE.CHECKBOX}
                  label={t('label.json')}
                  labelPlacement='end'
                  labelSx={{ minWidth: 60, width: 60 }}
                  name='isJson'
                  onChangeCheckbox={handleChangeJsonMode}
                />
              </Box>
            </Box>
            {watchedIsJson ? (
              <Box>
                <FormInput
                  formLabelSx={{ my: 2 }}
                  inputType={GENERATOR_INPUT_TYPE.TEXTAREA}
                  maxLengthInput={MAX_INPUT_LENGTH}
                  name='jsonValue'
                  placeholder={t('contextMenuCreate.addMenuPointModal.titleJsValue.placeholder')}
                  readOnly={true}
                  rules={{ maxLength: MAX_INPUT_LENGTH }}
                  additionalBtn={{
                    isEnabled: true,
                    text: 'edit',
                    color: 'primary',
                    variant: 'contained',
                    onClick: () => setShowScriptValueDialogJson(true),
                  }}
                />
              </Box>
            ) : (
              <Box>
                <TabPanel index={0} value={currentTab}>
                  <SwitchTable
                    disablePagination
                    isCrud
                    btnDisabled={!isEdit}
                    btnText={t(`tooltips.button.addTooltipPoint`)}
                    columns={TOOLTIP_POINT_COLUMNS}
                    loading={isLoadingTooltipById || isLoadingTooltipJSON}
                    rows={watchedPoints || []}
                    showActionsColumn={true}
                    showDialog={isOpenDialog}
                    type={MODEL_TYPE.TOOLTIP}
                    containerSx={{
                      '.MuiTableBody-root > div': {
                        height: '400px !important',
                      },
                    }}
                    onDelete={handleDeletePoint}
                    onEdit={handleEditPoint}
                    onShowDialog={handleOpenPointDialog}
                  />
                </TabPanel>
                <TabPanel index={1} value={currentTab}>
                  <FieldsConfig tooltipId={id} />
                </TabPanel>
                <TabPanel index={2} value={currentTab}>
                  <Grid display='flex' justifyContent='flex-end' mb={1}>
                    <Button variant='contained' onClick={() => handleAddVariableItem()}>
                      {t('tooltips.button.addTooltipVariable')}
                    </Button>
                  </Grid>
                  <VariablesTab
                    variables={variableFields}
                    onDeleteVariable={handleOpenDeleteVariableModal}
                    onSaveVariable={handleSaveVariable}
                  />
                  <ConfirmModal
                    actionBtnText={t('modal.delete.btn')}
                    containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
                    isShow={isShowDeleteVariableModal}
                    text={t('modal.delete.warning')}
                    title={t('modal.delete.title')}
                    onClose={handleCloseDeleteVariableModal}
                    onConfirm={handleDeleteVariable}
                  />
                </TabPanel>
              </Box>
            )}
          </PageContentLayout>
        </FormProvider>
      </Box>
    </PageContext.Provider>
  )
}
