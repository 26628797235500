import React, { FC, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isArray } from 'lodash'
import { Button, FormGenerator } from '@microservices/wiskey-react-components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { ChartsAxisData } from '@mui/x-charts'
import { BarChart as MUIBarChart } from '@mui/x-charts/BarChart'

import { VewFilterChips } from '@pages/BarChartPage/components/ViewFilterChips'
import { addLabels } from '@pages/BarChartPage/helpers/addLabels'
import { useDataset } from '@pages/BarChartPage/hooks/useDataset'
import { useDatasetParams } from '@pages/BarChartPage/hooks/useDatasetParams'
import { useDefaultValues } from '@pages/BarChartPage/hooks/useDefaultValues'
import { useFilterInputs } from '@pages/BarChartPage/hooks/useFilterInputs'
import { useScriptEditorConfig } from '@pages/BarChartPage/hooks/useScriptEditorConfig'
import { useViewFilter } from '@pages/BarChartPage/hooks/useViewFilter'
import { ConfiguredEntity, GetConfiguredEntityParamsHandle } from '@pages/ConfiguredEntity'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { PageTitle } from '@components/PageTitle'
import { EditorBase } from '@components/ScriptValueDialog/EditorBase'

import { BIND_TYPE, ENTITY, ROUTES } from '@constants'
import { AutocompleteOption, EntityType } from '@types'

type BarChartPageProps = {
  id?: string
}

export type FormFiltersType = {
  planDay: string
  department: AutocompleteOption<string> | null
  qual: AutocompleteOption<string> | null
}

export const BarChartPage: FC<BarChartPageProps> = () => {
  const { t } = useTranslation()

  const filterInputs = useFilterInputs()
  const defaultValues = useDefaultValues()
  const methods = useForm<FormFiltersType>({ defaultValues })
  const {
    formState: { isDirty },
  } = methods

  const datasetParams = useDatasetParams(methods, defaultValues)
  const { dataset, loading } = useDataset(datasetParams)

  const [entity, setEntity] = useState<EntityType | null>(null)

  const viewRef = useRef<GetConfiguredEntityParamsHandle>(null)

  const { viewFilter, setViewFilter } = useViewFilter({ viewRef, datasetParams })
  const { editorValue, barChartConfig, handleEditorValueChange, handleEditorConfigSave } =
    useScriptEditorConfig()

  const { entityCode, entityId, showView } = useMemo(() => {
    const entityCode = barChartConfig.entityCode || null
    const entityId = barChartConfig.entityId || null
    const showView = Boolean(entityCode) && Boolean(entityId)

    return { entityCode, entityId, showView }
  }, [barChartConfig])

  const handleSetEntity = (value: EntityType | null) => setEntity(value)

  const handleAxisClick = (axisData: ChartsAxisData | null) => {
    if (!axisData) {
      return
    }

    if (!isArray(dataset)) {
      return
    }

    const data = dataset[axisData.dataIndex]

    if (!data) {
      return
    }

    const { from, to } = data

    setViewFilter({ from, to })
  }

  const handleDeleteFilter = () => {
    setViewFilter(null)
  }

  return (
    <Box>
      <PageTitle
        end={false}
        start={
          <>
            <Typography display={'inline'} sx={{ mr: 1, ml: 1 }} variant={'h6'}>
              {t('pageTitle.barChart')}
            </Typography>
            <Typography display={'inline'} variant={'subtitle2'}>
              {'Staff roster'}
            </Typography>
          </>
        }
      />
      <PageContentLayout>
        <Accordion sx={{ width: 550 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>{'Editor'}</AccordionSummary>
          <AccordionDetails>
            <EditorBase
              noValidate
              autoFocus={false}
              // customKeyWord={keyWordObj}
              language={BIND_TYPE.JS}
              placeholder={'Editor'}
              value={editorValue}
              boxSx={{
                mt: 0.5,
                maxHeight: 500,
                width: 500,

                overflowY: 'auto',
              }}
              style={{
                width: '100%',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                transition: 'all .5s ease-in-out',
                flexGrow: 1,
              }}
              onChange={handleEditorValueChange}
              // onChange={onChange}
              // onKeyDown={handleKeyDown}
              // onKeyUp={handleKeyUp}
            />
            <Button onClick={handleEditorConfigSave}>Save</Button>
          </AccordionDetails>
        </Accordion>
        <Box p={2}>
          <FormProvider {...methods}>
            <FormGenerator inputs={filterInputs} mainGridSpacing={0} />
          </FormProvider>
        </Box>
        <MUIBarChart
          dataset={dataset}
          height={450}
          loading={loading}
          slotProps={{ legend: { hidden: true } }}
          width={1250}
          xAxis={[{ scaleType: 'band', dataKey: 'hour' }]}
          series={addLabels([
            { dataKey: 'plan', stack: 'A' },
            { dataKey: 'fact', stack: 'B' },
          ])}
          onAxisClick={(_event, axisData) => {
            handleAxisClick(axisData)
          }}
        />
        {viewFilter && (
          <VewFilterChips filter={viewFilter} handleDeleteFilter={handleDeleteFilter} />
        )}
        {showView && (
          <ConfiguredEntity
            ref={viewRef}
            isViewDialogWindow // TODO Оказывается от этого свойства высота строк становится компактной
            entity={entity}
            entityCode={entityCode}
            path={ROUTES.BAR_CHART}
            showQuickSearch={false}
            title={'title'}
            type={ENTITY.VIEW}
            onSetEntity={handleSetEntity}
            entityId={entityId}
            // key={entityId}
            // ref={ref}
            // dialogId={dialogId}
            // handleOpenForm={handleOpenForm}
            // isSearchAssistantDialogWindow={isSearchAssistantDialogWindow}
            // path={path}
            // windowHeight={windowHeight}
            // isViewDialogWindow={true} //выставлен true для стилизации рабочей области как в окнах
          />
        )}
      </PageContentLayout>
    </Box>
  )
}
