import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'
import { Close } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import TextField from '@mui/material/TextField'

import {
  getColumnFieldName,
  getIsAvailableRuleByNames,
  getIsAvailableRuleByType,
  getIsAvailableRuleByTypeForValueType,
} from '@pages/ConfiguredEntity/helpers'

import { SelectBoolean } from '@components/SelectBoolean'

import { ScrollingParams, useDebounce } from '@hooks'
import { changeGlobalCheckBoxInColumnHeader } from '@helpers'
import { generalFieldStylesByMode } from '@helpers'
import {
  DIRTY_RULES,
  FIELD_VALUE_TYPE,
  QUICK_SEARCH_PATTERN_DELAY,
  QuickRuleType,
  SEARCH_RULE_EXCEPTION,
  SEARCH_RULE_OPTIONS,
  SEARCH_RULE_TYPE,
} from '@constants'
import { RULES_EXCEPTIONS } from '@constants'
import {
  ALL_CHECKBOXES_IN_COLUMN_EVENT_DETAIL,
  GLOBAL_CHECKBOX_IN_COLUMN_EVENT_DETAIL,
  ViewRow,
} from '@types'

import { EVENT_NAME, publish } from '../../events'

type QuickSearchProps = {
  isBooleanSearchMode: boolean
  isDialogWindow?: boolean
  loading?: boolean
  dialogId?: string
  searchValue: string
  searchRule: QuickRuleType
  columnNameCheckBox: Record<string, boolean>
  sortKeysColumnCheckBox: Record<string, boolean>
  scrollingParams: ScrollingParams
  columns: ViewRow[]
  setSearchRule: (rule: QuickRuleType, allCheckCheckbox: boolean) => void
  setGlobalFilter: (value: string, skipRequest?: boolean) => void
  setColumnNameCheckBox: (value: Record<string, boolean>) => void
  setSortKeysForQuickSearch: (value: Record<string, boolean>) => void
  setBooleanMode: (value: boolean) => void
}

export const QuickSearch: FC<QuickSearchProps> = ({
  isBooleanSearchMode,
  isDialogWindow,
  loading,
  dialogId,
  searchValue,
  searchRule = { name: SEARCH_RULE_TYPE.CONTAIN, exception: RULES_EXCEPTIONS.DEFAULT },
  columnNameCheckBox,
  sortKeysColumnCheckBox,
  scrollingParams,
  columns,
  setGlobalFilter,
  setSearchRule,
  setColumnNameCheckBox,
  setSortKeysForQuickSearch,
  setBooleanMode,
}) => {
  const { t } = useTranslation()

  const [disableField, setDisableField] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(true)
  const [value, setValue] = useState('')

  const debounceValue = useDebounce(value, QUICK_SEARCH_PATTERN_DELAY)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const handleReset = (): void => {
    setGlobalFilter('')
    setValue('')
  }

  // В зависимости от режима поиска, пользователю показываются разные правила поиска
  // В режиме isBooleanSearchMode отображаются только те правила, которые применимы
  // к valueType колонок = boolean
  const searchRuleOptions: AutocompleteOption[] = useMemo(() => {
    return isBooleanSearchMode
      ? SEARCH_RULE_OPTIONS.filter(ruleOption => {
          return getIsAvailableRuleByTypeForValueType(
            FIELD_VALUE_TYPE.BOOLEAN,
            ruleOption.id as SEARCH_RULE_TYPE
          )
        })
      : SEARCH_RULE_OPTIONS
  }, [isBooleanSearchMode])

  useEffect(() => {
    // Для сброса значения при смене правил
    if (!searchValue) {
      setValue('')
    }
  }, [searchValue])

  useEffect(() => {
    // для предотвращения запроса при монтировании
    if (searchInputRef.current) {
      setGlobalFilter(debounceValue)
    }

    if (isBooleanSearchMode) {
      setGlobalFilter(debounceValue)
    }
  }, [debounceValue])

  const handleChangeRulesSearch = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const nameRule = event.target.value as SEARCH_RULE_TYPE

    setDisableField(!DIRTY_RULES.includes(nameRule))
    const exception = SEARCH_RULE_EXCEPTION[nameRule]

    setSearchRule({ name: nameRule, exception }, hasAllAvailableCheckboxesSelected())
  }

  const hasAllAvailableCheckboxesSelected = (): boolean => {
    const disabledColumns = columns.filter(column => {
      return (
        !getIsAvailableRuleByType(column, searchRule.name) ||
        !getIsAvailableRuleByNames(column, searchRule.name)
      )
    })

    const columnNameCheckboxWithoutDisabled = Object.keys(columnNameCheckBox).filter(columnName => {
      return !disabledColumns.find(column => {
        return column.code === columnName
      })
    })

    const checkboxValues = columnNameCheckboxWithoutDisabled
      .filter(columnName => {
        const column = columns.find(col => getColumnFieldName(col) === columnName)

        return isBooleanSearchMode
          ? column?.valueType === FIELD_VALUE_TYPE.BOOLEAN
          : column?.valueType !== FIELD_VALUE_TYPE.BOOLEAN
      })
      .map(columnName => {
        if (columnNameCheckBox[columnName] === true) {
          return true
        }

        // Boolean колонки не выбираются в check all. Их можно выбрать только прямым нажатием.
        // При этом режим быстрого поиска поменяется на isBooleanSearchMode (состояние в useQuickSearch)
        return columnNameCheckBox[columnName]
      })

    return (
      checkboxValues.length > 0 && !checkboxValues.some(columnCheckboxValue => !columnCheckboxValue)
    )
  }

  const handleChangeCheckBox = (e: ChangeEvent<HTMLInputElement>): void => {
    const checked = e?.target?.checked
    const { page, ...params } = scrollingParams
    const { newColumnNameCheckbox, newSortKeysColumnCheckBox } = changeGlobalCheckBoxInColumnHeader(
      columnNameCheckBox,
      sortKeysColumnCheckBox,
      checked,
      setColumnNameCheckBox,
      setSortKeysForQuickSearch,
      searchRule.name,
      columns,
      isBooleanSearchMode
    )

    const globalCheckboxInColumnDetail: GLOBAL_CHECKBOX_IN_COLUMN_EVENT_DETAIL = {
      columnNameCheckbox: newColumnNameCheckbox,
      dialogId,
    }

    const allCheckboxesInColumnDetail: ALL_CHECKBOXES_IN_COLUMN_EVENT_DETAIL = {
      sortKeys: newSortKeysColumnCheckBox,
      scrollingParams: params,
      searchRule,
      searchValue,
      dialogId,
    }

    publish(EVENT_NAME.GLOBAL_CHECKBOX_IN_COLUMN, globalCheckboxInColumnDetail)
    publish(EVENT_NAME.ALL_CHECKBOXES_IN_COLUMN, allCheckboxesInColumnDetail)

    setChecked(checked)
  }

  const handleSelectSearchInputChange = (e: SelectChangeEvent<string | number>) => {
    setValue(e.target.value as string)
  }

  const searchInput = isBooleanSearchMode ? (
    <Box
      // Кастомный placeholder для селекта с true/false
      sx={{
        position: 'relative',
        ...(!value || (value !== 'true' && value !== 'false')
          ? {
              '&::before': {
                content: `"${t('configViews.allTab.searchBoolean')}"`,
                position: 'absolute',
                pointerEvents: 'none',
                top: 1,
                left: 15,
                color: theme => theme.palette.grey['500'],
                zIndex: theme => theme.zIndex.tooltip,
              },
            }
          : {}),
      }}
    >
      <SelectBoolean
        disabled={disableField}
        dotsIcon={false}
        isDialog={Boolean(dialogId)}
        value={value}
        onChange={handleSelectSearchInputChange}
      />
    </Box>
  ) : (
    <TextField
      ref={searchInputRef}
      disabled={disableField}
      placeholder={t('configViews.allTab.search')}
      value={value}
      sx={theme => ({
        ...generalFieldStylesByMode(theme, undefined, undefined, undefined, isDialogWindow),
      })}
      onChange={({ target: { value } }) => setValue(value)}
    />
  )

  return (
    <Grid container alignItems={'center'} display={'flex'} gap={1}>
      <Grid item sx={{ position: 'relative', width: 210 }}>
        <Typography>{searchInput}</Typography>

        <IconButton
          disabled={disableField || loading}
          type={'reset'}
          sx={{
            position: 'absolute',
            top: isDialogWindow ? 1 : 0,
            right: isDialogWindow ? 2 : 0,
            padding: isDialogWindow ? '1px' : 1,
            '& > .MuiSvgIcon-root': {
              fontSize: isDialogWindow ? '1.2rem' : '1.5rem',
            },
          }}
          onClick={handleReset}
        >
          <Close />
        </IconButton>
      </Grid>
      <TextField
        select
        disabled={loading}
        size='small'
        value={searchRule.name}
        sx={theme => ({
          ...generalFieldStylesByMode(theme, undefined, undefined, undefined, isDialogWindow),
          width: 150,
        })}
        onChange={handleChangeRulesSearch}
      >
        {searchRuleOptions.map(select => (
          <MenuItem key={select.id} value={select.id}>
            {select.label}
          </MenuItem>
        ))}
      </TextField>

      <FormControlLabel
        disabled={loading}
        control={
          <Checkbox
            checked={hasAllAvailableCheckboxesSelected()}
            size={isDialogWindow ? '1rem' : 'small'}
            sx={{
              color: theme => theme.palette.grey['800'],
              '&.Mui-checked': {
                color: theme => theme.palette.grey['800'],
              },
            }}
            onChange={handleChangeCheckBox}
          />
        }
        label={
          isBooleanSearchMode
            ? t('columnInputs.quickSearch.checkAllCheckbox.booleanLabel')
            : t('columnInputs.quickSearch.checkAllCheckbox.label')
        }
      />
    </Grid>
  )
}
