import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'

import { transformResponseWithPagination } from '@helpers'
import { URLS } from '@constants'
import {
  GETCriteriaData,
  GETCriteriaParams,
  GETCriteriaType,
  GETDefaultCriteriaParams,
  GETObjectWithDefaultCriteria,
  POSTCriteriaType,
  POSTDefaultCriteria,
  POSTVisibilityCriteriaType,
} from '@types'

import { commonAPI } from './common.api'

export const CriteriaApi = commonAPI.injectEndpoints({
  endpoints: build => ({
    createOrUpdateCriteria: build.mutation<GETCriteriaType, POSTCriteriaType>({
      query: criteria => ({
        url: URLS.FRONT_CFG + '/searchFilter',
        method: 'POST',
        body: criteria,
      }),
      invalidatesTags: ['Criterias'],
    }),
    addDefaultCriteria: build.mutation<void, POSTDefaultCriteria[]>({
      query: defaultCriterias => ({
        url: URLS.FRONT_CFG + '/searchFilter/default',
        method: 'POST',
        body: defaultCriterias,
      }),
      invalidatesTags: ['DefaultCriteria'],
    }),
    fetchDefaultCriteria: build.query<GETObjectWithDefaultCriteria[], GETDefaultCriteriaParams>({
      query: ({ searchAssistantId }) => ({
        url: URLS.FRONT_CFG + `/searchFilter/default/searchAssistant/id/${searchAssistantId}`,
      }),
      providesTags: ['DefaultCriteria'],
    }),
    fetchAllCriterias: build.query<GETCriteriaData, GETCriteriaParams>({
      query: ({ objectCode, searchAssistantId }) => ({
        url:
          URLS.FRONT_CFG +
          `/searchFilter/object/code/${objectCode}/searchAssistant/id/${searchAssistantId}`,
      }),
      transformResponse: (output: GETCriteriaType[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<GETCriteriaType[]>(output, meta),
      providesTags: ['Criterias'],
    }),
    fetchCriteriasList: build.query<GETCriteriaData, GETCriteriaParams>({
      query: ({ objectCode, sort, searchAssistantId }) => ({
        url: URLS.FRONT_CFG + `/searchFilter/searchAssistant/id/${searchAssistantId}`,
        params: {
          objectCode,
          sort,
        },
      }),
      transformResponse: (output: GETCriteriaType[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<GETCriteriaType[]>(output, meta),
    }),
    deleteCriteria: build.mutation<void, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/searchFilter/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Criterias'],
    }),
    fetchCriteriaById: build.mutation<GETCriteriaType, string>({
      query: code => ({
        url: URLS.FRONT_CFG + `/searchFilter/code/${code}`,
      }),
    }),
    updateVisibilityById: build.mutation<GETCriteriaType, POSTVisibilityCriteriaType>({
      query: criteria => ({
        url: URLS.FRONT_CFG + '/searchFilter/visibility',
        method: 'POST',
        body: criteria,
      }),
      invalidatesTags: ['Criteria', 'Search', 'Criterias'],
    }),
  }),
})

export const {
  useCreateOrUpdateCriteriaMutation,
  useFetchAllCriteriasQuery,
  useFetchCriteriasListQuery,
  useDeleteCriteriaMutation,
  useFetchCriteriaByIdMutation,
  useUpdateVisibilityByIdMutation,
  useAddDefaultCriteriaMutation,
  useFetchDefaultCriteriaQuery,
} = CriteriaApi
