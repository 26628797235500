import { useEffect, useMemo, useState } from 'react'
import { GridSortItem } from '@mui/x-data-grid'

import { showMessage } from '@redux/reducers/snackbar.reducer'

import { useAppDispatch } from '@hooks'
import { getSortParamRequest } from '@helpers'
import { SIZE_PAGE } from '@constants'

import { UseMutationType } from '../types'

export type ListQueryResponse<T> = {
  data: T[]
  totalCount: number
  totalCountElements: number
}

type FilterParam = string | undefined

export type SortType = {
  sortKey?: string
} & GridSortItem

export type ScrollingParams = {
  sort?: SortType[]
  filter?: FilterParam
  page: number
} & Record<string, unknown>

type QueryParameters = {
  size?: string
  skip?: boolean
} & Record<string, unknown>

export const useInfiniteScroll = <Payload, Return>(
  useGetDataList: UseMutationType<Payload, ListQueryResponse<Return>>,
  { size = SIZE_PAGE, skip: skipParam, ...outerQueryParameters }: QueryParameters & Payload,
  transformResponse?: (data: Return[]) => Return[],
  defaultScrollingParams?: Partial<ScrollingParams>
) => {
  const [skip, setSkip] = useState(skipParam || false)
  const [combinedData, setCombinedData] = useState<Return[]>([])
  const dispatch = useAppDispatch()

  const [scrollingParams, setScrollingParams] = useState<ScrollingParams>({
    page: 0,
    sort: undefined,
    filter: undefined,
    ...defaultScrollingParams,
  })

  useEffect(() => {
    setScrollingParams(prev => ({ ...prev, size }))
  }, [size])

  useEffect(() => {
    setSkip(Boolean(skipParam))
  }, [skipParam])

  const [getDataList, { data: listData, isLoading }] = useGetDataList()

  const totalPages = useMemo(() => listData?.totalCount || 1, [listData])

  const totalCountElements = useMemo(() => listData?.totalCountElements, [listData])

  // Поддержка сохранения параметра sort в url
  // const { sort: sortFromURL, setSort: setSortFromURL } = useSorting()
  // const sort = useMemo(() => getSortParam(scrollingParams.sort || []), [scrollingParams.sort])
  // useEffect(() => {
  //   if (sortFromURL.length && JSON.stringify(sortFromURL) !== JSON.stringify(sort)) {
  //     refresh({ sort: sortFromURL })
  //   }
  // }, [sortFromURL])

  const refresh = (
    params?: Omit<ScrollingParams, 'page'>,
    skip?: boolean
  ): Promise<ListQueryResponse<Return>> | undefined => {
    // const sort = params?.sort
    // const isSorting = sort && sort.length
    // setSortFromURL(isSorting ? getSortParam(sort) : undefined)
    if (skip !== undefined) {
      setSkip(skip)
    }

    return fetchDataList({ page: 0, ...params }, undefined, skip)
  }

  const changeSort = (sort: SortType[], params?: Record<string, unknown>) => {
    fetchDataList({ ...scrollingParams, ...params, page: 0, sort })
  }

  const fetchDataList = (
    newScrollingParams: ScrollingParams,
    newOuterParams?: Record<string, unknown>,
    newSkip?: boolean
  ): Promise<ListQueryResponse<Return>> | undefined => {
    if (!skip || !newSkip) {
      setScrollingParams(prev => ({ ...prev, ...newScrollingParams }))
      const outerParams = newOuterParams ? newOuterParams : outerQueryParameters
      const params = {
        size,
        ...outerParams,
        ...newScrollingParams,
        sort: getSortParamRequest(
          'sort' in newScrollingParams ? newScrollingParams.sort : scrollingParams.sort
        ),
        filter: 'filter' in newScrollingParams ? newScrollingParams.filter : scrollingParams.filter,
      } as Payload

      return getDataList({
        ...params,
      })
        .unwrap()
        .then((res: any) => {
          const { data } = res as ListQueryResponse<Return>
          const nextData = transformResponse ? transformResponse(data) : data

          // TODO Костыль. Иначе после сортировки падает ошибка No row with id # found
          setTimeout(() => {
            if (newScrollingParams.page === 0) {
              setCombinedData(nextData)
            } else {
              setCombinedData(previousData => [...previousData, ...nextData])
            }
          }, 0)

          return res
        })
        .catch(err => {
          dispatch(
            showMessage({ type: 'error', statusCode: err?.status, text: err?.data?.message })
          )
        })
    }
  }

  useEffect(() => {
    if (!skipParam) {
      fetchDataList({ page: 0, ...outerQueryParameters })
    }
  }, [skipParam])

  const readMore = (params?: ScrollingParams) => {
    if (scrollingParams.page < totalPages && !skip) {
      fetchDataList({ ...scrollingParams, ...params, page: scrollingParams.page + 1 })
    }
  }

  const handleSetParams = (params: ScrollingParams) => {
    setScrollingParams(params)
    fetchDataList(params)
  }

  const refetch = (outerParams?: Record<string, unknown>) => fetchDataList({ page: 0 }, outerParams)

  return {
    combinedData,
    currentPage: scrollingParams.page,
    currentSort: scrollingParams.sort,
    currentFilter: scrollingParams.filter,
    scrollingParams,
    totalPages,
    totalCountElements,
    refresh,
    refetch,
    readMore,
    setCombinedDataForce: setCombinedData,
    onSetParams: handleSetParams,
    onChangeSort: changeSort,
    isLoading,
  }
}

export default useInfiniteScroll
