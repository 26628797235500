import { URLS } from '@constants'
import { GETSearchParams, GETSearchType, POSTCreateSearchType, POSTSearchTitleType } from '@types'

import { commonAPI } from './common.api'

export const SearchApi = commonAPI.injectEndpoints({
  endpoints: build => ({
    createSearch: build.mutation<void, POSTCreateSearchType>({
      query: search => ({
        url: URLS.FRONT_CFG + '/search',
        method: 'POST',
        body: { ...search, overwriting: false },
      }),
      invalidatesTags: ['Search'],
    }),
    updateSearch: build.mutation<GETSearchType, POSTCreateSearchType>({
      query: body => ({
        url: URLS.FRONT_CFG + '/search',
        method: 'POST',
        body: { ...body, overwriting: true },
      }),
      invalidatesTags: ['Search'],
    }),
    updateSearchTitle: build.mutation<GETSearchType, POSTSearchTitleType>({
      query: body => ({
        url: URLS.FRONT_CFG + '/search/title',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Search'],
    }),
    fetchSearchByObjectAndSA: build.query<GETSearchType[], GETSearchParams>({
      query: ({ objectCode, searchAssistantId }) => ({
        url:
          URLS.FRONT_CFG +
          `/search/objectCode/${objectCode}/searchAssistant/id/${searchAssistantId}`,
      }),
      providesTags: ['Search'],
    }),
    fetchSearchBySearchCode: build.query<GETSearchType, string>({
      query: code => ({
        url: URLS.FRONT_CFG + `/search/viewCode/${code}`,
      }),
    }),
    deleteSearch: build.mutation<void, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/search/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Search'],
    }),
  }),
})

export const {
  useCreateSearchMutation,
  useDeleteSearchMutation,
  useFetchSearchBySearchCodeQuery,
  useFetchSearchByObjectAndSAQuery,
  useUpdateSearchTitleMutation,
  useUpdateSearchMutation,
} = SearchApi
