import { GETGroups, GETGroupsParams } from '@types'

import { commonAPI } from './common.api'

export const groupsAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchGroupsForObject: build.query<GETGroups, GETGroupsParams>({
      query: ({ objectCode }) => ({
        url: `/api/tool/groups/object/${objectCode}/groups`,
      }),
      providesTags: ['Group'],
    }),
    fetchAllGroups: build.query<any, void>({
      query: () => ({
        url: '/api/tool/groups/list',
      }),
    }),
  }),
})

export const { useFetchGroupsForObjectQuery, useFetchAllGroupsQuery } = groupsAPI
