import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { FIELD_VALUE_TYPE, OBJECT_FIELD_TYPE } from '@constants'
import { ObjectFieldDTO } from '@types'

type useResetAsCheckboxOnValueTypeChangeOptions = {
  objectField: ObjectFieldDTO | undefined
  watchedAsCheckbox: boolean
  watchedAsDuration: boolean
  asCheckboxInputName: string
  asDurationInputName: string
  isDirty: boolean
}

// Когда поля asСheckbox и asDuration скрываются, нужно ресетить их значения, чтобы случайно не отправилось true
// при иных valueType
export const useResetAsCheckboxOnValueTypeChange = ({
  objectField,
  watchedAsCheckbox,
  watchedAsDuration,
  asCheckboxInputName,
  asDurationInputName,
  isDirty,
}: useResetAsCheckboxOnValueTypeChangeOptions) => {
  const { setValue } = useFormContext()

  useEffect(() => {
    if (!objectField) {
      return
    }

    const valueType = objectField.valueType
    const type = objectField.type

    if (type === OBJECT_FIELD_TYPE.ENUM) {
      return
    }

    if (valueType !== FIELD_VALUE_TYPE.INTEGER && valueType !== FIELD_VALUE_TYPE.BOOLEAN) {
      if (watchedAsCheckbox && isDirty) {
        setValue(asCheckboxInputName, false)
      }
    }

    if (valueType !== FIELD_VALUE_TYPE.INTEGER) {
      if (watchedAsDuration && isDirty) {
        setValue(asDurationInputName, false)
      }
    }
  }, [objectField])
}
