import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { t } from 'i18next'
import { GridCellParams, GridSortModel } from '@mui/x-data-grid'

import {
  useCreateOrUpdateSearchAssistantMutation,
  useFetchSearchAssistantByIdQuery,
} from '@redux/api/searchAssistant.api'

import { usePrompt } from '@hooks'
import { ROUTES } from '@constants'
import { AutocompleteOption, GETCriteriaType } from '@types'

import { SearchAssistant } from '../../../types/searchAssistant'
import { deleteCriteriaType, fetchCriteriaType, updateVisibilityType } from '../types'

type useHandlersParams = {
  fetchCriteria: fetchCriteriaType
  deleteCriteria: deleteCriteriaType
  updateVisibility: updateVisibilityType
}

type FormValues = {
  internalId: string
  title: string
  objectFilter: AutocompleteOption | null
}

export const useHandlers = ({
  fetchCriteria,
  deleteCriteria,
  updateVisibility,
}: useHandlersParams) => {
  const [showAddSearchFilterModal, setShowAddSearchFilterModal] = useState(false)
  const [showDefaultSetting, setShowDefaultSetting] = useState(false)
  const [page, setPage] = useState(0)
  const [objectCodeState, setObjectCode] = useState<string>()
  const [sortParam, setSortParam] = useState<string>()
  const [currentSort, setCurrentSort] = useState<GridSortModel>([])
  const [editCriteria, setEditCriteria] = useState<GETCriteriaType | null>()
  const [templateTable, setCriteriaTable] = useState<GETCriteriaType[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [currentId, setCurrentId] = useState<number>()
  const [isEditMode, setEditMode] = useState(false)
  const [skipDirty, setSkipDirty] = useState(false)

  const { id } = useParams()
  const navigate = useNavigate()

  const isCreate = ROUTES.CONFIG_SEARCH_ASSISTANTS_CREATE === location.pathname
  const isEdit = Boolean(id)

  const handleOpenAddSearchFilterModal = (code?: string) => {
    if (code) {
      fetchCriteria(code)
        .unwrap()
        .then(res => setEditCriteria(res))
    }
    setShowAddSearchFilterModal(true)
  }

  const handleCloseAddSearchFilterModal = (isDirty?: boolean) => {
    if (isDirty && confirm(t('notifications.leave'))) {
      setShowAddSearchFilterModal(false)
      if (editCriteria) setEditCriteria(null)

      return
    }
    if (!isDirty) {
      setShowAddSearchFilterModal(false)
      if (editCriteria) setEditCriteria(null)
    }
  }

  const handleChangeObjectCode = (value: string | null) => {
    const objectCode = value || undefined
    if (objectCodeState === objectCode) {
      return
    }
    setPage(0)
    setCriteriaTable([])
    setObjectCode(objectCode)
  }

  const handleDeleteCriteria = (id: number) =>
    deleteCriteria(id)
      .unwrap()
      .then(() => {
        setCriteriaTable(prev => prev.filter(template => template.id !== id))
      })

  const handlePage = (value: number) => setPage(value)

  const handleSetSorting = (value: GridSortModel | null) => {
    const sortParam = value ? `${value[0].field},${value[0].sort}` : undefined
    const sort = value ? value : []

    setPage(0)
    setCriteriaTable([])
    setSortParam(sortParam)
    setCurrentSort(sort)
  }

  const handleVisibility = (params: GridCellParams<any, GETCriteriaType>, checked: boolean) => {
    updateVisibility({ id: params.row.id, visibility: checked })
      .unwrap()
      .then(data => {
        setCriteriaTable(prev =>
          prev.map(template => (template.id === params.row.id ? data : template))
        )
      })
  }

  const [createOrUpdateSearchAssistant, { isLoading: isLoadingCreateOrUpdate }] =
    useCreateOrUpdateSearchAssistantMutation()
  const { data: searchAssistantDataById, isLoading: loadingSearchAssistant } =
    useFetchSearchAssistantByIdQuery(id, { skip: !id })

  const handleClose = () => setShowDeleteModal(false)

  const handleCancel = () => {
    navigate(ROUTES.CONFIG_SEARCH_ASSISTANTS)
  }
  const methods = useForm<FormValues>({
    defaultValues: { internalId: '', title: '', objectFilter: null },
  })

  const {
    watch,
    reset,
    handleSubmit,
    formState: { dirtyFields },
  } = methods

  const watchObjectFilter = watch('objectFilter')
  const watchInternalId = watch('internalId')
  const dirtyInternalId = dirtyFields['internalId']
  const isDirty = dirtyFields['title'] || dirtyInternalId

  useEffect(() => {
    if (searchAssistantDataById) {
      reset({
        internalId: searchAssistantDataById?.internalId ? searchAssistantDataById.internalId : '',
        title: searchAssistantDataById?.title ? searchAssistantDataById.title : '',
      })
    }
  }, [searchAssistantDataById])

  const handleSave = handleSubmit((searchAssistantData: SearchAssistant) => {
    const { title, internalId } = searchAssistantData

    setSkipDirty(true)
    createOrUpdateSearchAssistant({
      ...(id ? { id } : {}),
      internalId,
      title,
    })
      .unwrap()
      .then(res => {
        if (id) {
          return
        }

        if (!res) {
          handleCancel()
        }

        navigate(`${ROUTES.CONFIG_SEARCH_ASSISTANTS_EDIT}/${res.id}`)
      })
      .finally(() => setSkipDirty(false))
      .catch(() => {
        //
      })
  })

  const handleEdit = (params: GridCellParams<any, GETCriteriaType>) => {
    handleOpenAddSearchFilterModal(params.row.code)
    setEditMode(true)
  }

  const handleNew = () => {
    setEditMode(false)
    handleOpenAddSearchFilterModal()
  }

  const handleDelete = () => {
    if (currentId) {
      handleDeleteCriteria(currentId)
      setShowDeleteModal(false)
    }
  }

  const handleClickDelete = (params: GridCellParams<any, GETCriteriaType>) => {
    setCurrentId(params.row.id)
    setShowDeleteModal(true)
  }

  const handleChangeModelSort = (value: GridSortModel | null) => {
    if (value?.length) {
      handleSetSorting(value)

      return
    }
    handleSetSorting(null)
  }

  const handleSetEditRow = (row: GETCriteriaType) => setEditCriteria(row)

  const handleSetCriteriaTable = setCriteriaTable

  const handleShowDefaultSettings = () => {
    setShowDefaultSetting(true)
  }

  const handleCloseDefaultSettings = (isDirty?: boolean) => {
    if (isDirty && confirm(t('notifications.leave'))) {
      setShowDefaultSetting(false)

      return
    }
    if (!isDirty) {
      setShowDefaultSetting(false)
    }
  }

  const isLeaveEdit = !isCreate && !loadingSearchAssistant && !skipDirty && isDirty
  const isLeaveCreate =
    !isEdit &&
    !isLoadingCreateOrUpdate &&
    !searchAssistantDataById &&
    !skipDirty &&
    Boolean(isDirty)

  usePrompt({ when: isLeaveEdit || isLeaveCreate })

  return {
    state: {
      showAddSearchFilterModal,
      showDefaultSetting,
      page,
      objectCodeState,
      sortParam,
      currentSort,
      editCriteria,
      templateTable,
      showDeleteModal,
      currentId,
      isEditMode,
      dirtyInternalId,
    },
    handlers: {
      handleOpenAddSearchFilterModal,
      handleCloseAddSearchFilterModal,
      handleChangeObjectCode,
      handleDeleteCriteria,
      handlePage,
      handleSetSorting,
      handleVisibility,
      handleClose,
      handleEdit,
      handleNew,
      handleDelete,
      handleClickDelete,
      handleChangeModelSort,
      handleSetEditRow,
      handleSetCriteriaTable,
      handleShowDefaultSettings,
      handleCloseDefaultSettings,
      handleCancel,
      handleSave,
    },
    data: {
      loadingSearchAssistant,
      id,
      isDirty,
      reset,
      methods,
      searchAssistantDataById,
      isEdit,
      watchObjectFilter,
      watchInternalId,
    },
  }
}
