export enum FIELD_TYPE {
  SINGLE = 'single',
  OBJECT_LINK = 'object_link',
}

/** Модель для отрисовки окна с конфигурацией табличного представления */
export type ObjectWithConfiguredRestrictions = {
  /** Заголовок объекта */
  objectTitle: string
  /** Код объекта */
  objectCode: string
  /** Список колонок объекта с ограничениями для отрисовки окна конфигурации */
  columns: ColumnWithConfiguredRestrictions[]
}

/** Колонка в конфигурации табличного представления */
export type ColumnWithConfiguredRestrictions = {
  /** Активация колонки */
  enabled: boolean
  /** Наименование поля */
  field: string
  /** Описание поля */
  desc: string
  /** Тип поля */
  type: FIELD_TYPE
  /** Базовая обязательность поля */
  baseRequired: boolean
  /** Выставленная пользовательская обязательность */
  userRequired: boolean
  /** Уникальность */
  unique: boolean
  /** Ограничение по символам */
  symbolsNumLimit: number | null
  /** Список вложенных полей, если текущее поле является ссылочным */
  embeddedFields: ConfiguredColumnEmbeddedField[] | null
}

/** Вложенное поле для ссылочного поля в конфигурации табличного представления  */
export type ConfiguredColumnEmbeddedField = {
  /** Наименование вложенного поля */
  name: string
  /** Активация колонки вложенного поля */
  enabled: boolean
}

/** Перечисление типов ограничения  */
export enum RESTRICTION_TYPE {
  /** Сортировка поля */
  SORT = 'SORT',
  /** Отображение колонки с данным полем у пользователя */
  SHOW = 'SHOW',
  /** Ширина колонки с данным полем у пользователя */
  WIDTH = 'WIDTH',
  /** Порядок колонки с данным полем в таблице у пользователя */
  ORDER = 'ORDER',
  /** Закрепление колонки справа/слева в таблице у пользователя */
  FIX = 'FIX',
}

/** Модель ограничения */
export type RestrictionDTO = {
  id?: number
  /** Внутренний идентификатор колонки */
  internalId: string
  /** Наименование родительского поля при наличии */
  parentFieldName: string | null
  /** Наименование поля */
  fieldName: string
  /** Код колонки */
  fieldCode: string
  /** id вью */
  viewId: string
  /** Тип ограничения */
  restrictionType: RESTRICTION_TYPE
  /** Значение ограничения */
  value: unknown
}

export type RestrictionKey = 'sort' | 'show' | 'width' | 'order' | 'fix'

export type CutRestrictionDTO = Omit<RestrictionDTO, 'objectCode' | 'restrictionType' | 'viewCode'>

export type RestrictionData = Record<RestrictionKey, CutRestrictionDTO[]>
