import { FC, useContext } from 'react'
import {
  GridColumnsPanel,
  GridPanel,
  GridPanelProps,
} from '@microservices/wiskey-react-table-component'
import { Backdrop, CircularProgress, Grid } from '@mui/material'

import { PageContext } from '@pages/ConfiguredEntity'

type CustomPanelComponentProps = {
  loading: boolean
} & GridPanelProps

const HIDDEN_COLUMN_OPTION_CLASSNAME = 'custom-column-option-hidden'

export const CustomPanelComponent: FC<CustomPanelComponentProps> = props => {
  const { isLoadingView, showActionsColumn, hasRowAction } = useContext(PageContext)

  // Таким способом скрываются колонки с ссылкой на форму (с пустым названием) и колонка с actions
  // в меню управления отображения колонок
  const handleProcessColumnOptions = () => {
    setTimeout(() => {
      const columnsOptions = document.querySelectorAll('div .MuiDataGrid-columnsPanelRow')

      if (!columnsOptions) {
        return
      }

      columnsOptions.forEach(columnOption => {
        if (columnOption.textContent === 'linkToForm' && hasRowAction) {
          columnOption.classList.add(HIDDEN_COLUMN_OPTION_CLASSNAME)
        }

        if (columnOption.textContent === 'actions' && showActionsColumn) {
          columnOption.classList.add(HIDDEN_COLUMN_OPTION_CLASSNAME)
        }
      })
    }, 0)
  }

  return (
    <Grid>
      <GridPanel
        {...props}
        sx={{
          position: 'relative',
        }}
      >
        <GridColumnsPanel
          sx={{
            [`& .${HIDDEN_COLUMN_OPTION_CLASSNAME}`]: {
              display: 'none !important',
            },
          }}
          // Скрытие при поиске колонок
          onKeyDown={() => handleProcessColumnOptions()}
          // Скрытие при первом рендере
          onBlur={() => handleProcessColumnOptions()}
        />
        <Backdrop open={isLoadingView} sx={{ color: '#fff', position: 'absolute' }}>
          <CircularProgress color='inherit' />
        </Backdrop>
      </GridPanel>
    </Grid>
  )
}
