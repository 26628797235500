import { FC, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GETObjectWithDefaultCriteria } from 'src/types'
import { GENERATOR_INPUT_TYPE, WIDTH_DEFAULT_CRITERIAS_MODAL } from '@constants'
import { FormInput } from '@microservices/wiskey-react-components'
import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'
import { AutocompleteRenderGetTagProps, Chip } from '@mui/material'

import { SearchAssistantsCreateOrEditContext } from '@pages/SearchAssistantsCreateOrEdit'

import { useFetchAllCriteriasQuery } from '@redux/api'

type ObjectInputProps = {
  object: GETObjectWithDefaultCriteria
}

export const ObjectInput: FC<ObjectInputProps> = ({ object }) => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const { searchAssistantId } = useContext(SearchAssistantsCreateOrEditContext)

  const { data: criteriasData, isLoading } = useFetchAllCriteriasQuery(
    {
      objectCode: object.objectCode,
      searchAssistantId: searchAssistantId ? searchAssistantId : '',
    },
    {
      skip: !object.defaultSearchFilters.length && !open,
      refetchOnMountOrArgChange: true,
    }
  )

  const criterias = criteriasData?.data

  const options = useMemo(
    () => criterias?.map(criteria => ({ id: criteria.id, label: criteria.title })) || [],
    [criterias]
  )
  const customRenderTags = (
    tagValue: AutocompleteOption[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => {
    return tagValue.map((option: AutocompleteOption, index: number) => {
      return (
        <Chip
          {...getTagProps({ index })}
          key={index}
          label={option.label}
          size='small'
          sx={index > 4 ? { background: '#cdcdcd' } : {}}
          title={t('searchAssistant.affects', {
            entity: index > 4 ? 'DropDown' : 'Search Assistant',
          })}
        />
      )
    })
  }

  return (
    <FormInput
      multiple
      autocompleteOptions={options}
      disablePortal={true}
      inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
      label={object.objectTitle}
      labelSx={{ width: WIDTH_DEFAULT_CRITERIAS_MODAL / 2, pr: 2 }}
      loading={isLoading}
      name={object.objectCode}
      open={open}
      autoCompleteRenderTags={(
        tagValue: AutocompleteOption[],
        getTagProps: AutocompleteRenderGetTagProps
      ) => customRenderTags(tagValue, getTagProps)}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  )
}
