import React, { Component, createRef, ReactNode, RefObject } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { connect } from 'react-redux'
import { Position, Rnd } from 'react-rnd'
import isNull from 'lodash/isNull'
import {
  FormStateMeta,
  UIOverlayCloseActionPayload,
  UIOverlayOpenActionPayload,
  UIOverlayState,
} from 'src/types'
import copy from '@assets/images/copy.svg'
import logo from '@assets/images/logo.svg'
import underline from '@assets/images/underline.svg'
import {
  Close as CloseIcon,
  ErrorOutlineRounded as ErrorOutlineRoundedIcon,
  UnfoldMore as UnfoldMoreIcon,
} from '@mui/icons-material'
import { Box, Grid, IconButton, Paper, Skeleton, Stack, Theme, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import { CustomSvgIcon } from '@components/CustomSvgIcon'
import { ErrorFallback } from '@components/ErrorFallback'

import { onCloseDialogWindow, onOpenDialogWindow } from '@redux/reducers/uiOverlay.reducer'
import { AppDispatch, RootState } from '@redux/store'

import { getHighestZIndexPriority } from '@helpers'
import { BLOCKING_DIALOG_ZINDEX_INCREASE, DRAGGABLE_FORM_SIZES, SCROLL_WIDTH } from '@constants'

import { EVENT_NAME, subscribe, unsubscribe } from '../../events'

import { observeRndTransform } from './helpers'

export type Size = {
  width: number
  height: number
}

type DraggableFormProps = {
  onSetSelectedDialogId: (id: string | null) => void
  formCode: string | null
  title: string
  id: string
  minWidth?: number
  onClose: (id: string, meta: FormStateMeta, isDirty?: boolean) => void
  isSelected: boolean
  theme: Theme
  disableDragging: boolean
  tooltipBtnCopyFromCode?: string
  children: (
    rnd: Rnd | null | undefined,
    state: Readonly<DraggableFormState>,
    onUpdateDirty: (value: boolean) => void,
    onSetTitleWindow: (title: string | null) => void
  ) => ReactNode
  initialPosition?: Position | null
  initialSize?: Size | null
  sizeBeforeFullscreen?: Size | null
  positionBeforeFullscreen?: Position | null
  isOpenBeforeFullscreen?: boolean
  isFullScreen?: boolean
  isOpen?: boolean
  hidden?: boolean
  icon?: ReactNode
  uiOverlay: UIOverlayState
  onCopyFormCode: (formCode: string) => void
  onOpenDialogWindow: (uiOverlayPayload: UIOverlayOpenActionPayload) => void
  onCloseDialogWindow: (uiOverlayPayload: UIOverlayCloseActionPayload) => void
}

export type DraggableFormState = {
  isOpen: boolean
  storedHeight: number
  storedWidth: number
  isDragging: boolean
  isFullscreen: boolean
  isOverlayShown: boolean
  positionBeforeFullscreen: Position | null
  sizeBeforeFullscreen: Size | null
  scrollLeftBeforeDragging: number | null
  isDirty: boolean
  isOpenBeforeFullscreen: boolean
  title: string | null
}

class DialogWindowComponent extends Component<DraggableFormProps, DraggableFormState> {
  rnd: Rnd | null | undefined
  wrapperRef: RefObject<HTMLDivElement> = createRef()
  containerRef: RefObject<HTMLDivElement> = createRef()
  windowResizeObserver: ResizeObserver | null = null
  buttonResizeRef: RefObject<HTMLButtonElement> = createRef()

  constructor(props: DraggableFormProps) {
    super(props)
    this.handleChangePositionEvent = this.handleChangePositionEvent.bind(this)
    this.handleChangeSizeEvent = this.handleChangeSizeEvent.bind(this)
    this.handleChangeFullScreenModeEvent = this.handleChangeFullScreenModeEvent.bind(this)

    this.state = {
      isOpen: true,
      storedHeight: 0,
      storedWidth: 0,
      isDragging: false,
      isFullscreen: false,
      isOverlayShown: false,
      positionBeforeFullscreen: null,
      sizeBeforeFullscreen: null,
      scrollLeftBeforeDragging: null,
      isDirty: false,
      isOpenBeforeFullscreen: true,
      title: null,
    }
  }

  updateSize() {
    if (!this.rnd) {
      return
    }

    this.rnd.updateSize(this.getDimentions())
  }

  handleChangePositionEvent(data: { detail: { id: string; position: Position } }) {
    if (data.detail.id === this.props.id) {
      this.rnd?.updatePosition(data.detail.position)
    }
  }

  handleChangeSizeEvent(data: { detail: { id: string; size: Size } }) {
    if (data.detail.id === this.props.id) {
      this.rnd?.updateSize(data.detail.size)
      this.setState(prev => ({
        ...prev,
        storedHeight: data.detail.size.height,
        storedWidth: data.detail.size.width,
      }))
    }
  }

  handleChangeFullScreenModeEvent(data: {
    detail: {
      id: string
      isFullscreen: boolean
      sizeBeforeFullscreen: Size | null
      positionBeforeFullscreen: Position | null
    }
  }) {
    if (data.detail.id === this.props.id) {
      if (this.state.isFullscreen !== data.detail.isFullscreen) {
        this.setState({
          isFullscreen: data.detail.isFullscreen,
          sizeBeforeFullscreen: data.detail.sizeBeforeFullscreen,
          positionBeforeFullscreen: data.detail.positionBeforeFullscreen,
        })
      }
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    const {
      sizeBeforeFullscreen,
      positionBeforeFullscreen,
      isOpen,
      isFullScreen,
      initialPosition,
      isOpenBeforeFullscreen,
    } = this.props

    this.setState(prev => ({
      ...prev,
      storedHeight: this.props.initialSize?.height || DRAGGABLE_FORM_SIZES.OPENED_HEIGHT,
      storedWidth: this.props.initialSize?.width || DRAGGABLE_FORM_SIZES.OPENED_WIDTH,
    }))

    if (isFullScreen) {
      this.toggleFullscreen(true)
    }

    if (sizeBeforeFullscreen) {
      this.setState(prev => ({ ...prev, sizeBeforeFullscreen }))
    }

    if (positionBeforeFullscreen) {
      this.setState(prev => ({
        ...prev,
        positionBeforeFullscreen,
      }))
    }

    if (isOpen !== undefined && isOpenBeforeFullscreen !== undefined) {
      // сохраняем позицию при перетаскивании полноэкранного, свернутого окна,
      // т.к. в функции this.toggleFullscreen при полноэкранном режиме обновляется позиция на (0;0)
      if (isFullScreen && !isOpen) {
        this.rnd?.updatePosition(initialPosition || { x: 0, y: 0 })
      }
      this.setState(prev => ({ ...prev, isOpen, isOpenBeforeFullscreen }))
    }

    // this.updateSize()
    this.handleSelect()
    setTimeout(() => {
      this.wrapperRef.current?.focus()
    }, 0)
    // Обработка возможного блюра окна rnd (см. комментарий хелпера)
    observeRndTransform(this.rnd)

    subscribe(EVENT_NAME.DIALOG_WINDOW_CHANGE_POSITION, this.handleChangePositionEvent)
    subscribe(EVENT_NAME.DIALOG_WINDOW_CHANGE_SIZE, this.handleChangeSizeEvent)
    subscribe(EVENT_NAME.DIALOG_WINDOW_CHANGE_FULLSCREEN_MODE, this.handleChangeFullScreenModeEvent)

    this.props.onOpenDialogWindow({
      dialogId: this.props.id,
      parentDialogId: null,
      isBlockingWindow: false,
    })
  }

  componentDidUpdate(prevProps: DraggableFormProps, prevState: DraggableFormState) {
    const formWasClosedBeforeSelection = !prevState.isOpen
    const formWasNotSelected = !prevProps.isSelected
    // Эта логика нужна для кейса, когда мы в таблице выбираем форму, которая была свернута.
    // Если это так, то мы ее раскрываем
    if (this.props.isSelected && formWasClosedBeforeSelection && formWasNotSelected) {
      this.handleOpen()
    }

    // if (prevState.isFullscreen && !this.state.isFullscreen) {
    //   this.updateSize()
    // }

    if (prevState.isFullscreen !== this.state.isFullscreen) {
      this.updateSize()
    }

    if (prevState.isOpen !== this.state.isOpen) {
      this.updateSize()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    // this.containerRef.current?.removeEventListener('scroll', this.blockScrollingWhileDragging)

    unsubscribe(EVENT_NAME.DIALOG_WINDOW_CHANGE_POSITION, this.handleChangePositionEvent)
    unsubscribe(EVENT_NAME.DIALOG_WINDOW_CHANGE_SIZE, this.handleChangeSizeEvent)
    unsubscribe(
      EVENT_NAME.DIALOG_WINDOW_CHANGE_FULLSCREEN_MODE,
      this.handleChangeFullScreenModeEvent
    )

    this.props.onCloseDialogWindow({ dialogId: this.props.id })
  }

  getDimentions() {
    return {
      width: this.state.isOpen
        ? this.state.storedWidth
          ? this.state.storedWidth
          : DRAGGABLE_FORM_SIZES.OPENED_WIDTH
        : DRAGGABLE_FORM_SIZES.CLOSED_WIDTH,
      height: this.state.isOpen
        ? this.state.storedHeight
          ? this.state.storedHeight
          : DRAGGABLE_FORM_SIZES.OPENED_HEIGHT
        : DRAGGABLE_FORM_SIZES.CLOSED_HEIGHT,
    }
  }

  getWindowSize(): Size | null {
    const bounding = this.rnd?.getSelfElement()?.getBoundingClientRect()
    if (bounding) {
      return { width: bounding.width, height: bounding.height }
    }

    return null
  }

  handleSetBodyOverflow(value: 'visible' | 'hidden') {
    const body = document.querySelector('body') as HTMLElement

    body.style.overflow = value
  }

  handleWindowResizeWhenFullscreen() {
    if (this.state.isOpen) {
      this.rnd?.updateSize({ width: window.innerWidth, height: window.innerHeight })
      this.setState(prev => ({
        ...prev,
        storedHeight: window.innerHeight,
        storedWidth: window.innerWidth,
      }))
    }
  }

  toggleFullscreen(value: boolean) {
    const body = document.querySelector('body') as HTMLElement
    const positionBeforeFullscreen = this.rnd?.getDraggablePosition() as Position

    if (!this.windowResizeObserver) {
      this.windowResizeObserver = new ResizeObserver(() => this.handleWindowResizeWhenFullscreen())
    }

    this.windowResizeObserver.observe(body)

    if (value) {
      this.rnd?.updatePosition({ x: 0, y: 0 })
      window.scrollTo(0, 0)
      this.handleSetBodyOverflow('hidden')
    } else {
      if (this.state.positionBeforeFullscreen) {
        this.rnd?.updatePosition(this.state.positionBeforeFullscreen)
      }

      if (this.state.sizeBeforeFullscreen) {
        this.rnd?.updateSize(this.state.sizeBeforeFullscreen)
      }
      this.handleSetBodyOverflow('visible')

      this.windowResizeObserver && this.windowResizeObserver.unobserve(body)
    }
    // Запоминаем sizeBeforeFullscreen при сворачивании окна
    const sizeBeforeFullscreen = !this.state.isOpen
      ? this.state.sizeBeforeFullscreen
      : this.getWindowSize()

    this.setState(prev => ({
      ...prev,
      storedHeight: value
        ? window.innerHeight
        : this.state.sizeBeforeFullscreen?.height ?? DRAGGABLE_FORM_SIZES.OPENED_HEIGHT,
      storedWidth: value
        ? window.innerWidth
        : this.state.sizeBeforeFullscreen?.width ?? DRAGGABLE_FORM_SIZES.OPENED_WIDTH,
      positionBeforeFullscreen: value ? positionBeforeFullscreen : null,
      sizeBeforeFullscreen: value ? sizeBeforeFullscreen : null,
      isOpenBeforeFullscreen: this.state.isOpen,
      isOpen: value || this.state.isOpenBeforeFullscreen,
      isFullscreen: value,
      isOverlayShown: value,
    }))
    this.buttonResizeRef?.current?.blur()
  }

  handleClickOutside(event: MouseEvent) {
    const target = event.target as Node

    if (isNull(target)) {
      return
    }

    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(target)) {
      this.props.onSetSelectedDialogId(null)
    }
  }

  updateDirty = (value: boolean) => {
    this.setState({ isDirty: value })
  }

  setTitle = (title: string | null) => {
    this.setState(prev => ({ ...prev, title }))
  }

  handleMinimize() {
    this.handleSetBodyOverflow('visible')

    if (this.state.isFullscreen) {
      this.rnd?.updatePosition({
        x: this.state.positionBeforeFullscreen?.x ?? 0,
        y: this.state.positionBeforeFullscreen?.y ?? 0,
      })
    }

    this.setState(prev => ({
      ...prev,
      isOpen: false,
      storedWidth: DRAGGABLE_FORM_SIZES.CLOSED_WIDTH,
      storedHeight: DRAGGABLE_FORM_SIZES.CLOSED_HEIGHT,
    }))
  }

  handleOpen() {
    if (this.state.isFullscreen) {
      this.rnd?.updatePosition({ x: 0, y: 0 })
      window.scrollTo(0, 0)
      this.handleSetBodyOverflow('hidden')
      this.setState({
        isOverlayShown: true,
        isOpen: true,
        storedHeight: window.innerHeight,
        storedWidth: window.innerWidth,
      })

      return
    }

    this.setState({
      isOpen: true,
      storedWidth: DRAGGABLE_FORM_SIZES.OPENED_WIDTH,
      storedHeight: DRAGGABLE_FORM_SIZES.OPENED_HEIGHT,
    })
  }

  handleSelect(event?: React.MouseEvent<HTMLDivElement>) {
    if (this.props.isSelected) {
      return
    }

    if (event?.shiftKey) {
      return
    }

    this.props.onSetSelectedDialogId(this.props.id)
  }

  handleDragStart() {
    const draggingContainer = document.getElementById(`disable-dragging-container_${this.props.id}`)

    document.body.style.overflowY = 'hidden'

    if (draggingContainer?.style) {
      draggingContainer.style.overflow = 'hidden'
      // Чтобы предотвратить дёрганье вертски
      const hasVerticalScroll = draggingContainer.scrollHeight > draggingContainer.clientHeight
      const hasHorizontalScroll = draggingContainer.scrollWidth > draggingContainer.clientWidth

      if (hasVerticalScroll) {
        const paddingRightValue = Number(draggingContainer.style.paddingRight.split('px')[0])

        draggingContainer.style.paddingRight = `${paddingRightValue + SCROLL_WIDTH}px`
      }

      if (hasHorizontalScroll) {
        const paddingBottomValue = Number(draggingContainer.style.paddingBottom.split('px')[0])
        draggingContainer.style.paddingBottom = `${paddingBottomValue + SCROLL_WIDTH}px`
      }
    }

    // Окно становится selected, когда начинается его перетаскивание (https://jiradc.utg.group/browse/WF-1786)
    this.handleSelect()
  }

  handleDragStop() {
    const draggingContainer = document.getElementById(`disable-dragging-container_${this.props.id}`)

    document.body.style.overflow = 'auto'

    if (draggingContainer?.style) {
      const hasVerticalScroll = draggingContainer.scrollHeight > draggingContainer.clientHeight
      const hasHorizontalScroll = draggingContainer.scrollWidth > draggingContainer.clientWidth

      draggingContainer.style.overflow = 'auto'

      if (hasVerticalScroll) {
        const paddingRightValue = Number(draggingContainer.style.paddingRight.split('px')[0])

        draggingContainer.style.paddingRight = `${paddingRightValue - SCROLL_WIDTH}px`
      }

      if (hasHorizontalScroll) {
        const paddingBottomValue = Number(draggingContainer.style.paddingBottom.split('px')[0])
        draggingContainer.style.paddingBottom = `${paddingBottomValue - SCROLL_WIDTH}px`
      }
    }

    // Убрано в handleDragStart в процессе решения https://jiradc.utg.group/browse/WF-1786
    // this.handleSelect()
  }

  handleResizeStop(ref: HTMLElement) {
    this.setState({
      storedWidth: Number(ref.style.width.substring(0, ref.style.width.length - 2)),
      storedHeight: Number(ref.style.height.substring(0, ref.style.height.length - 2)),
    })
  }

  handleContainerScroll() {
    // Нужно для скрытия дропдауна у autocomplete
    // Решение было получено в ходе решения этой проблемы:
    // https://github.com/mui/material-ui/issues/25564
    const documentActiveElement = document.activeElement as HTMLElement

    if (documentActiveElement.classList.contains('MuiAutocomplete-input')) {
      documentActiveElement.blur()
    }
  }

  resizeWindowWithHeader(): void {
    // default size window (800*600) -> fullscreen
    if (this.state.isOpen && !this.state.isFullscreen) {
      this.toggleFullscreen(true)

      return
    }
    // fullscreen -> default size window
    if (this.state.isOpen && this.state.isFullscreen) {
      this.toggleFullscreen(false)

      return
      // min size window(160*40) -> default size
    }
    if (!this.state.isOpen && !this.state.isFullscreen) {
      this.handleSetBodyOverflow('hidden')
      this.setState({
        isOpen: true,
        storedWidth: DRAGGABLE_FORM_SIZES.OPENED_WIDTH,
        storedHeight: DRAGGABLE_FORM_SIZES.OPENED_HEIGHT,
      })

      return
    }
  }

  handleGetShouldApplyBlockingZIndex() {
    const uiOverlay = this.props.uiOverlay

    const currentDialogDataFromOverlayTree = uiOverlay.dialogTreeData[this.props.id]

    if (!currentDialogDataFromOverlayTree) {
      return false
    }

    const { isBlockingWindow, zIndexPriority } = currentDialogDataFromOverlayTree
    const highestZIndexPriority = getHighestZIndexPriority(uiOverlay.dialogTreeData)

    return isBlockingWindow && zIndexPriority === highestZIndexPriority
  }

  render() {
    const {
      title,
      id,
      initialPosition,
      initialSize,
      hidden,
      onClose,
      uiOverlay,
      formCode,
      onCopyFormCode,
    } = this.props
    const shouldApplyBlockingZIndex = this.handleGetShouldApplyBlockingZIndex()

    return (
      <>
        {/** Оверлей, чтобы в режиме полного экрана при задержке при изменении масштаба страницы
         * не было видно содержимого страницы вне формы */}
        {this.state.isOverlayShown && (
          <Paper
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100vh',
              zIndex: theme => theme.zIndex.fab,
            }}
          />
        )}
        <Box
          ref={this.wrapperRef}
          tabIndex={0}
          sx={{
            ...(hidden && {
              display: 'none',
              '& .MuiButtonBase-root, & .MuiBadge-root': { display: 'none' },
            }),
            '.draggable-form__handler': {
              cursor: this.state.isOverlayShown ? 'default' : 'move !important',
            },
          }}
          onClick={event => this.handleSelect(event)}
        >
          <Rnd
            ref={c => {
              this.rnd = c
            }}
            bounds='window'
            cancel={`.disable-dragging-container_${id}`}
            disableDragging={this.props.disableDragging}
            dragHandleClassName={'draggable-form__handler'}
            enableResizing={this.state.isOpen && !this.state.isFullscreen}
            minHeight={DRAGGABLE_FORM_SIZES.CLOSED_HEIGHT}
            default={{
              x: initialPosition?.x ?? 0,
              y: initialPosition?.y ?? 0,
              ...this.getDimentions(),
              ...(initialSize && { ...initialSize }),
            }}
            resizeHandleStyles={{
              right: {
                width: '7px',
              },
              topRight: {
                top: '-5px',
                right: '-5px',
              },
              topLeft: {
                top: '-5px',
                left: '-5px',
              },
              bottomRight: {
                bottom: '-5px',
                right: '-5px',
              },
              bottomLeft: {
                bottom: '-5px',
                left: '-5px',
              },
            }}
            style={{
              backgroundColor: this.props.theme.palette.background.paper,
              boxShadow: '6px 6px 6px 0px #00000040',
              overflow: 'hidden',
              cursor: 'default !important',
              zIndex: this.props.isSelected
                ? shouldApplyBlockingZIndex
                  ? this.props.theme.zIndex.modal + BLOCKING_DIALOG_ZINDEX_INCREASE
                  : this.props.theme.zIndex.modal - 1
                : shouldApplyBlockingZIndex
                ? this.props.theme.zIndex.modal + BLOCKING_DIALOG_ZINDEX_INCREASE
                : this.props.theme.zIndex.modal - 2,
              border: `1px solid ${grey[400]}`,
              position: 'fixed',
            }}
            onDragStart={() => this.handleDragStart()}
            onDragStop={() => this.handleDragStop()}
            onResizeStop={(e, direction, ref, delta, position) => this.handleResizeStop(ref)}
          >
            <Stack sx={{ width: '100%', height: '100%' }}>
              <Box
                sx={{
                  color: this.props.theme.palette.color.formWindowHeader,
                  background: this.props.theme.palette.primary.main,
                  height: !this.state.isOpen ? '100%' : 'auto',
                  pl: 2,
                  pr: 1,
                  py: 0.1,
                }}
                onDoubleClick={() => this.resizeWindowWithHeader()}
              >
                <Grid
                  container
                  alignItems='center'
                  className='draggable-form__handler'
                  justifyContent='space-between'
                >
                  <Grid
                    container
                    item
                    sx={{
                      alignItems: 'center',
                      gap: '20px',
                      textOverflow: this.state.isOpen ? 'auto' : 'ellipsis',
                      width: this.state.isOpen ? 'auto' : '162px',
                      overflowX: 'hidden',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Grid item pt='5px'>
                      {this.props.icon || (
                        <CustomSvgIcon src={logo} sx={{ width: 24, height: 26 }} />
                      )}
                    </Grid>
                    <Typography noWrap aria-label={title} title={title} variant='subtitle1'>
                      {title || this.state.title || <Skeleton width={200} />}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {this.state.isOpen ? (
                      <>
                        {formCode && (
                          <IconButton
                            title={`${this.props.tooltipBtnCopyFromCode} (${formCode})`}
                            onClick={() => onCopyFormCode(formCode)}
                          >
                            <ErrorOutlineRoundedIcon
                              fontSize='small'
                              sx={{ color: theme => theme.palette.background.formCardContainer }}
                            />
                          </IconButton>
                        )}

                        <IconButton
                          onClick={() => {
                            this.handleMinimize()
                          }}
                        >
                          <CustomSvgIcon src={underline} sx={{ width: 20, height: 20 }} />
                        </IconButton>

                        {this.state.isFullscreen ? (
                          <IconButton
                            onClick={() => {
                              this.toggleFullscreen(false)
                            }}
                          >
                            <CustomSvgIcon src={copy} sx={{ width: 20, height: 20 }} />
                          </IconButton>
                        ) : (
                          <IconButton
                            ref={this.buttonResizeRef}
                            onClick={() => {
                              this.toggleFullscreen(true)
                            }}
                          >
                            <CustomSvgIcon src={copy} sx={{ width: 20, height: 20 }} />
                          </IconButton>
                        )}
                      </>
                    ) : (
                      <>
                        <IconButton
                          onClick={() => {
                            this.handleOpen()
                          }}
                        >
                          <UnfoldMoreIcon
                            fontSize='small'
                            sx={{
                              transform: 'rotateZ(45deg)',
                              color: this.props.theme.palette.color.formWindowHeader,
                            }}
                          />
                        </IconButton>
                        <IconButton
                          ref={this.buttonResizeRef}
                          onClick={() => {
                            // this.handleOpen()
                            this.toggleFullscreen(true)
                          }}
                        >
                          <CustomSvgIcon src={copy} sx={{ width: 20, height: 20 }} />
                        </IconButton>
                      </>
                    )}
                    <IconButton
                      onClick={() => {
                        onClose(
                          id,
                          {
                            size: {
                              height:
                                this.state.isFullscreen && !this.state.isOpenBeforeFullscreen
                                  ? DRAGGABLE_FORM_SIZES.OPENED_HEIGHT
                                  : this.state.storedHeight,
                              width:
                                this.state.isFullscreen && !this.state.isOpenBeforeFullscreen
                                  ? DRAGGABLE_FORM_SIZES.OPENED_WIDTH
                                  : this.state.storedWidth,
                            },
                            position: this.rnd?.getDraggablePosition(),
                            isOpen: this.state.isOpen,
                            isFullscreen: this.state.isFullscreen,
                            sizeBeforeFullscreen: this.state.sizeBeforeFullscreen,
                            positionBeforeFullscreen: this.state.positionBeforeFullscreen,
                            isOpenBeforeFullscreen: this.state.isOpenBeforeFullscreen,
                          },
                          this.state.isDirty
                        )

                        if (this.state.isFullscreen) {
                          this.handleSetBodyOverflow('visible')
                        }
                      }}
                    >
                      <CloseIcon
                        fontSize='small'
                        sx={{ color: this.props.theme.palette.color.formWindowHeader }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
              <Box
                ref={this.containerRef}
                className={`.disable-dragging-container_${id}`}
                id={`disable-dragging-container_${id}`}
                sx={{
                  overflowY: 'auto',
                  height: '100%',
                  cursor: 'default !important',
                  border: theme => `2px solid ${theme.palette.primary.main}`,
                  borderTop: 0,
                }}
                onScroll={() => this.handleContainerScroll()}
              >
                <Box
                  sx={{
                    height: 'calc(100% - 10px)',
                    ...(!this.state.isOpen && { display: 'none' }),
                    minWidth: this.props.minWidth || DRAGGABLE_FORM_SIZES.MIN_WIDTH,
                  }}
                >
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    {this.props.children(this.rnd, this.state, this.updateDirty, this.setTitle)}
                  </ErrorBoundary>
                </Box>
              </Box>
            </Stack>
          </Rnd>
        </Box>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onOpenDialogWindow: (uiOverlayPayload: UIOverlayOpenActionPayload) => {
      dispatch(onOpenDialogWindow(uiOverlayPayload))
    },
    onCloseDialogWindow: (uiOverlayPayload: UIOverlayCloseActionPayload) => {
      dispatch(onCloseDialogWindow(uiOverlayPayload))
    },
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    uiOverlay: state.uiOverlay,
  }
}

export const DialogWindow = connect(mapStateToProps, mapDispatchToProps)(DialogWindowComponent)
