import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IEvent {
  type: string
  title?: string
  src?: string
  icon?: string
  label?: string
}

const initialState: {
  title: string
  leftEvents: IEvent[]
} = {
  title: '',
  leftEvents: [],
}

const appFooter = createSlice({
  name: 'appFooter',
  initialState,
  reducers: {
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload
    },
    resetTitle(state) {
      state.title = ''
    },
    setLeftEvents(state, action: PayloadAction<IEvent[]>) {
      state.leftEvents = action.payload
    },
    resetLeftEvents(state) {
      state.leftEvents = []
    },
  },
})

export default appFooter.reducer
export const { setTitle, resetTitle, setLeftEvents, resetLeftEvents } = appFooter.actions
