import { GridSortDirection } from '@mui/x-data-grid/models/gridSortModel'

import { ACTION_CODE, EVENT_CODE_GANTT, FIELD_VALUE_TYPE, PINNED_COLUMN } from '@constants'
import {
  ColumnParametersType,
  ENTITY_COMMAND_TYPE,
  EntityCommandDTO,
  GETDictionary,
  ModalType,
  ObjectFieldDTO,
  ObjectShortDTO,
  ShareColumnType,
} from '@types'

import { JS_FUN_ARGS } from '@gantt/constants'

import { GanttParametersFormType } from '../components/GanttConfiguration/components/GeneralForm/hooks/useGanttParametersForm'

export type ModelType = 'editModel' | 'newModel'

export enum SegmentType {
  SINGLE = 'single',
  OWN_SECTIONS = 'own-sections',
  REF_SECTIONS = 'ref-sections',
  MULTI = 'multi',
}

export enum SegmentFieldType {
  DATETIME_START = 'datetimeStart',
  DATETIME_END = 'datetimeEnd',
  TITLE = 'title',
  TOOLTIP = 'tooltip',
  EMPTY = 'empty',
}

export enum SORT_BINDING_TYPE {
  STATIC = 'static',
  JSON = 'json',
}

export enum FILTER_BINDING_TYPE {
  STATIC = 'static',
  // JSON = 'json',
}

export enum TITLE_BINDING_TYPE {
  FIELD = 'field',
  JS = 'js',
  STATIC = 'static',
  FIELD_ARRAY = 'field_array',
}

export enum TOOLTIP_BINDING_TYPE {
  FIELD = 'field',
  JS = 'js',
  STATIC = 'static',
  FIELD_ARRAY = 'field_array',
  JSON = 'json',
}

export enum BACKGROUND_BINDING_TYPE {
  STATIC = 'static',
  JS = 'js',
}

export enum BIND_TYPE_INPUTS_FORM_TYPE {
  RESOURCE = 'resource',
  TIMELINE = 'timeline',
}

export enum SORT_ORDER_TYPE {
  ASC = 'asc',
  DESC = 'desc',
}

export enum AXIS_TYPE {
  Y = 'Y',
  X = 'X',
}

export enum LINES_WIDTH_BINDING_TYPE {
  STATIC = 'static',
  JS = 'js',
}

export enum BORDERS_WIDTH_BINDING_TYPE {
  STATIC = 'static',
  JS = 'js',
}

export const enum UNIT_INTERVAL_OPTIONS {
  MINUTE = 'minute',
  QUARTER = 'quarter',
  HALF = 'half',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export type GanttSegmentType = {
  id: number
  value: SegmentType
  name: string
}

export type AvailableVariables = Record<JS_FUN_ARGS, string[]>

export type PageContextType = {
  objects?: ObjectShortDTO[]
  showDialog: boolean
  modalType: ModalType
  bindingValuesColumns?: GETDictionary[]
  objectFields?: ObjectFieldDTO[]
  objectFieldsForFilter?: ObjectFieldDTO[]
  currentRow?: (GanttColumnForm & GanttActionType & SectionType) | null
  rawObjectFields?: ObjectFieldDTO[]
  objectCodeResource?: string
  objectCodeTimelines?: string
  bindingValuesActionResourceEvents: GETDictionary[]
  bindingValuesActionTimelineEvents: GETDictionary[]
  bindingValuesResourceAction?: GETDictionary[]
  bindingValuesTimelineAction?: GETDictionary[]
  ganttCode: string
  watchedObjectResource: AutocompleteOption<string> | null
  watchedObjectTimeline: AutocompleteOption<string> | null
  currentColumn: GanttColumnForm
  columnToDelete?: number | string
  currentConfiguration?: GanttFormValuesBack
  currentActionResource?: GanttActionType
  currentActionTimeline?: GanttActionType
  actionResourceToDelete?: string | number
  actionTimelineToDelete?: string | number
  GANTT_SEGMENT_TYPE_OPTIONS: GanttSegmentType[]
  currentColumnsList: GanttColumnForm[]
  currentSectionsList: SectionType[]
  currentSegment?: SectionType
  segmentToDelete?: string | number
  currentBarIndex: number
  currentBarObject?: AutocompleteOption<string> | null
  availableVariables: AvailableVariables
  currentActionResourceList: GanttActionType[]
  currentActionTimelineList: GanttActionType[]
  isSuccessCreated: boolean
  existingPaths: string[]
  selectedPaths: string[]
} & ContextHandlers

type ContextHandlers = {
  handleOpenDialog: (props: {
    type: ModalType
    id?: number | string
    row?: GanttColumnForm | GanttActionType | SectionType | null
    barIndex?: number | null
    selectedBarObject?: AutocompleteOption<string> | null
    inputAvailableVariables?: AvailableVariables
  }) => void
  handleCloseModal: (isDirty?: boolean) => void
  handleEdit: (
    row: GanttColumnForm | GanttActionType | SectionType | null,
    id?: number | string,
    isAction?: boolean
  ) => void
  handleHide: (row: GanttColumnForm, checked: boolean) => void
  onSetModalProperty?: (type: ShareColumnType) => void
  onSetObjectCodeResource: (code: string) => void
  onSetObjectCodeTimelines: (code: string) => void
  addGantt: (gantt: GanttFormValues) => Promise<any>
  onSetWatchedObjectResource: (value: AutocompleteOption<string> | null) => void
  onSetWatchedObjectTimeline: (value: AutocompleteOption<string> | null) => void
  onSetCurrentColumn: (column: GanttColumnForm) => void
  onSetColumnToDelete: (id: number) => void
  onDeleteColumn: (id: string | number) => void
  onSetCurrentConfiguration: (configuration: GanttFormValuesBack) => void
  onSetCurrentActionResource: (action: GanttActionType) => void
  onSetCurrentActionTimeline: (action: GanttActionType) => void
  onSetActionToDeleteResource: (id: string | number) => void
  onSetActionToDeleteTimeline: (id: string | number, barIndex: number) => void
  onSetCurrentColumnsList: (value: GanttColumnForm[]) => void
  onSetCurrentSectionsList: (value: SectionType[]) => void
  onSetCurrentSegment: (value: SectionType) => void
  onSetSegmentToDelete: (id: string | number, barIndex: number) => void
  onSetCurrentBarIndex: (index: number) => void
  onSetCurrentBarObject: (barObject: AutocompleteOption<string> | null) => void
  onSetCurrentActionTimelineList: (action: GanttActionType[]) => void
  onSetCurrentActionResourceList: (action: GanttActionType[]) => void
  onSetIsSuccessCreated: (value: boolean) => void
}

export type EmbeddedObject = {
  objectCode: string
  valueType: FIELD_VALUE_TYPE
  refValueType: FIELD_VALUE_TYPE
  field?: string
  isPk?: boolean
  isInternalId?: boolean
  description?: string
}

export type LinkType = {
  refObjTimelines: string
  refObjJobs: string
  embeddedObjectPickerTimelines: EmbeddedObject[]
  embeddedObjectPickerJobs: EmbeddedObject[]
}

export type AutocompleteOption<T = number | string> = {
  id: T
  label: string
}

export type ConfigField = {
  pathStr: string
  pathArray: EmbeddedObject[]
  field: string
  commands?: GanttFormCommands
}

export enum GANTT_BIND_TYPE_LIST {
  FIELD = 'field',
  JS = 'js',
  STATIC = 'static',
  FIELD_ARRAY = 'field_array',
  JSON = 'json',
  CUSTOM = 'custom',
  COMMANDS = 'commands',
  COMMAND = 'command',
  FORM = 'form',
}

export type BindTypeField = {
  bindType: GANTT_BIND_TYPE_LIST.FIELD
  field: ConfigField | undefined
}

export type BindTypeFieldArr = {
  bindType: GANTT_BIND_TYPE_LIST.FIELD_ARRAY
  field_array: ConfigField[] | undefined
}

export type BindTypeStatic = {
  bindType: GANTT_BIND_TYPE_LIST.STATIC
  static: string | undefined
}

export type BindTypeStaticBoolean = {
  bindType: GANTT_BIND_TYPE_LIST.STATIC
  static: boolean | undefined
}

export type BindTypeJs = {
  bindType: GANTT_BIND_TYPE_LIST.JS
  js: string | undefined
}

export type BindTypeJson = {
  bindType: GANTT_BIND_TYPE_LIST.JSON | null
  json: string | undefined
}

export type BindTypeCustom = {
  bindType: GANTT_BIND_TYPE_LIST.CUSTOM
  custom: any
}

export type BindTypeCommand = {
  bindType: GANTT_BIND_TYPE_LIST.COMMAND
  command: string
}

export type BindTypeCommands = {
  bindType: GANTT_BIND_TYPE_LIST.COMMANDS
  commands: EntityCommandDTO[]
}

export type BindTypeForm = {
  bindType: GANTT_BIND_TYPE_LIST.FORM
  form: AutocompleteOption<string>
}

export enum CONTEXT_MENU_BIND_TYPE {
  JSON = 'json',
  JS = 'js',
}

export enum OPEN_MODAL_BIND_TYPE {
  JSON = 'json',
  CUSTOM = 'custom',
  FORM = 'form',
}

export type BindField =
  | BindTypeField
  | BindTypeFieldArr
  | BindTypeStatic
  | BindTypeJs
  | BindTypeJson
  | BindTypeCustom
  | BindTypeCommand
  | BindTypeCommands
  | BindTypeForm

export type GanttFormCommands = GanttFormFilledCommands | GanttFormEmptyCommands

export type GanttFormFilledCommands = Array<{
  type: AutocompleteOption<ENTITY_COMMAND_TYPE>
  name: AutocompleteOption<string>
}>
export type GanttFormEmptyCommands = Array<{
  type: null
  name: null
}>

export type SectionType = {
  id: string | number
  title: BindField
  tooltip: BindField
  datetimeStart: BindTypeField
  datetimeEnd: BindTypeField
  duration?: BindTypeField
  link: {
    axisX: BindTypeField
    axisY: BindTypeField
    systemName: string
    resourceKeyField: string
    resourceLinkField: string
  }
  background: BindTypeStatic | BindTypeJs
  labels: {
    leftTopTitle: BindField
    topTitle: BindField
    rightTopTitle: BindField
    leftBottomTitle: BindField
    bottomTitle: BindField
    rightBottomTitle: BindField
  }
  lines: {
    top: {
      has: BindTypeStaticBoolean | BindTypeJs
    }
    bottom: {
      has: BindTypeStaticBoolean | BindTypeJs
    }
  }
  textColor: BindTypeStatic | BindTypeJs
}

export type TimelineItem = {
  data: AutocompleteOption<string> | null
  segmentType: SegmentType
  sections: SectionType[]
  key: BindTypeField
  additionalFields: ConfigField[]
  actions: GanttActionType[]
  hasCorners: boolean
  title: BindField
  tooltip: BindField
  background: BindTypeStatic | BindTypeJs
  filter: BindTypeStatic | BindTypeJson
  contextMenu?: ContextMenuAction[]
  commands: GanttFormCommands
  lines: {
    top: {
      has: BindTypeStaticBoolean | BindTypeJs
      color: BindTypeStatic | BindTypeJs
      width: BindTypeStatic | BindTypeJs
    }
    bottom: {
      has: BindTypeStaticBoolean | BindTypeJs
      color: BindTypeStatic | BindTypeJs
      width: BindTypeStatic | BindTypeJs
    }
  }
  borders: {
    inner: {
      has: BindTypeStaticBoolean | BindTypeJs
      color: BindTypeStatic | BindTypeJs
      width: BindTypeStatic | BindTypeJs
    }
    outer: {
      has: BindTypeStaticBoolean | BindTypeJs
      color: BindTypeStatic | BindTypeJs
      width: BindTypeStatic | BindTypeJs
    }
  }
  textColor: BindTypeStatic | BindTypeJs
  boxVerticalGuideLines: GuideLines
}

export type ResourceItem = {
  data: AutocompleteOption<string> | null
  key: BindTypeField
  columns: GanttColumnForm[]
  actions: GanttActionType[]
  filter: BindTypeStatic | BindTypeJson
  commands: GanttFormCommands
}

export type GanttColumnForm = {
  id: number | string
  code: string
  title: string
  field: BindTypeField
  objectValue?: string
  valueType: FIELD_VALUE_TYPE | null
  order?: string | number
  pinning: boolean
  useParameters: boolean
  pinnedColumn: PINNED_COLUMN
  visibility: boolean
  parameters: ColumnParametersType
  useLocalParameters?: boolean
}

export type GanttActionType = {
  id: number | string
  code: string
  title: string
  actionCode: ACTION_CODE
  eventCode: EVENT_CODE_GANTT
  target?: string
  actionField: BindField
}

export type ContextMenuAction = {
  name: string
  additionalData?: {
    order?: GridSortDirection
    statusCode?: string
  }
  command?: string
  commands?: EntityCommandDTO[]
  isHeader?: boolean
  isDivider?: boolean
}
export type GuideLines = {
  has: BindTypeStaticBoolean | BindTypeJs
  isBoxColor: BindTypeStaticBoolean | BindTypeJs
  color: BindTypeStatic | BindTypeJs
  width: BindTypeStatic | BindTypeJs
  type: BindTypeStatic | BindTypeJs
}

export type GanttFormValues = {
  id?: number | string
  viewId?: number | string
  code: string
  title: string
  canvas: GanttParametersFormType
  resource: ResourceItem
  timeline: TimelineItem[]
  sort: {
    axis: AXIS_TYPE
    code: string
    value: {
      field: string | null
      order: SORT_ORDER_TYPE | null
    }[]
  }
}

export type GanttFormValuesBack = Omit<GanttFormValues, 'resource' | 'timeline'> & {
  resource: Omit<ResourceItem, 'commands'> & {
    commands: EntityCommandDTO[]
  }
  timeline: (Omit<TimelineItem, 'commands'> & { commands: EntityCommandDTO[] })[]
}
