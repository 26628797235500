import { GridRenderCellParams } from '@microservices/wiskey-react-table-component'

import { ObjectDataRecord } from '@types'

import { getTimeFromSeconds } from '../getTimeFromSeconds'

export const renderIntegerAsTimeValueCell = (
  code: string,
  params: GridRenderCellParams<unknown, ObjectDataRecord, unknown>
) => {
  const value: number = params.row[code]

  if (!value && value !== 0) {
    return
  }

  return getTimeFromSeconds(value)
}
