import { useTranslation } from 'react-i18next'
import check from '@assets/images/check.svg'
import { FormInput } from '@microservices/wiskey-react-components'
import { Box, Grid } from '@mui/material'

import { useContextCreateOrEdit, useSearchAssistant } from '@pages/ContextCreateOrEdit/hooks'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { GENERATOR_INPUT_TYPE } from '@constants'

export const SearchAssistant = () => {
  const { t } = useTranslation()

  const { isLoadingSearchAssistants, autocompleteOptionsSearchAssistant, views, loadingViewList } =
    useSearchAssistant()
  const { data } = useContextCreateOrEdit()

  const { watchDefaultView } = data

  return (
    <Box>
      <FormInput
        autocompleteOptions={autocompleteOptionsSearchAssistant}
        inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
        label={t('contexts.title.searchAssistant')}
        loading={isLoadingSearchAssistants}
        name='searchAssistant'
        placeholder={t('contexts.placeholder.selectSearchAssistant')}
        labelSx={{
          minWidth: 140,
          mr: 1,
        }}
      />
      <FormInput
        autocompleteOptions={views}
        inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
        label={t('searchAssistant.defaultView')}
        labelSx={{ mr: 1, minWidth: 140 }}
        loading={loadingViewList}
        name='defaultView'
        placeholder={t('searchAssistant.defaultView')}
        size='small'
        renderAutocompleteOption={(props, option) =>
          option.id === watchDefaultView?.id ? (
            <li {...props} key={option.id} style={{ paddingLeft: '28px' }}>
              <Grid item alignSelf={'end'} left={3} position={'absolute'}>
                <CustomSvgIcon imgStyle={{}} src={check} />
              </Grid>
              {option.label}
            </li>
          ) : (
            <li {...props} key={option.id} style={{ paddingLeft: '28px' }}>
              {option.label}
            </li>
          )
        }
      />
    </Box>
  )
}
