type PlanDutyRequestParams = {
  planDay: string
  planQual: string
}

export const getPlanDutyRequestParams = ({ planDay, planQual }: PlanDutyRequestParams) => {
  return {
    objectCode: 'roster-staff-plan',
    filter: [`(day==${planDay})`, planQual ? `(qual_ref.qual_id=="${planQual}")` : undefined]
      .filter(Boolean)
      .join(';'),
  }
}
