import { URLS } from '@constants'
import { ParameterPropertyType, POSTLocalParameterPropertyType, POSTParameters } from '@types'

import { commonAPI } from './common.api'

export const parametersAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchParameterProperties: build.query<ParameterPropertyType[], { code: string; sort?: string }>(
      {
        query: ({ code, sort = 'num,asc' }) => ({
          url: `/api/tool/front/param/code/${code}/properties`,
          params: {
            sort,
          },
        }),
        providesTags: ['Parameter'],
      }
    ),
    updateParameterProperty: build.mutation<ParameterPropertyType[], POSTParameters>({
      query: ({ parameters, paramCode }) => ({
        url: `/api/tool/front/param/code/${paramCode}/property`,
        method: 'POST',
        body: parameters,
      }),
      invalidatesTags: ['Parameter'],
    }),
    updateLocalParameterProperty: build.mutation<
      ParameterPropertyType[],
      POSTLocalParameterPropertyType[]
    >({
      query: parameters => ({
        url: URLS.FRONT_CFG + `/prm/property`,
        method: 'POST',
        body: parameters,
      }),
    }),
    updateLocalParameterPropertyWithViewReset: build.mutation<
      ParameterPropertyType[],
      POSTLocalParameterPropertyType[]
    >({
      query: parameters => ({
        url: URLS.FRONT_CFG + `/prm/property`,
        method: 'POST',
        body: parameters,
      }),
      invalidatesTags: ['SingleView'],
    }),
    deleteLocalParameter: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: URLS.FRONT_CFG + `/prm/parameters/id/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteLocalParameterWithViewReset: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: URLS.FRONT_CFG + `/prm/parameters/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SingleView'],
    }),
  }),
})

export const {
  useFetchParameterPropertiesQuery,
  useUpdateParameterPropertyMutation,
  useUpdateLocalParameterPropertyMutation,
  useUpdateLocalParameterPropertyWithViewResetMutation,
  useDeleteLocalParameterMutation,
  useDeleteLocalParameterWithViewResetMutation,
} = parametersAPI
