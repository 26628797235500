import { GridPinnedColumnFields } from '@microservices/wiskey-react-table-component'

export const getFilteredPinnedColumnsArray = (pinnedColumns: GridPinnedColumnFields): string[] => {
  let pinnedColumnsArray: string[] = []

  if (pinnedColumns.left) {
    pinnedColumnsArray = pinnedColumnsArray.concat(pinnedColumns.left)
  }

  if (pinnedColumns.right) {
    pinnedColumnsArray = pinnedColumnsArray.concat(pinnedColumns.right)
  }

  return pinnedColumnsArray.filter(col => col !== 'linkToForm' && col !== 'actions')
}
