import { FC } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormGenerator, ModalWrapper } from '@microservices/wiskey-react-components'
import { Box } from '@mui/material'

import { ScriptValueDialog } from '@components/ScriptValueDialog'
import { ColorSettings } from '@components/TextSettings/components/ColorSettings'

import { checkValidJS } from '@helpers'
import { MODAL_TYPE } from '@constants'
import { AutocompleteOption } from '@types'

import { useAddPointDialog } from './hooks'

export type AddTooltipPointForm = {
  title: string
  bindType: AutocompleteOption | null
  value: AutocompleteOption | string | null
  background: string | undefined
  conditionsHidden: string
}

export const AddTooltipPointDialog: FC = () => {
  const { t } = useTranslation()

  const {
    data: { methods, variables },
    state: { currentNameScriptDialog, isShowScriptValueDialogTitle, loadingPoint, watchBackground },
    handlers: { handleSave, handleClose, handleSaveScriptDialog, handleCloseScriptDialog },
    inputs: { titleInput, bindingInput, valueInput, conditionInput },
  } = useAddPointDialog()

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = methods

  return (
    <ModalWrapper
      hideDivider
      containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      disabledSave={!isDirty}
      isShow={true}
      title={t(`tooltips.tooltipPointModal.titleModal`)}
      width={700}
      onClose={handleClose}
      onSave={handleSubmit(handleSave)}
    >
      {isShowScriptValueDialogTitle && (
        <ScriptValueDialog
          windowBoundaries
          isShow={isShowScriptValueDialogTitle}
          modalType={MODAL_TYPE.EDIT}
          objectFields={[]}
          title={t(`tooltips.title.editJs`)}
          value={currentNameScriptDialog ? watch(currentNameScriptDialog) : ''}
          validator={value =>
            checkValidJS(
              value,
              { srcObj: {}, global: {}, local: {} },
              { local: variables?.map(({ field }) => field) || [], global: [] }
            )
          }
          onClose={handleCloseScriptDialog}
          onSave={handleSaveScriptDialog}
        />
      )}
      <Box pb={2} pt={2} px={2}>
        <FormProvider {...methods}>
          <FormGenerator inputs={titleInput} loading={loadingPoint} />
          <FormGenerator inputs={bindingInput} loading={loadingPoint} />
          <FormGenerator
            inputs={[...valueInput, ...conditionInput]}
            loading={loadingPoint}
            sx={{
              '& .MuiGrid-item': {
                '&:nth-last-child(-n+1)': {
                  pt: 0,
                },
                flexWrap: 'nowrap',
              },
            }}
          />
          <ColorSettings
            clearableColor
            color={watchBackground}
            isEdit={true}
            label={t('parameters.visual.background')}
            labelMaxWidth={150}
            labelPlacement='left'
            onChangeColor={value => setValue('background', value, { shouldDirty: true })}
          />
        </FormProvider>
      </Box>
    </ModalWrapper>
  )
}
