import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { Box, Skeleton, Typography } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { useAppSelector } from '@hooks'
import { DRAWER_WIDTH } from '@constants'

const getTime = () => moment().format('DD.MM.YYYY HH:mm')

interface IProps {
  hasSidebar: boolean
  isAdmin: boolean
}

function dispatchEvent(type: string) {
  window.dispatchEvent(new CustomEvent<any>(type))
}

export const AppFooter = (props: IProps) => {
  const { title, leftEvents } = useAppSelector(state => state.appFooter)

  const [now, setNow] = useState<string>()
  const [isLoading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    !isLoading && setInterval(() => setNow(getTime()), 60_000)
  }, [isLoading])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      setNow(getTime())
    }, 500) // для установки кастомной таймзоны в роуте
  }, [])

  return (
    <Box
      alignItems='center'
      bgcolor={theme => theme.palette.background.footer}
      display='flex'
      gap={2}
      height={32}
      justifyContent='center'
    >
      <Box width={props.hasSidebar ? DRAWER_WIDTH : 0} />
      {title && (
        <Box key='app-footer-title' display='flex' justifyContent='start'>
          <Typography variant='subtitle1'>{title}</Typography>
        </Box>
      )}
      {!isEmpty(leftEvents) && (
        <Box key='app-footer-left-events' display='flex' gap={1}>
          {leftEvents.map(event => (
            <Box
              key={event?.label || event.title}
              alignItems='center'
              display='flex'
              sx={{ cursor: 'pointer' }}
              onClick={() => dispatchEvent(event.type)}
            >
              {event.src && (
                <CustomSvgIcon
                  key={event.type + event.src + event.title + event.label}
                  src={event.src}
                  sx={{ width: '28px', height: '28px' }}
                  title={event?.title}
                />
              )}
              {event.icon && !event.src && (
                <span
                  key={event.type + event.title + event.label}
                  className='material-icons md-28'
                  title={event.title}
                >
                  {event.icon}
                </span>
              )}
              {event?.label && <Typography>{event?.label}</Typography>}
            </Box>
          ))}
        </Box>
      )}
      <Box flex={1} />
      <Box key='app-footer-datetime' display='flex' justifyContent='end' paddingRight={2}>
        {isLoading && <Skeleton height={20} variant='rounded' width={150} />}
        {!isLoading && <span title={moment().format('Z')}>{now}</span>}
      </Box>
    </Box>
  )
}
