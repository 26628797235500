import i18next from 'i18next'
import { isBoolean } from 'lodash'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import some from 'lodash/some'

import { ErrorValidJSType } from '@helpers'

import { GANTT_SCRIPT_RETURN_TYPE } from '@gantt/types'

export const checkReturnedValueType = (
  returnedValue: Record<string, any>[],
  type?: GANTT_SCRIPT_RETURN_TYPE | ((value: any) => boolean)[]
): ErrorValidJSType | undefined => {
  if (!type) {
    return {
      error: false,
    }
  }

  switch (type) {
    case GANTT_SCRIPT_RETURN_TYPE.STRING:
      if (!isString(returnedValue)) {
        return {
          error: true,
          message: i18next.t(`error.functionReturnValueType.${type}`),
        }
      }
      break

    case GANTT_SCRIPT_RETURN_TYPE.ARRAY_OF_OBJECTS:
      if (!isArray(returnedValue) || some(returnedValue, value => !isObject(value))) {
        return {
          error: true,
          message: i18next.t(`error.functionReturnValueType.${type}`),
        }
      }
      break

    case GANTT_SCRIPT_RETURN_TYPE.BOOLEAN:
      if (!isBoolean(returnedValue))
        return {
          error: true,
          message: i18next.t(`error.functionReturnValueType.${type}`),
        }
      break

    default:
      if (!type?.some(fun => fun?.(returnedValue))) {
        return {
          error: true,
          message: i18next.t(`error.notValidReturnedType`),
        }
      }
  }
}
