import {
  contextMenuDefaultJson,
  contextMenuDefaultWithCommandJson,
  tooltipDefaultGroupJson,
  tooltipDefaultTextJson,
  tooltipExampleJson,
} from '@gantt/constants'

export default {
  'Context header menu for Y with Sort': JSON.stringify(contextMenuDefaultJson, null, '\t'),
  'Context menu with Command': JSON.stringify(contextMenuDefaultWithCommandJson, null, '\t'),
  'Simple tooltip': JSON.stringify(tooltipDefaultTextJson, null, '\t'),
  'Group tooltip': JSON.stringify(tooltipDefaultGroupJson, null, '\t'),
  'Tooltip example in JSON': JSON.stringify(tooltipExampleJson, null, '\t'),
}
