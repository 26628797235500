import { URLS } from '@constants'
import { DisplayRule, GETObjectDataParams, ShortDisplayRule } from '@types'

import { GETDisplayRuleParams, POSTDisplayRuleParams } from '../../types/api/displayRule.api'

import { commonAPI } from './common.api'

export const displayRulesApi = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllDisplayRules: build.query<ShortDisplayRule[], GETObjectDataParams>({
      query: params => ({
        url: URLS.FRONT_CFG + '/displayRule',
        params: {
          searchPattern: params?.searchPattern,
          sort: params?.sort,
          objectCode: params?.objectCode,
          objectField: params?.objectField,
        },
      }),
      providesTags: ['AllDisplayRules'],
    }),
    fetchDisplayRuleById: build.query<DisplayRule, GETDisplayRuleParams>({
      query: ({ id }) => ({
        url: URLS.FRONT_CFG + `/displayRule/id/${id}`,
        method: 'GET',
      }),
      providesTags: ['SingleDisplayRule'],
    }),
    createOrUpdateDisplayRule: build.mutation<DisplayRule, POSTDisplayRuleParams>({
      query: displayRule => ({
        url: URLS.FRONT_CFG + `/displayRule`,
        method: 'POST',
        body: displayRule,
      }),
      invalidatesTags: ['AllDisplayRules', 'DisplayRule', 'SingleDisplayRule'],
    }),
    deleteDisplayRule: build.mutation<void, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/displayRule/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllDisplayRules', 'DisplayRule'],
    }),
  }),
})

export const {
  useFetchAllDisplayRulesQuery,
  useFetchDisplayRuleByIdQuery,
  useCreateOrUpdateDisplayRuleMutation,
  useDeleteDisplayRuleMutation,
} = displayRulesApi
