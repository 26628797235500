import { MouseEventHandler, useMemo, useState } from 'react'
import { Position } from 'react-rnd'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'
import { NestedMenuItem } from 'mui-nested-menu'
import { NavOptionType } from 'src/types/menu'
import { v4 as uuid } from 'uuid'
import nestedArrow from '@assets/images/nested-arrow.svg'
import { Box, ListItemIcon, Menu, SxProps, Theme } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'
import { ICONS } from '@components/IconPicker/constants'
import { useAlignmentsNavList } from '@components/UserNavList/hooks'

import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'
import {
  openFileUploadDialog,
  openFormDialog,
  openSearchAssistantDialog,
  openViewDialog,
} from '@redux/reducers/dialogWindowManager.reducer'
import { addFormHistory } from '@redux/reducers/formHistory.reducer'
import { showMessage } from '@redux/reducers/snackbar.reducer'

import { useAppDispatch, useAppSelector } from '@hooks'
import {
  getInitialPositionWindow,
  getOptionRoute,
  transformCommonParametersToValues,
} from '@helpers'
import { COMMON_PARAMETERS, ELEMENT_TYPE, ENTITY, FORM_TYPE, MENU_TYPE, ROUTES } from '@constants'

import { DIALOG_WINDOW_TYPE, DialogWindowType, ViewDialogWindowMeta } from '../../../src/types'

import { ListItem, ListItemButton } from './components/ListItem'
import { containsOption } from './helpers'

const menuSx: SxProps<Theme> = {
  '& .MuiList-root': {
    py: 0,
    top: -1,
  },
  '& .MuiPaper-root': {
    borderRadius: 0,
    border: theme => theme.palette.border.field,
    boxShadow: theme => `2px 2px 6px 0px ${theme.palette.common.black}`,
    minWidth: 280,
    py: '4px',
  },
}

const nestedItemSx: SxProps<Theme> = {
  '& .MuiTypography-root': {
    p: '0px 12px',
    fontFamily: 'Montserrat Regular',
  },
  p: 0,
  pr: 3,
  '&:hover': {
    bgcolor: theme => theme.palette.background.hovered,
  },
}

export const UserNavList = ({
  option,
  itemsLine,
  parentMenuOpen,
  isChild,
  onCloseParentMenu,
}: {
  option: NavOptionType
  itemsLine?: NavOptionType[] | undefined
  onCloseParentMenu?: () => void
  parentMenuOpen?: boolean
  isChild?: boolean
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])
  const hasOptions = Boolean(option.options && option.options?.length)
  const isChildWithOptions = Boolean(isChild && hasOptions)
  const { selectedId, dialogWindows } = useAppSelector(state => state.dialogWindowManager)
  const selectedOption = dialogWindows.find(
    ({ id, type }) => id === selectedId && type === DIALOG_WINDOW_TYPE.VIEW
  ) as DialogWindowType<ViewDialogWindowMeta> | undefined
  const isSelected = isMenuOpen || (selectedOption && containsOption(option, selectedOption))

  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })

  const { order, margin } = useAlignmentsNavList(option, itemsLine)

  const initialCommonParameters = useMemo(
    () => transformCommonParametersToValues(commonParameters),
    [commonParameters]
  )

  const handleCloseMenu = () => setAnchorEl(null)

  const checkMaxOpenedDialogs = () => {
    const openedFormsCount = dialogWindows.filter(
      dialog =>
        [DIALOG_WINDOW_TYPE.FORM, DIALOG_WINDOW_TYPE.VIEW, DIALOG_WINDOW_TYPE.FILE_UPLOAD].includes(
          dialog.type
        ) && !dialog.hidden
    ).length
    const maxOpenedFormsCount = Number(initialCommonParameters.numberOfWindows)

    if (openedFormsCount === maxOpenedFormsCount) {
      dispatch(
        showMessage({
          type: 'info',
          text: t('error.dynamicFormsExceeded', { count: maxOpenedFormsCount }),
        })
      )

      onCloseParentMenu?.()

      return true
    }

    return false
  }

  const openSearchAssistant = (position?: Position | null) => {
    if (checkMaxOpenedDialogs()) {
      return
    }

    dispatch(
      openSearchAssistantDialog({
        id: uuid(),
        parentDialogId: null,
        type: DIALOG_WINDOW_TYPE.SEARCH_ASSISTANT,
        title: t('searchAssistant.title'),
        initialPosition: position,
        meta: {},
      })
    )
  }

  const openView = (position?: Position | null) => {
    if (checkMaxOpenedDialogs()) {
      return
    }

    if (option.elementId && option.elementCode && option.elementTitle) {
      dispatch(
        openViewDialog({
          id: uuid(),
          parentDialogId: null,
          type: DIALOG_WINDOW_TYPE.VIEW,
          title: option.elementTitle,
          meta: {
            viewId: option.elementId,
            viewCode: option.elementCode,
            title: option.elementTitle,
            path: option.id.toString(),
            menuId: option.id,
            ...(option.parentId && { parentId: option.parentId }),
          },
          initialPosition: position,
        })
      )
    }
  }

  const openForm = (position?: Position | null) => {
    if (checkMaxOpenedDialogs()) return

    if (option.elementId && option.elementCode) {
      const id = uuid()
      dispatch(
        openFormDialog({
          id,
          parentDialogId: null,
          type: DIALOG_WINDOW_TYPE.FORM,
          title: option?.elementTitle,
          meta: {
            objectCode: '',
            formCode: option.elementCode.toString(),
            viewCode: '',
            path: option.id.toString(),
            event: FORM_TYPE.CREATE,
            globalId: id,
            entityType: ENTITY.VIEW,
            viewId: option.elementId,
            isIndependentForm: true,
          },
          initialPosition: position,
        })
      )

      dispatch(
        addFormHistory({
          id,
          form: {
            formCode: option.elementCode.toString(),
            objectCode: '',
            event: FORM_TYPE.CREATE,
            objectId: null,
            title: option?.elementTitle || '',
            id,
            isWindow: true,
          },
        })
      )
    }
  }

  const handleOpenFileUploadDialog = (position?: Position | null) => {
    if (checkMaxOpenedDialogs()) return
    const id = uuid()
    dispatch(
      openFileUploadDialog({
        id,
        parentDialogId: null,
        type: DIALOG_WINDOW_TYPE.FILE_UPLOAD,
        title: option?.title || '',
        meta: {},
        initialPosition: position,
      })
    )
  }

  const handleClickItem: MouseEventHandler<HTMLDivElement> = event => {
    if (option.options?.length) {
      if (anchorEl) return

      setAnchorEl(event.currentTarget)

      return
    }

    const initialPosition = getInitialPositionWindow(event)

    switch (option.elementType) {
      case ELEMENT_TYPE.SEARCH_ASSISTANT:
        openSearchAssistant(initialPosition)
        onCloseParentMenu?.()

        return
      case ELEMENT_TYPE.VIEW:
        if (option.background) {
          navigate(getOptionRoute(option))
        } else {
          openView(initialPosition)
        }

        onCloseParentMenu?.()

        return
      case ELEMENT_TYPE.GANTT_CHART:
        navigate(getOptionRoute(option))
        onCloseParentMenu?.()

        return

      case ELEMENT_TYPE.FORM:
        openForm(initialPosition)
        onCloseParentMenu?.()

        return

      case ELEMENT_TYPE.FILE_UPLOAD:
        handleOpenFileUploadDialog(initialPosition)
        onCloseParentMenu?.()

        return

      case ELEMENT_TYPE.BAR_CHART:
        navigate(ROUTES.BAR_CHART)
        onCloseParentMenu?.()

        return
      default:
        return
    }
  }

  return (
    <Box
      sx={{
        whiteSpace: 'nowrap',
        p: 0,
        textDecoration: 'none',
        color: theme => theme.palette.common.black,
        ...(!isChild && { maxWidth: 170 }),
        marginLeft: margin?.includes('ml') ? 'auto' : undefined,
        marginRight: margin?.includes('mr') ? 'auto' : undefined,
        order,
      }}
    >
      {isChildWithOptions ? (
        <NestedMenuItem
          component={ListItemButton}
          label={option.title}
          parentMenuOpen={Boolean(parentMenuOpen)}
          selected={isSelected}
          sx={nestedItemSx}
          MenuProps={{
            anchorReference: 'anchorEl',

            sx: menuSx,
          }}
          leftIcon={
            option.iconName && option.menuType === MENU_TYPE.ICON ? (
              <CustomSvgIcon
                imgStyle={{ maxHeight: 26, maxWidth: 38 }}
                src={ICONS[option.iconName].src}
                sx={{ ml: '11px', display: 'flex', height: 26 }}
              />
            ) : (
              <ListItemIcon sx={{ width: 20, height: 20, minWidth: 0 }} />
            )
          }
          rightIcon={
            <CustomSvgIcon
              fontSize='small'
              src={nestedArrow}
              sx={{ alignSelf: 'baseline', height: '1.2em', '& > img': { width: 15, height: 15 } }}
            />
          }
        >
          {option.options?.map(child => (
            <UserNavList
              key={child.id}
              isChild
              option={child}
              parentMenuOpen={Boolean(parentMenuOpen)}
              onCloseParentMenu={onCloseParentMenu}
            />
          ))}
        </NestedMenuItem>
      ) : (
        <ListItem
          key={option.id}
          disabled={option.elementType === ELEMENT_TYPE.COMMAND}
          icon={option.iconName ? ICONS[option.iconName].src : undefined}
          isChild={isChild}
          option={option}
          selected={isSelected}
          onClick={handleClickItem}
        />
      )}
      {hasOptions ? (
        <Menu
          anchorEl={anchorEl}
          anchorReference='anchorEl'
          open={isMenuOpen}
          sx={menuSx}
          onClose={handleCloseMenu}
        >
          {option.options?.map(child => (
            <UserNavList
              key={child.id}
              isChild
              option={child}
              parentMenuOpen={isMenuOpen}
              onCloseParentMenu={handleCloseMenu}
            />
          ))}
        </Menu>
      ) : null}
    </Box>
  )
}
