import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'

import { transformResponseWithPagination } from '@helpers'
import { URLS } from '@constants'
import {
  GETAllObject,
  GETEnumForObject,
  GETObjectDataRecordParams,
  GETObjectEnumParams,
  GETObjectFieldChanges,
  GETObjectFieldChangesParams,
  GETObjectModel,
  ObjectDataRecord,
  ObjectDataValue,
  ObjectFieldChangesDTO,
} from '@types'

import { commonAPI } from './common.api'

export const objectAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllObject: build.query<GETAllObject, void>({
      query: () => ({
        url: URLS.FRONT_API + `/models/list`,
      }),
    }),
    fetchObjectByCode: build.query<GETObjectModel, string | void>({
      query: code => ({
        url: URLS.FRONT_API + `/models/code/${code}`,
      }),
    }),
    fetchEnumForObject: build.query<GETEnumForObject[], GETObjectEnumParams>({
      query: ({ code, name }) => ({
        url: URLS.FRONT_API + `/models/code/${code}/field/code/${name}/enums`,
      }),
    }),
    // TODO Устарело, проверить
    fetchObjectData: build.mutation<ObjectDataRecord, GETObjectDataRecordParams>({
      query: ({ objectCode, objectId, expands }) => ({
        url: URLS.FRONT_API + `/data/${objectCode}/id/${objectId}`,
        params: { expands },
      }),
    }),
    fetchObjectFieldChanges: build.mutation<GETObjectFieldChanges, GETObjectFieldChangesParams>({
      query: ({ objectCode, objectId, page, size, sort, filter }) => ({
        url: `/api/audit/object/${objectCode}/id/${objectId}`,
        params: {
          page,
          size,
          sort,
          filter,
        },
      }),
      // providesTags: ['AuditData'],
      transformResponse: (output: ObjectFieldChangesDTO[], meta: FetchBaseQueryMeta) =>
        transformResponseWithPagination<ObjectFieldChangesDTO[]>(output, meta),
    }),
    deleteObject: build.mutation<ObjectDataRecord, ObjectDataValue>({
      query: ({ objectId, viewId }) => ({
        url: URLS.FRONT_API + `/data/view/${viewId}/id/${objectId}`,
        method: 'DELETE',
      }),
    }),
    // fetchListControlOrDropdownObjectDataEnriched: build.mutation<
    //   GETObjectData,
    //   GETObjectDataParamsEnriched
    // >({
    //   query: ({
    //     page,
    //     size,
    //     sort,
    //     formObjectCode,
    //     elementId,
    //     valueId,
    //     fieldId,
    //     dropDownEntityType,
    //     formObjectId,
    //     viewId,
    //     body,
    //     filter,
    //     searchValue,
    //     searchRule,
    //     sortKeysForQuickSearch,
    //   }) => ({
    //     url: `router/storage/object/list/form/dropDown?${getEnrichedSortParam(sort)}`,
    //     method: 'POST',
    //     params: {
    //       viewId,
    //       dropDownEntityType,
    //       page,
    //       size,
    //       formObjectCode,
    //       formObjectId,
    //       elementId,
    //       valueId,
    //       fieldId,
    //       filter,
    //       searchValue,
    //       searchRule,
    //       sortKeysForQuickSearch,
    //     },
    //     body,
    //   }),
    //   extraOptions: {
    //     showNotification: false,
    //   },
    //   transformResponse: (output: ObjectDataRecord[], meta: FetchBaseQueryMeta) =>
    //     transformResponseWithPagination<ObjectDataRecord[]>(output, meta),
    //   // providesTags: ['ObjectData'],
    // }),
    fetchObjectByCodeNoCache: build.mutation<GETObjectModel, string>({
      query: code => ({
        url: URLS.FRONT_API + `/models/code/${code}`,
      }),
    }),
    fetchStorage: build.mutation<any, { objectCode: string } & Record<string, any>>({
      query: ({ objectCode, select, groupBy, filter, ...params }) => ({
        url: `/api/storage/objects/${objectCode}`,
        params: { select, groupBy, filter, ...params },
        method: 'GET',
      }),
    }),
    fetchStorageAggregate: build.mutation<
      any,
      { objectCode: string; select: string; filter: string; groupBy?: string[] } & Record<
        string,
        any
      >
    >({
      query: ({ objectCode, select, groupBy, filter, ...params }) => ({
        url: `/api/storage/objects/${objectCode}/aggregate`,
        params: { select, groupBy, filter, ...params },
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useFetchAllObjectQuery,
  useFetchObjectByCodeQuery,
  useFetchEnumForObjectQuery,
  useFetchObjectFieldChangesMutation,
  useFetchObjectDataMutation,
  useFetchObjectByCodeNoCacheMutation,
  useDeleteObjectMutation,
  useFetchStorageMutation,
  useFetchStorageAggregateMutation,
} = objectAPI
