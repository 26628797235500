import { URLS } from '@constants'
import {
  GETAllTooltipLines,
  GETAllTooltips,
  GETObjectDataParams,
  GETTooltip,
  GETTooltipElement,
  GETTooltipLine,
  GETTooltipPoint,
  POSTCopyTooltip,
  POSTTooltip,
  POSTTooltipElement,
  POSTTooltipJSON,
  POSTTooltipLine,
  POSTTooltipPoint,
} from '@types'

import { commonAPI } from './common.api'

export const tooltipAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllTooltips: build.query<GETAllTooltips, GETObjectDataParams | void>({
      query: params => ({
        url: URLS.FRONT_CFG + '/tooltip',
        params: {
          searchPattern: params?.searchPattern,
          objectCode: params?.objectCode,
        },
      }),
      providesTags: ['AllTooltips'],
    }),
    fetchTooltipById: build.query<GETTooltip, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/tooltip/id/${id}`,
      }),
      providesTags: ['SingleTooltip'],
    }),
    createTooltip: build.mutation<GETTooltip, POSTTooltip>({
      query: tooltip => ({
        url: URLS.FRONT_CFG + '/tooltip',
        method: 'POST',
        body: tooltip,
      }),
      invalidatesTags: ['AllTooltips'],
    }),
    updateTooltip: build.mutation<GETTooltip, POSTTooltip>({
      query: tooltip => ({
        url: URLS.FRONT_CFG + '/tooltip',
        method: 'POST',
        body: tooltip,
      }),
      invalidatesTags: ['AllTooltips', 'SingleTooltip'],
    }),
    copyTooltip: build.mutation<GETTooltip, POSTCopyTooltip>({
      query: tooltip => ({
        url: URLS.FRONT_CFG + '/tooltip/copy',
        method: 'POST',
        body: tooltip,
      }),
      invalidatesTags: ['AllTooltips'],
    }),
    deleteTooltip: build.mutation<GETTooltip, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/tooltip/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllTooltips'],
    }),
    updateTooltipJSON: build.mutation<GETTooltip, POSTTooltipJSON>({
      query: tooltipJSON => ({
        url: URLS.FRONT_CFG + '/tooltip/json',
        method: 'POST',
        body: tooltipJSON,
      }),
      invalidatesTags: ['SingleTooltip'],
    }),
    updateTooltipPoint: build.mutation<GETTooltipPoint, POSTTooltipPoint>({
      query: tooltipPoint => ({
        url: URLS.FRONT_CFG + '/tooltip/point',
        method: 'POST',
        body: tooltipPoint,
      }),
      invalidatesTags: ['SingleTooltip'],
    }),
    fetchTooltipPointById: build.query<GETTooltipPoint, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/tooltip/point/id/${id}`,
      }),
    }),
    deleteTooltipPoint: build.mutation<void, number | string>({
      query: id => ({
        url: URLS.FRONT_CFG + `/tooltip/point/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SingleTooltip'],
    }),
    fetchUndefinedPointsById: build.query<
      GETTooltipPoint[],
      { tooltipId: number; filter?: string }
    >({
      query: ({ tooltipId, filter }) => ({
        url: URLS.FRONT_CFG + `/tooltip/id/${tooltipId}/point/undefined`,
        params: { filter },
      }),
      providesTags: ['UndefinedPoints'],
    }),
    fetchPointsLinesById: build.query<GETAllTooltipLines, number>({
      query: tooltipId => ({
        url: URLS.FRONT_CFG + `/tooltip/id/${tooltipId}/lines`,
      }),
      providesTags: ['UndefinedPoints', 'PointLines'],
    }),
    updatePointLineById: build.mutation<GETTooltipLine, POSTTooltipLine>({
      query: pointLine => ({
        url: URLS.FRONT_CFG + '/tooltip/line',
        method: 'POST',
        body: pointLine,
      }),
      invalidatesTags: ['UndefinedPoints', 'PointLines'],
    }),
    updatePointLineElementById: build.mutation<GETTooltipElement, POSTTooltipElement>({
      query: pointLineElement => ({
        url: URLS.FRONT_CFG + '/tooltip/line/element',
        method: 'POST',
        body: pointLineElement,
      }),
      invalidatesTags: ['UndefinedPoints'],
    }),
    deletePoint: build.mutation<void, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/tooltip/line/element/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PointLines', 'UndefinedPoints'],
    }),
  }),
})

export const {
  useFetchAllTooltipsQuery,
  useFetchTooltipByIdQuery,
  useCreateTooltipMutation,
  useUpdateTooltipMutation,
  useCopyTooltipMutation,
  useDeleteTooltipMutation,
  useUpdateTooltipJSONMutation,
  useUpdateTooltipPointMutation,
  useFetchTooltipPointByIdQuery,
  useDeleteTooltipPointMutation,
  useFetchUndefinedPointsByIdQuery,
  useFetchPointsLinesByIdQuery,
  useUpdatePointLineByIdMutation,
  useUpdatePointLineElementByIdMutation,
  useDeletePointMutation,
} = tooltipAPI
