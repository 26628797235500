import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { matchPath, useLocation } from 'react-router-dom'

import { useHandlers } from '@pages/ConfigSearchAssistants/hooks/useHandlers'

import { useFetchAllSearchAssistantsQuery } from '@redux/api/searchAssistant.api'

import { useDebounce } from '@hooks'
import { ROUTES, SEARCH_PATTERN_DELAY } from '@constants'

import { SearchAssistant } from '../../../types/searchAssistant'

export const useConfigSearchAssistants = () => {
  const [allSearchAssistants, setAllSearchAssistants] = useState<SearchAssistant[]>([])

  const { data, handlers, state } = useHandlers()

  const { pathname } = useLocation()

  const isAllSearchAssistants = matchPath(ROUTES.CONFIG_SEARCH_ASSISTANTS, pathname)

  const methods = useForm<{ searchPattern: string }>({ defaultValues: { searchPattern: '' } })
  const { watch } = methods

  const watchSearchPattern = watch('searchPattern')
  const debouncedSearchPattern = useDebounce(watchSearchPattern, SEARCH_PATTERN_DELAY)

  const { data: searchAssistantsData, isFetching: isLoadingSearchAssistantsData } =
    useFetchAllSearchAssistantsQuery(
      {
        searchPattern: debouncedSearchPattern ? debouncedSearchPattern : undefined,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    )

  useEffect(() => {
    if (searchAssistantsData) {
      setAllSearchAssistants(searchAssistantsData)
    }
  }, [searchAssistantsData])

  return {
    state: {
      ...state,
      allSearchAssistants,
    },
    handlers: {
      ...handlers,
    },
    data: {
      ...data,
      isAllSearchAssistants,
      debouncedSearchPattern,
      methods,
      isLoadingSearchAssistantsData,
    },
  }
}
