import { createContext, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import { AllSearchAssistants } from '@pages/ConfigSearchAssistants/components/AllSearchAssistants'
import { CopyDialog } from '@pages/ConfigSearchAssistants/components/CopyDialog/CopyDialog'
import { useConfigSearchAssistants } from '@pages/ConfigSearchAssistants/hooks/useConfigSearchAssistants'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { PageTitle } from '@components/PageTitle'

import { VISIBLE_HIDDEN } from '@constants'

import { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const ConfigSearchAssistants: FC = () => {
  const { t } = useTranslation()

  const {
    state: { allSearchAssistants },
    data: {
      isAllSearchAssistants,
      methods,
      isLoadingSearchAssistantsData,
      loadingCopySearchAssistant,
      copySearchAssistantData,
      showCopyDialog,
    },
    handlers: {
      handleCreateSearchAssistant,
      handleEditSearchAssistant,
      handleDeleteSearchAssistant,
      handleCopySearchAssistant,
      handleCloseDialog,
      handleShowDialog,
    },
  } = useConfigSearchAssistants()

  const contextValue = {
    allSearchAssistants,
    isLoading: isLoadingSearchAssistantsData,
    onCreateSearchAssistant: handleCreateSearchAssistant,
    onEditSearchAssistant: handleEditSearchAssistant,
    onDeleteSearchAssistant: handleDeleteSearchAssistant,
    onCopySearchAssistant: handleCopySearchAssistant,
    onShowCopyDialog: handleShowDialog,
  }

  return (
    <PageContext.Provider value={contextValue}>
      {showCopyDialog && copySearchAssistantData && (
        <CopyDialog
          copyData={copySearchAssistantData}
          dialogTitle={t(`searchAssistants.title.copyDialog`)}
          isShow={showCopyDialog}
          loading={loadingCopySearchAssistant}
          models={allSearchAssistants.map(sa => sa.id)}
          onClose={handleCloseDialog}
          onCopy={handleCopySearchAssistant}
        />
      )}
      <Outlet />
      <Box sx={{ ...(!isAllSearchAssistants && VISIBLE_HIDDEN) }}>
        <PageTitle
          end={false}
          start={
            <>
              <Typography display='inline' sx={{ mr: 1, ml: 1 }} variant='h6'>
                {t('pageTitle.searchAssistants')}
              </Typography>
            </>
          }
        />
        <PageContentLayout>
          <AllSearchAssistants methods={methods} />
        </PageContentLayout>
      </Box>
    </PageContext.Provider>
  )
}
