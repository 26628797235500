import { EmptyObject } from '@reduxjs/toolkit'

import { commonAPI } from '@redux/api/common.api'

import { URLS } from '@constants'

import { GanttFormValues, GanttFormValuesBack } from '@gantt/components/GanttCreateOrEdit'
import { GanttConfig } from '@gantt/types'

export const modelGanttAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    createGantt: build.mutation<number, GanttFormValues>({
      query: gantt => ({
        url: URLS.FRONT_CFG + '/gantChart',
        method: 'POST',
        body: gantt,
      }),
      invalidatesTags: ['AllGantt', 'AllViews', 'SingleGantt'],
    }),
    fetchAllGantt: build.query<GanttConfig[], EmptyObject>({
      query: () => ({
        url: URLS.FRONT_CFG + '/gantChart/all',
      }),
      providesTags: ['AllGantt'],
    }),
    fetchGanttById: build.query<GanttFormValuesBack, string | undefined>({
      query: id => ({
        url: URLS.FRONT_CFG + `/gantChart/config/${id}`,
      }),
      providesTags: ['SingleGantt'],
    }),
    // TODO: WF-RN-7
    // Убрать получение дефолтного ганта после реализации получения гантов в контексте (нужно для роутинга)
    fetchDefaultGanttById: build.mutation<GanttFormValuesBack, string | undefined>({
      query: id => ({
        url: URLS.FRONT_CFG + `/gantChart/config/${id}`,
      }),
    }),
    updateGantt: build.mutation<GanttFormValues, GanttFormValues>({
      query: gantt => ({
        url: URLS.FRONT_CFG + '/gantChart',
        method: 'POST',
        body: gantt,
      }),
      invalidatesTags: ['AllGantt', 'SingleGantt'],
    }),
    deleteGanttById: build.mutation<void, string | number>({
      query: code => ({
        url: URLS.FRONT_CFG + `/gantChart/${code}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllGantt'],
    }),
  }),
})

export const {
  useCreateGanttMutation,
  useFetchAllGanttQuery,
  useFetchGanttByIdQuery,
  useFetchDefaultGanttByIdMutation,
  useUpdateGanttMutation,
  useDeleteGanttByIdMutation,
} = modelGanttAPI
