import { useContext, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { PageContext } from '@pages/ContextCreateOrEdit'

import { useFetchAllViewQuery, useFetchSearchAssistantByContextIdQuery } from '@redux/api'
import { useFetchAllSearchAssistantsQuery } from '@redux/api/searchAssistant.api'

export const useSearchAssistant = () => {
  const methods = useFormContext()
  const { id } = useContext(PageContext)
  const { getValues, reset } = methods

  const { data: searchAssistant } = useFetchSearchAssistantByContextIdQuery(
    { id },
    { skip: !id, refetchOnMountOrArgChange: true }
  )
  const { data: searchAssistantsList, isLoading: isLoadingSearchAssistants } =
    useFetchAllSearchAssistantsQuery(
      {},
      {
        refetchOnMountOrArgChange: true,
      }
    )

  const { data: viewList, isLoading: loadingViewList } = useFetchAllViewQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const views = useMemo(
    () =>
      viewList?.data?.map(view => {
        return { id: view?.id, label: view?.title }
      }),
    [viewList]
  )

  useEffect(() => {
    reset({
      ...getValues(),
      searchAssistant: searchAssistant
        ? {
            id: searchAssistant && searchAssistant.id ? searchAssistant.id : '',
            label: searchAssistant && searchAssistant.title ? searchAssistant.title : '',
          }
        : null,
      defaultView:
        searchAssistant && searchAssistant.defaultViewId && searchAssistant.defaultViewTitle
          ? {
              id: searchAssistant.defaultViewId,
              label: searchAssistant.defaultViewTitle,
            }
          : null,
    })
  }, [searchAssistant])

  const autocompleteOptionsSearchAssistant = useMemo(
    () => searchAssistantsList?.map(({ id, title: label }) => ({ id, label })) || [],
    [searchAssistantsList]
  )

  return {
    isLoadingSearchAssistants,
    autocompleteOptionsSearchAssistant,
    views,
    loadingViewList,
  }
}
