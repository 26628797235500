import { ReactNode, useEffect, useRef } from 'react'
import { AutoComplete, Chip, TextField } from '@microservices/wiskey-react-components'
import { GridCellParams } from '@microservices/wiskey-react-table-component'
import { AutocompleteInputChangeReason } from '@mui/material'

import { AutocompleteOption } from '@types'

type RenderCellValueEditProps = {
  params: GridCellParams
  values: string[]
  inputValue: string
  onChangeInput: (value: string) => void
  onChangeValue: (value: string[], id: number) => void
}

export const RenderCellValueEdit = ({
  params,
  values = [],
  inputValue,
  onChangeInput,
  onChangeValue,
}: RenderCellValueEditProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef?.current?.focus()
  }, [])

  return (
    <AutoComplete
      freeSolo
      fullWidth
      multiple
      defaultValue={values}
      inputValue={inputValue}
      options={[]}
      value={values}
      renderInput={params => {
        return (
          <TextField
            {...params}
            inputRef={inputRef}
            variant='filled'
            sx={{
              '.MuiInputBase-root': {
                '& input': {
                  padding: 0,
                  cursor: 'text',
                },
                maxHeight: 41,
                overflowY: 'auto',
                paddingTop: 0,
                paddingRight: 0,
                paddingLeft: '9px',
                background: 'transparent !important',
              },
              '*::before, *::after': {
                borderBottom: 'none !important',
              },
            }}
          />
        )
      }}
      renderTags={(value: AutocompleteOption<string | number>[], getTagProps) =>
        value.map((option: AutocompleteOption, index: number) => {
          const { key, ...tagProps } = getTagProps({ index })

          return (
            <Chip
              key={key}
              label={option as unknown as ReactNode}
              size='small'
              variant='outlined'
              sx={{
                height: '23px',
                ml: 1,
                color: theme => theme.palette.color.default,
                bgcolor: theme => theme.palette.background.chip,
                border: theme => theme.palette.border.field,
                '&.MuiChip-deleteIcon': {
                  color: theme => theme.palette.color.default,
                },
                '&.MuiChip-root:hover': {
                  bgcolor: theme => theme.palette.background.hoveredChip,
                },
                userSelect: 'all',
              }}
              {...tagProps}
            />
          )
        })
      }
      onChange={value => onChangeValue(value, params.id as number)}
      onInputChange={(
        event: React.SyntheticEvent,
        value: string,
        reason: AutocompleteInputChangeReason
      ) => onChangeInput(value)}
    />
  )
}
