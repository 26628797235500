import { PathMatch } from 'react-router-dom'
import { GridSortModel } from '@mui/x-data-grid'
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
} from '@reduxjs/toolkit/dist/query'
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks'

import {
  ConfigRowsType,
  CopyModelDataType,
  DataOfCopiedModelType,
  EntityType,
  ExtraOptions,
  ObjectFieldDTO,
  ObjectShortDTO,
} from '@types'

import { GANTT_BIND_TYPE_LIST } from '@gantt/components/GanttCreateOrEdit'

type PageContextTypeHandlers = {
  onDeleteGantt: (id: string | number) => void
  onDeleteGanttByCode: (code: string | number) => void
  onShowDialog: (data: DataOfCopiedModelType) => void
  onCopyGantt: (data: CopyModelDataType) => void
  onEditGantt: (id: string) => void
  onSetSorting: (value: GridSortModel | null) => void
  onSetObjectCode: (code: string) => void
  onSetSearchPattern: (pattern: string) => void
  onSetAllGantt: (value: ConfigRowsType[]) => void
  onSetCurrentSort: (value: GridSortModel) => void
}

export type GanttConfig = {
  id: string | number
  resourceObjectCode: string
  timelineObjectCodes: string[]
  title: string
  viewId: string | number
}

export type GanttContextValue = {
  isAllGantt?: PathMatch<string> | null
  objects?: ObjectShortDTO[]
  isLoadingGanttList?: boolean
  ganttConfigList?: GanttConfig[]
} & PageContextTypeHandlers

export type DeleteGantt = MutationTrigger<
  MutationDefinition<
    string | number,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, ExtraOptions>,
    string,
    EntityType,
    'commonApi'
  >
>
export type DeleteGanttByCode = MutationTrigger<
  MutationDefinition<
    string | number,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, ExtraOptions>,
    string,
    EntityType,
    'commonApi'
  >
>

export enum GANTT_SCRIPT_RETURN_TYPE {
  STRING = 'string',
  ARRAY_OF_OBJECTS = 'arrayOfObjects',
  BOOLEAN = 'boolean',
}

export type SelectOrAutocompleteOption = {
  id: string | number
  label?: string | number
  default?: boolean
  name: string
  value?: string | undefined | boolean
}

export type ExtOptionFilter = ObjectFieldDTO | SelectOrAutocompleteOption

export type OptionsFilter = (
  options: ExtOptionFilter[],
  bindType?: GANTT_BIND_TYPE_LIST
) => ExtOptionFilter[]

export enum GANTT_CONFIG_STEPS {
  GENERAL = 'General',
  AXIS_Y = 'Axis Y',
  AXIS_X = 'Axis X',
  MAIN_OBJECT = 'Main Object',
  PARAMETERS = 'Parameters',
}
