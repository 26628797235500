import { forwardRef, useState } from 'react'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { GETListControlFilter } from 'src/types'
import { Delete as DeleteIcon, DragIndicator as DragIndicatorIcon } from '@mui/icons-material'
import { Box, Grid, IconButton, SxProps, Theme, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'

import {
  FilterDialog,
  FormListControlFilter,
} from '../../../../../components/FilterDialog/FilterDialog'
import { FORM_ELEMENT_TYPE } from '../../../../../constants'

type FieldProps = {
  isEditOrPopup: boolean
  isSmallSize: boolean
  dragHandlePropsForIcon: DraggableProvidedDragHandleProps | undefined
  dragHandlePropsForContainer: DraggableProvidedDragHandleProps | undefined
  elementType: FORM_ELEMENT_TYPE
  elementTitle: string | undefined
  textTitle: string
  filters?: GETListControlFilter[]
  elementId: number
  handleDeleteFieldOnForm: () => void
  onSubmitFilter: (filter: GETListControlFilter) => void
  onDeleteFilter: (id: number) => void
}

const wrapperStyles: SxProps<Theme> = {
  padding: '16px 16px 16px 24px',
  gap: '16px',
  py: '14px',
  justifyContent: 'space-between',
  userSelect: 'none',
  height: 56,
  mx: 1,
  display: 'flex',
  alignItems: 'center',
  border: '1px solid',
  borderRadius: '4px',
  borderColor: grey[300],
  backgroundColor: theme => theme.palette.background.paper,
}

const Field = forwardRef<HTMLDivElement | null, FieldProps>(
  (
    {
      isEditOrPopup,
      isSmallSize,
      dragHandlePropsForIcon,
      dragHandlePropsForContainer,
      elementTitle,
      textTitle,
      elementId,
      handleDeleteFieldOnForm,
      onSubmitFilter,
    },
    newItemRef
  ) => {
    const { t } = useTranslation()
    const [isShowFilterDialog, setShowFilterDialog] = useState(false)
    const [currentFilter, setCurrentFilter] = useState<GETListControlFilter | null>(null)

    const handleCloseFilterDialog = (isDirty: boolean) => {
      if (isDirty && !confirm(t('notifications.leave'))) {
        return
      }
      setCurrentFilter(null)
      setShowFilterDialog(false)
    }

    const handleSubmitFilter = (filter: FormListControlFilter) => {
      onSubmitFilter({ ...filter, elementId })
      setShowFilterDialog(false)
      setCurrentFilter(null)
    }

    return (
      <Box
        sx={wrapperStyles}
        {...dragHandlePropsForContainer}
        title={isSmallSize ? textTitle : undefined}
      >
        <Grid container item alignItems={'center'} flexWrap={'nowrap'}>
          <Grid
            ref={newItemRef}
            container
            item
            alignItems={'baseline'}
            flexWrap={'nowrap'}
            width={'auto'}
            {...dragHandlePropsForIcon}
          >
            {!isEditOrPopup && !isSmallSize && (
              <DragIndicatorIcon
                fontSize={'small'}
                sx={{
                  alignSelf: 'center',
                  color: theme => (theme.palette.mode === 'light' ? grey[600] : grey[300]),
                }}
              />
            )}
          </Grid>
          {!isEditOrPopup && (
            <Grid item flex={1} overflow={'hidden'}>
              <Typography
                title={elementTitle}
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {elementTitle}
              </Typography>
            </Grid>
          )}
          {isShowFilterDialog && (
            <FilterDialog
              filter={currentFilter}
              isShow={isShowFilterDialog}
              onClose={handleCloseFilterDialog}
              onSubmit={handleSubmitFilter}
            />
          )}
          {!isEditOrPopup && !isSmallSize && (
            <IconButton size={'small'} sx={{ ml: 'auto', p: 0 }} onClick={handleDeleteFieldOnForm}>
              <DeleteIcon fontSize={'medium'} />
            </IconButton>
          )}
        </Grid>
      </Box>
    )
  }
)

Field.displayName = 'Field'

export { Field }
