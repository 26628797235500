import { URLS } from '@constants'
import {
  GETContainerDTO,
  GETContainerEnrichedDTO,
  GETFormElement,
  GETFormLine,
  GETFormTabDTO,
  GETListControlFilter,
  POSTCreateContainerDTO,
  POSTCreateFormElement,
  POSTCreateTabDTO,
  POSTListControlFilter,
  POSTUpdateContainerDTO,
  POSTUpdateFormElement,
  POSTUpdateFormLine,
  POSTUpdateTabDTO,
  UPDATEListControlFilter,
} from '@types'

import { commonAPI } from './common.api'

export const ContainerApi = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchContainers: build.query<GETContainerDTO[], string>({
      query: formCode => ({
        url: URLS.FRONT_CFG + `/container/form/code/${formCode}`,
      }),
      providesTags: ['FormContainers'],
    }),
    fetchEnrichedContainer: build.query<GETContainerEnrichedDTO[], string>({
      query: formCode => ({
        url: URLS.FRONT_CFG + `/container/enriched/form/code/${formCode}`,
      }),
      providesTags: ['EnrichedContainer'],
    }),
    createContainer: build.mutation<GETContainerDTO, POSTCreateContainerDTO>({
      query: body => ({
        url: URLS.FRONT_CFG + `/container`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FormContainers'],
    }),
    updateContainer: build.mutation<GETContainerDTO, POSTUpdateContainerDTO>({
      query: body => ({
        url: URLS.FRONT_CFG + `/container`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FormContainers'],
    }),
    deleteContainer: build.mutation<GETContainerDTO, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/container/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FormContainers', 'UndefinedFields', 'UndefinedViews'],
    }),
    createContainerTab: build.mutation<GETFormTabDTO, POSTCreateTabDTO>({
      query: body => ({
        url: URLS.FRONT_CFG + '/container/tab',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FormContainers'],
    }),
    updateContainerTab: build.mutation<GETFormTabDTO, POSTUpdateTabDTO>({
      query: body => ({
        url: URLS.FRONT_CFG + '/container/tab',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FormContainers'],
    }),
    deleteContainerTab: build.mutation<GETFormTabDTO, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/container/tab/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FormContainers', 'UndefinedFields', 'UndefinedViews'],
    }),
    updateContainerLine: build.mutation<GETFormLine, POSTUpdateFormLine>({
      query: body => ({
        url: URLS.FRONT_CFG + `/container/tab/line`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FormContainers'],
    }),
    createContainerElement: build.mutation<GETFormElement, POSTCreateFormElement>({
      query: body => ({
        url: URLS.FRONT_CFG + '/container/tab/line/element',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FormContainers', 'UndefinedFields', 'UndefinedViews'],
    }),
    updateContainerElement: build.mutation<GETFormElement, POSTUpdateFormElement>({
      query: body => ({
        url: URLS.FRONT_CFG + '/container/tab/line/element',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FormContainers'],
    }),
    deleteContainerElement: build.mutation<void, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/container/tab/line/element/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FormContainers', 'UndefinedViews', 'UndefinedFields'],
    }),
    createListControlFilter: build.mutation<GETListControlFilter, POSTListControlFilter>({
      query: body => ({
        url: URLS.FRONT_CFG + '/container/tab/line/element/filter',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FormContainers'],
    }),
    updateListControlFilter: build.mutation<GETListControlFilter, UPDATEListControlFilter>({
      query: body => ({
        url: URLS.FRONT_CFG + '/container/tab/line/element/filter',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['FormContainers'],
    }),
    deleteListControlFilter: build.mutation<void, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/container/tab/line/element/filter/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FormContainers'],
    }),
  }),
})

export const {
  useFetchContainersQuery,
  useFetchEnrichedContainerQuery,
  useCreateContainerMutation,
  useUpdateContainerMutation,
  useDeleteContainerMutation,
  useCreateContainerTabMutation,
  useUpdateContainerTabMutation,
  useDeleteContainerTabMutation,
  useUpdateContainerLineMutation,
  useCreateContainerElementMutation,
  useUpdateContainerElementMutation,
  useDeleteContainerElementMutation,
  useCreateListControlFilterMutation,
  useUpdateListControlFilterMutation,
  useDeleteListControlFilterMutation,
} = ContainerApi
