import i18next from 'i18next'
import * as yup from 'yup'

import { GANTT_BIND_TYPE_LIST, GuideLines } from '@gantt/components/GanttCreateOrEdit'
import { borderStyle } from '@gantt/constants'

export const ganttParametersFormSchema = yup.object().shape({
  rowHeight: yup
    .mixed()
    .test('min', i18next.t('error.ganttParametersForm.rowHeightMinMax'), value => {
      if (!value && value !== 0) {
        return true
      }

      return !(value < 20)
    }),
  backlogHeight: yup.mixed().when('hasBacklog', {
    is: true,
    then: schema => {
      return schema.test('min', i18next.t('error.ganttParametersForm.backlogHeightMin'), value => {
        if (!value && value !== 0) {
          return true
        }

        return !(value < 100)
      })
    },
  }),
  yAxisWidth: yup
    .mixed()
    .test('min', i18next.t('error.ganttParametersForm.yAxisWidthMin'), value => {
      if (!value && value !== 0) {
        return true
      }

      return !(value < 50)
    }),
  axisX: yup.object().shape({
    realtimeLineWidth: yup.mixed().when('hasRealtimeLine', {
      is: true,
      then: schema => {
        return schema.test(
          'val',
          i18next.t('error.ganttParametersForm.realtimeLineWidth'),
          value => {
            if (!value && value !== 0) {
              return true
            }

            return /^([1-4]{1}(\.5|\.0)?|5(\.0)?)$/.test(value as string)
          }
        )
      },
    }),
    boxVerticalGuideLines: yup.object().shape<Partial<Record<keyof GuideLines, yup.AnySchema>>>({
      width: yup.object().shape({
        static: yup
          .mixed()
          .when('bindType', ([bindType], schema) => {
            if (bindType === GANTT_BIND_TYPE_LIST.STATIC) {
              return schema.test(
                'min',
                i18next.t('error.ganttParametersForm.boxVerticalGuideLinesWidthMin'),
                val => {
                  if (!val && val !== 0) {
                    return true
                  }

                  return !(val < 1)
                }
              )
            }

            return schema
          })
          .nullable(),
      }),
      type: yup.object().shape({
        static: yup
          .string()
          .when('bindType', ([bindType], schema) => {
            if (bindType === GANTT_BIND_TYPE_LIST.STATIC) {
              return schema.test(
                'min',
                i18next.t('error.ganttParametersForm.boxVerticalGuideLinesTypeIncorrect'),
                val => {
                  if (!val) {
                    return true
                  }

                  return borderStyle.includes(val)
                }
              )
            }

            return schema
          })
          .nullable(),
      }),
    }),
    cursorVerticalGuideLine: yup.object().shape<Partial<Record<keyof GuideLines, yup.AnySchema>>>({
      width: yup.object().shape({
        static: yup
          .mixed()
          .when('bindType', ([bindType], schema) => {
            if (bindType === GANTT_BIND_TYPE_LIST.STATIC) {
              return schema.test(
                'min',
                i18next.t('error.ganttParametersForm.cursorVerticalGuideLineWidthMin'),
                val => {
                  if (!val && val !== 0) {
                    return true
                  }

                  return !(val < 1)
                }
              )
            }

            return schema
          })
          .nullable(),
      }),
      type: yup.object().shape({
        static: yup
          .string()
          .when('bindType', ([bindType], schema) => {
            if (bindType === GANTT_BIND_TYPE_LIST.STATIC) {
              return schema.test(
                'min',
                i18next.t('error.ganttParametersForm.cursorVerticalGuideLinesTypeIncorrect'),
                val => {
                  if (!val) {
                    return true
                  }

                  return borderStyle.includes(val)
                }
              )
            }

            return schema
          })
          .nullable(),
      }),
    }),
    borders: yup.object().shape({
      inner: yup.object().shape({
        width: yup.object().shape({
          static: yup
            .mixed()
            .when('bindType', ([bindType], schema) => {
              if (bindType === GANTT_BIND_TYPE_LIST.STATIC) {
                return schema.test(
                  'min',
                  i18next.t('error.ganttParametersForm.bordersInnerWidthMin'),
                  val => {
                    if (!val && val !== 0) {
                      return true
                    }

                    return !(val < 1)
                  }
                )
              }

              return schema
            })
            .nullable(),
        }),
      }),
      outer: yup.object().shape({
        width: yup.object().shape({
          static: yup
            .mixed()
            .when('bindType', ([bindType], schema) => {
              if (bindType === GANTT_BIND_TYPE_LIST.STATIC) {
                return schema.test(
                  'min',
                  i18next.t('error.ganttParametersForm.bordersOuterWidthMin'),
                  val => {
                    if (!val && val !== 0) {
                      return true
                    }

                    return !(val < 1)
                  }
                )
              }

              return schema
            })
            .nullable(),
        }),
      }),
    }),
    lines: yup.object().shape({
      top: yup.object().shape({
        width: yup.object().shape({
          static: yup
            .mixed()
            .when('bindType', ([bindType], schema) => {
              if (bindType === GANTT_BIND_TYPE_LIST.STATIC) {
                return schema.test(
                  'min',
                  i18next.t('error.ganttParametersForm.linesTopWidthMin'),
                  val => {
                    if (!val && val !== 0) {
                      return true
                    }

                    return !(val < 1)
                  }
                )
              }

              return schema
            })
            .nullable(),
        }),
      }),
      bottom: yup.object().shape({
        width: yup.object().shape({
          static: yup
            .mixed()
            .when('bindType', ([bindType], schema) => {
              if (bindType === GANTT_BIND_TYPE_LIST.STATIC) {
                return schema.test(
                  'min',
                  i18next.t('error.ganttParametersForm.linesBottomWidthMin'),
                  val => {
                    if (!val && val !== 0) {
                      return true
                    }

                    return !(val < 1)
                  }
                )
              }

              return schema
            })
            .nullable(),
        }),
      }),
    }),
  }),
  rowExpansionHeight: yup.mixed().when('hasRowExpansion', {
    is: true,
    then: schema => {
      return schema.test(
        'min',
        i18next.t('error.ganttParametersForm.rowExpansionHeightMin'),
        value => {
          if (!value && value !== 0) {
            return true
          }

          return !(value < 100)
        }
      )
    },
  }),
})
