import { ChangeEventHandler, FC, useContext, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { TextField } from '@microservices/wiskey-react-components'
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material'
import { Box, Grid, IconButton, InputAdornment, LinearProgress } from '@mui/material'

import { DraggableElement } from '@pages/FormCreateOrEdit/components/FieldsConfig/AvailableElementsOutsideForm/DraggableElement'

import { FORM_ELEMENT_TYPE_TO_DROPPABLE_TYPE } from '@constants'

import { FieldsConfigContext, Title } from './FieldsConfig'

export const AvailableTooltipElements: FC = () => {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')

  const {
    availableFormFields = [],
    isLoadingAvailableFields,
    changeFieldsFilter,
  } = useContext(FieldsConfigContext)

  const handleChangeSearch: ChangeEventHandler<HTMLInputElement> = event => {
    setSearch(event.target.value)
  }

  const handleClearSearch = () => {
    setSearch('')
    changeFieldsFilter(undefined)
  }

  const handleSearchButtonClick = () => {
    changeFieldsFilter(search || undefined)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      {isLoadingAvailableFields && (
        <LinearProgress sx={{ position: 'absolute', top: '-1rem', width: '100%' }} />
      )}
      <Grid container direction={'row'} flexWrap={'nowrap'} spacing={1}>
        <Grid item>
          <Title sx={{ ml: 2 }} title={t('tooltips.tabs.tooltipPoints')} />
        </Grid>
      </Grid>
      <Grid container item sx={{ mt: 1 }}>
        <TextField
          fullWidth
          placeholder={t('fieldsConfig.placeholder.fieldsFilter')}
          size={'small'}
          sx={{ mr: 2 }}
          value={search}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {search && (
                  <IconButton size={'small'} onClick={handleClearSearch}>
                    <ClearIcon />
                  </IconButton>
                )}
                <IconButton color={'primary'} size={'small'} onClick={handleSearchButtonClick}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleChangeSearch}
        />
      </Grid>
      {availableFormFields.map((element, elementIndex) => (
        <Droppable
          key={`${element.id}`}
          isDropDisabled
          droppableId={`${element.type}:${element.id}`}
          type={FORM_ELEMENT_TYPE_TO_DROPPABLE_TYPE[element.type]}
        >
          {provided => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              <DraggableElement
                element={element}
                index={elementIndex}
                title={element.title || ''}
              />
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      ))}
    </Box>
  )
}
