import { useContext, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormInput } from '@microservices/wiskey-react-components'
import { GridCellParams } from '@microservices/wiskey-react-table-component'
import { Box } from '@mui/material'

import { TableWithActions } from '@pages/Contexts/components/TableWithActions'

import { useFetchContextVariablesByIdQuery } from '@redux/api'
import { useFetchAllVariablesQuery } from '@redux/api/variables.api'

import { useDebounce } from '@hooks'
import { GENERATOR_INPUT_TYPE, SEARCH_PATTERN_DELAY } from '@constants'

import { VARIABLE_COLUMNS } from '../../constants/columns'
import { PageContext } from '../../ContextCreateOrEdit'

import { RenderCellValue } from './components/renderCell'
import { RenderCellValueEdit } from './components/renderCellEdit'

export const Variables = () => {
  const methods = useFormContext()
  const { id } = useContext(PageContext)
  const { getValues, reset, watch, setValue } = methods
  const { t } = useTranslation()

  const selectedVariables = watch('selectedVariables')
  const unselectedVariables = watch('unselectedVariables')
  const searchVariable = watch('searchVariable')

  const [editRowId, setEditRowId] = useState<number | null>(null)
  const [values, setValues] = useState<Record<number, string[]>>({})
  const [inputValue, setInputValue] = useState<string>('')

  const debouncedSearchPattern = useDebounce(searchVariable, SEARCH_PATTERN_DELAY)

  const { data: variablesById, isFetching: isLoadingVariablesById } =
    useFetchContextVariablesByIdQuery(id, { skip: !id, refetchOnMountOrArgChange: true })

  const { data: allVariables, isFetching: isLoadingAllVariables } = useFetchAllVariablesQuery(
    { searchPattern: debouncedSearchPattern ?? undefined },
    { refetchOnMountOrArgChange: true }
  )

  const handleDelete = (id: number) => {
    const { selectedVariables } = getValues()
    const deletedVariable = allVariables?.find(opt => opt.id === id)
    const selected = selectedVariables.filter(variable => variable.variableId !== id)
    const unselected = [...unselectedVariables, deletedVariable]

    setValue('selectedVariables', selected, { shouldDirty: true })
    setValue('unselectedVariables', unselected)
  }

  const handleChangeValue = (id: number) => {
    const { selectedVariables } = getValues()
    const newSelectedVariables = selectedVariables.map(variable => {
      if (variable.variableId === id) {
        return { ...variable, values: values[id] }
      }

      return variable
    })

    setInputValue('')
    setValue('selectedVariables', newSelectedVariables, { shouldDirty: true })
  }

  const handleChangeValues = (value: string[], id: number) => setValues({ ...values, [id]: value })

  const handleChangeInput = (value: string) => setInputValue(value)

  const handleEdit = (id: number | null) => setEditRowId(id)

  const handleCancel = (id: number) => {
    const prevValues =
      variablesById?.variables.find(variable => variable.variableId === id)?.values || []

    const newSelectedVariables = selectedVariables.map(variable => {
      if (variable.variableId === id) {
        return { ...variable, values: prevValues }
      }

      return variable
    })

    setValues({ ...values, [id]: prevValues })
    setInputValue('')
    setValue('selectedVariables', newSelectedVariables)
  }

  useEffect(() => {
    if (allVariables && searchVariable?.id) {
      const { selectedVariables, unselectedVariables } = getValues()
      const addedVariable = allVariables.find(variable => variable.id === searchVariable?.id)

      if (addedVariable) {
        const selected = [
          ...selectedVariables,
          {
            valueType: addedVariable.valueType,
            values: [],
            variableId: addedVariable.id,
            variableTitle: addedVariable.title,
            variableType: addedVariable.variableType,
            id: addedVariable.id,
          },
        ]
        const unselected = unselectedVariables.filter(variable => variable.id !== addedVariable.id)

        setValue('selectedVariables', selected, { shouldDirty: true })
        setValue('unselectedVariables', unselected)
      }

      setValue('searchVariable', null, { shouldDirty: false })
    }
  }, [searchVariable])

  useEffect(() => {
    if (
      variablesById?.variables &&
      allVariables &&
      !isLoadingAllVariables &&
      !isLoadingVariablesById
    ) {
      const selectedValues: Record<number, string[]> = {}
      const selected = variablesById.variables.map(variable => {
        const id = variable.variableId
        selectedValues[id] = variable.values

        return { ...variable, id }
      })
      const variableIds = variablesById.variables.map(variable => variable.variableId)
      const unselected = allVariables.filter(variable => !variableIds.includes(variable.id))

      reset({
        ...getValues(),
        selectedVariables: selected,
        unselectedVariables: unselected,
      })
      setValues(selectedValues)
    }
  }, [variablesById, allVariables, isLoadingAllVariables, isLoadingVariablesById])

  return (
    <Box>
      <FormInput
        inputType={GENERATOR_INPUT_TYPE.AUTOCOMPLETE}
        label={t('contexts.title.variable')}
        loading={isLoadingAllVariables || isLoadingVariablesById}
        name='searchVariable'
        placeholder={t('contexts.placeholder.selectVariable')}
        autocompleteOptions={
          unselectedVariables
            ? unselectedVariables.map(opt => ({
                id: opt.id,
                label: opt.title,
              }))
            : []
        }
      />
      <TableWithActions
        editMode='row'
        editRowId={editRowId}
        loading={isLoadingVariablesById || isLoadingAllVariables}
        rows={selectedVariables}
        columns={VARIABLE_COLUMNS({
          renderCell: (params: GridCellParams) => (
            <RenderCellValue params={params} values={values[params.id as number]} />
          ),
          renderEditCell: (params: GridCellParams) => (
            <RenderCellValueEdit
              inputValue={inputValue}
              params={params}
              values={values[params.id as number]}
              onChangeInput={handleChangeInput}
              onChangeValue={handleChangeValues}
            />
          ),
        })}
        onCancel={handleCancel}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onSave={handleChangeValue}
      />
    </Box>
  )
}
