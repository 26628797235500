import { MouseEvent, MutableRefObject, useState } from 'react'
import { GridApiPro } from '@microservices/wiskey-react-table-component'

import { ConfigActionsType } from '@pages/ConfiguredEntity/hooks'

import { GETAllTooltipLines, Position, ViewRow } from '@types'

type TooltipPropsType = {
  configActions: ConfigActionsType
  columns: ViewRow[]
  apiRef: MutableRefObject<GridApiPro>
}

type TooltipData = {
  items: GETAllTooltipLines
  metaData: Record<string, any>
  width: number | null
} | null

export const useTooltip = ({ configActions, columns, apiRef }: TooltipPropsType) => {
  const [position, setPosition] = useState<Position | null>(null)
  const [data, setData] = useState<TooltipData>(null)

  const handleOpenTooltip = (event: MouseEvent<HTMLElement>, isHeader?: boolean) => {
    if (isHeader) {
      const columnHeaderName = event.target.innerText
      const column = columns.find(column => column.title === columnHeaderName)
      const columnId = column?.id

      if (!columnId || !configActions.actionsData.on_header_tooltip[columnId]) {
        return
      }

      const items = configActions.actionsData.on_header_tooltip[columnId]?.tooltip?.isJson
        ? JSON.parse(configActions.actionsData.on_header_tooltip[columnId]?.tooltip?.jsonValue)
        : configActions.actionsData.on_header_tooltip[columnId]?.tooltip?.lines

      setData({
        items,
        metaData: {
          variables: configActions.actionsData.on_header_tooltip[columnId]?.tooltip?.variables,
          column,
        },
        width: configActions.actionsData.on_header_tooltip[columnId]?.tooltip?.width ?? null,
      })
      setPosition({ x: event.clientX, y: event.clientY })

      return
    }

    const field = event.target?.dataset?.field ?? ''

    if (!field || field === 'actions' || field === 'linkToForm') {
      return
    }

    const rowId = event.currentTarget.parentElement?.getAttribute('data-id')
    const cellColumnId = Object.keys(configActions.actionsAvailable.on_cell_tooltip)[0] ?? ''

    if (
      rowId &&
      cellColumnId &&
      configActions.actionsData.on_cell_tooltip[cellColumnId]?.target?.code === field
    ) {
      const column = columns.find(column => column.id === Number(cellColumnId))

      const items = configActions.actionsData.on_cell_tooltip[cellColumnId]?.tooltip?.isJson
        ? JSON.parse(configActions.actionsData.on_cell_tooltip[cellColumnId]?.tooltip?.jsonValue)
        : configActions.actionsData.on_cell_tooltip[cellColumnId]?.tooltip?.lines

      setData({
        items,
        metaData: {
          srcObj: apiRef.current.getRow(rowId),
          variables: configActions.actionsData.on_cell_tooltip[cellColumnId]?.tooltip?.variables,
          column,
        },
        width: configActions.actionsData.on_cell_tooltip[cellColumnId]?.tooltip?.width ?? null,
      })
      setPosition({ x: event.clientX, y: event.clientY })

      return
    }

    if (rowId && configActions.actionsAvailable.on_row_tooltip) {
      const items = configActions.actionsData.on_row_tooltip?.tooltip?.isJson
        ? JSON.parse(configActions.actionsData.on_row_tooltip?.tooltip?.jsonValue)
        : configActions.actionsData.on_row_tooltip?.tooltip?.lines

      setData({
        items,
        metaData: {
          srcObj: apiRef.current.getRow(rowId),
          variables: configActions.actionsData.on_row_tooltip?.tooltip?.variables,
        },
        width: configActions.actionsData.on_row_tooltip?.tooltip?.width ?? null,
      })
      setPosition({ x: event.clientX, y: event.clientY })
    }
  }

  const handleCloseTooltip = (event: MouseEvent<HTMLElement>) => {
    setPosition(null)
  }

  return {
    tooltipPosition: position,
    tooltipData: data,
    handleOpenTooltip,
    handleCloseTooltip,
  }
}
