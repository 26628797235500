import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PageContext } from '@pages/TooltipCreateOrEdit/TooltipCreateOrEdit'

import { useFetchTooltipPointByIdQuery } from '@redux/api/tooltips.api'

import { AddTooltipPointForm } from '../AddPointDialog'

import { useInputs } from './useInputs'

export const useAddPointDialog = () => {
  const { t } = useTranslation()
  const { points, variables, currentRowId, objectFields, handleClosePointDialog, handleSavePoint } =
    useContext(PageContext)

  const { data: point, isLoading: loadingPoint } = useFetchTooltipPointByIdQuery(
    currentRowId as number,
    {
      skip: !currentRowId,
      refetchOnMountOrArgChange: true,
    }
  )

  const [isShowScriptValueDialogTitle, setShowScriptValueDialogTitle] = useState(false)
  const [currentNameScriptDialog, setCurrentNameScriptDialog] = useState<
    'conditionsHidden' | 'value' | null
  >(null)

  const methods = useForm<AddTooltipPointForm>({
    defaultValues: {
      title: '',
      bindType: null,
      value: null,
      background: 'none',
      conditionsHidden: '',
    },
  })

  const {
    setValue,
    reset,
    formState: { isDirty },
    watch,
  } = methods

  const watchBackground = watch('background')

  const handleSave = (data: AddTooltipPointForm) => {
    handleSavePoint(data)
  }

  const handleClose = () => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }

    handleClosePointDialog()
  }

  const handleCloseScriptDialog = () => {
    setCurrentNameScriptDialog(null)
    setShowScriptValueDialogTitle(false)
  }

  const handleSaveScriptDialog = (value: string) => {
    if (currentNameScriptDialog) {
      setValue(currentNameScriptDialog, value, { shouldDirty: true })
    }
  }

  const handleChangeBinding = () => {
    setValue('value', null)
  }

  useEffect(() => {
    if (currentRowId && point) {
      reset({
        title: point.title,
        bindType: {
          id: point.bindType,
          label: point.bindType,
        },
        value: point.value,
        background: point.background ?? 'none',
        conditionsHidden: point.conditionsHidden,
      })
    }
  }, [point])

  const { inputs } = useInputs({
    methods,
    points,
    currentRowId,
    objectFields,
    handleChangeBinding,
    setShowScriptValueDialogTitle,
    setCurrentNameScriptDialog,
  })

  return {
    data: {
      methods,
      variables,
    },
    state: {
      isShowScriptValueDialogTitle,
      currentNameScriptDialog,
      loadingPoint,
      watchBackground,
    },
    handlers: {
      handleSave,
      handleClose,
      handleSaveScriptDialog,
      handleCloseScriptDialog,
    },
    inputs: {
      ...inputs,
    },
  }
}
