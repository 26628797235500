import { MouseEvent, useEffect, useMemo, useState } from 'react'
import isString from 'lodash/isString'
import {
  GridColDef,
  GridColumnVisibilityModel,
  MuiEvent,
  useGridApiRef,
} from '@microservices/wiskey-react-table-component'
import { Interpolation, Theme } from '@mui/material'

import { generateClassesCellDisplayRule } from '@pages/ConfiguredEntity/helpers/generateClassesCellDisplayRule'
import { useColumns } from '@pages/ConfiguredEntity/hooks/useColumns'
import {
  CellsDisplayRuleValueProps,
  CellsDisplayRuleValueType,
  OpenFormProps,
} from '@pages/ConfiguredEntity/types'

import { NestedMenuProps } from '@components/NestedMenu'

import {
  useFetchAllObjectQuery,
  useFetchAllViewQuery,
  useFetchFormQuery,
  useFetchViewByIdNoCacheMutation,
} from '@redux/api'
import { useFetchParameterPropertiesQuery } from '@redux/api/parameters.api'
import {
  useFetchRestrictionsByViewIdNoCacheMutation,
  useUpdateRestrictionsMutation,
} from '@redux/api/restriction.api'

import { useContextMenu, useSubscription, useTooltip } from '@hooks'
import {
  getColumnParametersFromArray,
  getLocalStorageContext,
  transformCommonParametersToValues,
} from '@helpers'
import {
  CLICK_EVENT_TYPE,
  COMMON_PARAMETERS,
  DEFAULT_BACKGROUND_PINNED_COLUMN,
  DEFAULT_HEADER_STYLES_CLASSNAME,
  ENTITY,
  GLOBAL_PARAMETER_CODE,
  PASSED_FORM_DATA_VARIANT,
  ROUTES,
  SEARCH_RULE_EXCEPTION,
  SEARCH_RULE_TYPE,
  SIZE_PAGE,
} from '@constants'
import {
  AutocompleteOption,
  EntityType,
  ObjectDataRecord,
  RestrictionData,
  RestrictionDTO,
  ViewRow,
} from '@types'

import {
  getClassNameCell,
  getColumnFieldName,
  getColumnParameterStyles,
  getObjectCodeUrl,
} from '../helpers'
import getClassNameGlobalStyles, {
  NAMES_OF_ENTITIES_FOR_STYLES,
} from '../helpers/getClassNameGlobalStyles'
import { getInitialColumnVisibilityModel } from '../helpers/getInitialColumnVisibilityModel'
import { getSortByColumns } from '../helpers/getSortByColumns'

import { useGetActions } from './useGetActions'
import { useHandleColumnDrag } from './useHandleColumnDrag'
import { useHandlers } from './useHandlers'

type useConfiguredViewParams = {
  entityCode: string | null
  entityId: number | string | null
  path: string
  isNestedView?: boolean
  parentFormIsDirty?: boolean
  getLeaveForm?: () => boolean
  onResetEditForm?: () => void
  onSelectPickerOption?: (row: ObjectDataRecord) => void
  type: ENTITY
  formObjectId?: string
  formObjectCode?: string
  formElementId?: number
  valueId?: number
  fieldId?: number
  selectedPickerOption?: string
  selectedPickerOptionValue?: string | AutocompleteOption
  onMultiSelectPickerOption?: (rows: ObjectDataRecord[]) => void
  multipleSelectPickerOption?: AutocompleteOption[]
  objectValueNameOption?: string
  handleOpenForm?: (
    formProps: OpenFormProps,
    event: MouseEvent<HTMLButtonElement> | MuiEvent<MouseEvent>,
    type: CLICK_EVENT_TYPE
  ) => void
  search?: string
  pathname: string
  dialogId?: string
  isListControlDialogWindow: boolean
  isViewDialogWindow?: boolean
  customPageSize: number

  entity: EntityType | null
  onSetEntity: (value: EntityType | null) => void
  readonly?: boolean
}

export const useConfiguredEntity = ({
  entityCode,
  entityId,
  path,
  isNestedView,
  parentFormIsDirty,
  type,
  getLeaveForm,
  onResetEditForm,
  onSelectPickerOption,
  formObjectId,
  formObjectCode,
  formElementId,
  fieldId,
  valueId,
  selectedPickerOptionValue,
  onMultiSelectPickerOption,
  multipleSelectPickerOption,
  objectValueNameOption,
  handleOpenForm,
  search,
  pathname,
  dialogId,
  isListControlDialogWindow,
  customPageSize,
  entity,
  onSetEntity,
  readonly,
  isViewDialogWindow,
}: useConfiguredViewParams) => {
  const context = getLocalStorageContext()

  const isAssistiantSearch = path === ROUTES.SEARCH && !isNestedView
  const [visibleColumns, setVisibleColumns] = useState<ViewRow[]>([])

  const { data: objects } = useFetchAllObjectQuery(undefined, { skip: !isAssistiantSearch })

  const [fetchEntity, { isLoading: isLoadingView }] = useFetchViewByIdNoCacheMutation()
  const [fetchEntityRestrictions, { isSuccess: isEntityRestrictionsChanged }] =
    useFetchRestrictionsByViewIdNoCacheMutation()
  const [
    updateRestrictions,
    { isSuccess: isUpdateRestrictionsSuccess, isLoading: isLoadingRestrictions },
  ] = useUpdateRestrictionsMutation()

  const [entityRestrictions, setEntityRestrictions] = useState<RestrictionData>()

  const [globalStyles, setGlobalStyles] = useState<Interpolation<Theme>>({})
  const [formCode, setFormCode] = useState<string>()
  const { data: form, isSuccess: formIsSuccess } = useFetchFormQuery(formCode, { skip: !formCode })
  const viewColumns = useMemo(() => entity?.columns?.filter(col => col.visibility) || [], [entity])
  const actions = entity?.actions

  // В dropdown window быстрый поиск есть всегда
  const hasQuickSearch = entity?.hasQuickSearch || type === ENTITY.DROP_DOWN_ENTITY
  // const hasQuickSearch = entity?.hasQuickSearch
  const objectCode = entity?.objectCode || ''

  const apiRef = useGridApiRef()

  const { data: globalColumnHeaderStyles } = useFetchParameterPropertiesQuery({
    code: GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_HEADER_STYLES,
  })

  const { data: globalColumnTextStyles } = useFetchParameterPropertiesQuery({
    code: GLOBAL_PARAMETER_CODE.GLOBAL_COLUMN_TEXT_STYLES,
  })

  const { data: commonParameters } = useFetchParameterPropertiesQuery({
    code: COMMON_PARAMETERS.STATE,
  })

  const initialCommonParameters = useMemo(
    () => transformCommonParametersToValues(commonParameters),
    [commonParameters]
  )

  const [columns, setColumns] = useState<GridColDef<ObjectDataRecord>[]>([])
  const [initialColumnVisibilityModel, setInitialColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({})
  const [objectStyles, setObjectStyles] = useState<Interpolation<Theme>>({})

  const handleGetEntityRestrictionsData = () => {
    fetchEntityRestrictions({
      viewId: entityId as string,
    })
      .unwrap()
      .then(entityRestrictions => {
        setEntityRestrictions(entityRestrictions)
      })
  }

  const handleUpdateRestrictions = (restrictions: RestrictionDTO[]) => {
    return updateRestrictions(restrictions)
  }

  const handleGetEntityThenRestrictions = (entityId: string | number) => {
    fetchEntity({ id: entityId })
      .unwrap()
      .then(entity => {
        onSetEntity(entity)
      })
      .then(() => {
        fetchEntityRestrictions({
          viewId: entityId as string,
        })
          .unwrap()
          .then(entityRestrictions => {
            setEntityRestrictions(entityRestrictions)
          })
      })
  }

  const handleSetColumnsVisibilityModel = (value: GridColumnVisibilityModel) =>
    setColumnVisibilityModel(value)

  const { state, data, handlers } = useHandlers({
    entityCode,
    entityId,
    path,
    apiRef,
    entity,
    objectCode,
    actions,
    isNestedView,
    getLeaveForm,
    onResetEditForm,
    onSelectPickerOption,
    onMultiSelectPickerOption,
    initialCommonParameters,
    initialColumnVisibilityModel,
    type,
    formObjectId,
    formObjectCode,
    formElementId,
    fieldId,
    valueId,
    multipleSelectPickerOption,
    objectValueNameOption,
    columns,
    entityRestrictions: entityRestrictions ?? ({} as RestrictionData),
    isLoadingRestrictions,
    handleUpdateRestrictions,
    onSetColumnVisibilityModel: handleSetColumnsVisibilityModel,
    handleOpenForm,
    search,
    isAssistiantSearch,
    dialogId,
    customPageSize,
    handleGetEntityRestrictionsData,
    viewColumns,
    onSetColumns: setColumns,
    readonly,
    contextId: context?.id,
  })

  const {
    samePageFormsData,
    currentDynamicForm,
    currentStaticForm,
    selectedRowIds,
    searchAssistantValue,
    isSuccessDeleteRestrictions,
  } = state

  const {
    cellRenderer,
    handleSetSelectedRowIds,
    handleObjectSubscribe,
    handleEdit,
    handleClickDelete,
    handleAdditionalButtonClick,
    handleSetTotalElements,
    handleSetPinnedColumns,
    handleSetColumnNameCheckBox,
    handleSetSortKeysColumnCheckBox,
    handleSetSortKeysForQuickSearch,
    handleSetSearchRule,
    handleSetGlobalFilter,
    refreshObjectData,
  } = handlers

  const { staticFormsData, dynamicFormsData, totalCountElements, objectData, isLoadingObjectData } =
    data

  const { position, onRefContextMenu, onClickLink, onOpenContextMenu, onCloseContextMenu } =
    useContextMenu({ dialogId })

  const configActions = useGetActions({
    actions: entity?.actions || [],
    onClickLink,
  })

  const { tooltipPosition, tooltipData, handleOpenTooltip, handleCloseTooltip } = useTooltip({
    configActions,
    columns: viewColumns,
    apiRef,
  })

  const {
    columnsForEntity,
    areAllColumnsHidden,
    setColumnVisibilityModel,
    columnVisibilityModel,
    shouldDisplayCreateButton,
    showActionsColumn,
    rowCreateAction,
    hasRowAction,
    isColumnReorderEnabled,
  } = useColumns({
    columns,
    onSetColumns: setColumns,
    entity,
    type,
    readonly,
    selectedRowIds,
    actions,
    isViewDialogWindow,
    isListControlDialogWindow,
    handleAdditionalButtonClick,
    handleEdit,
    handleClickDelete,
    parentFormIsDirty,
    handleOpenForm,
    viewColumns,
    cellRenderer,
    configActions,
    hasQuickSearch,
    setInitialColumnVisibilityModel,
    isEntityRestrictionsChanged,
    entityRestrictions,
    handleSetPinnedColumns,
    handleOpenTooltip,
    handleCloseTooltip,
  })

  useEffect(() => {
    if (!entityId || !isUpdateRestrictionsSuccess) {
      return
    }

    handleGetEntityThenRestrictions(entityId)
  }, [isUpdateRestrictionsSuccess, isSuccessDeleteRestrictions])

  const handleResetEntity = () => {
    onSetEntity(null)
    handleSetColumnNameCheckBox({})
    handleSetSortKeysColumnCheckBox({})
    handleSetSortKeysForQuickSearch([])
    handleSetSearchRule(
      {
        name: SEARCH_RULE_TYPE.CONTAIN,
        exception: SEARCH_RULE_EXCEPTION[SEARCH_RULE_TYPE.CONTAIN],
      },
      true
    )
    handleSetGlobalFilter('')
  }

  useEffect(() => {
    if (entityId) {
      handleResetEntity()

      fetchEntity({ id: entityId })
        .unwrap()
        .then(entity => {
          onSetEntity(entity)

          // Логика для первоначальной (либо при смене вью) подгрузке данных для ассистента поиска
          if (isAssistiantSearch) {
            const viewColumns = entity.columns?.filter(col => col.visibility) || []
            const initialColumnsSortKey = getInitialColumnVisibilityModel(
              viewColumns,
              true,
              SEARCH_RULE_TYPE.CONTAIN
            )
            const pageSizeForRequest = customPageSize * 2
            const sizeParameter = Number(initialCommonParameters.pagination)
            refreshObjectData(
              {
                code: entity.objectCode,
                viewId: entity.id,
                size:
                  pageSizeForRequest > sizeParameter
                    ? pageSizeForRequest.toString()
                    : sizeParameter || SIZE_PAGE,
                body: searchAssistantValue,
                sortKeysForQuickSearch: undefined,
                searchValue: undefined,
                searchRule: undefined,
                sort: getSortByColumns(viewColumns),
              },
              false
            )
          }
        })

      fetchEntityRestrictions({
        viewId: entityId as string,
      })
        .unwrap()
        .then(entityRestrictions => {
          setEntityRestrictions(entityRestrictions)
        })
    }
  }, [entityId])

  useSubscription(getObjectCodeUrl(objectCode), handleObjectSubscribe)

  const { data: allViews } = useFetchAllViewQuery()

  useHandleColumnDrag(
    apiRef,
    entityId as string,
    entityRestrictions,
    handleUpdateRestrictions,
    isLoadingView
  )

  useEffect(() => {
    setVisibleColumns(viewColumns)
  }, [viewColumns])

  useEffect(() => {
    if (totalCountElements === 0) {
      handleSetTotalElements(0)
    }
    if (totalCountElements) {
      handleSetTotalElements(totalCountElements)
    }
  }, [totalCountElements])

  useEffect(() => {
    if (type === ENTITY.DROP_DOWN_ENTITY && selectedPickerOptionValue) {
      if (!selectedRowIds.length) {
        handleSetSelectedRowIds(
          isString(selectedPickerOptionValue)
            ? selectedPickerOptionValue
            : selectedPickerOptionValue.id.toString() // TODO Тут падает ошибка Cannot read properties of undefined (reading 'toString')
        )
      }
    }
  }, [objectData, isLoadingObjectData])

  useEffect(() => {
    const currentStaticFormData = staticFormsData[currentStaticForm]
    const currentDynamicFormData = dynamicFormsData[currentDynamicForm]
    const lastSamePageFormVariant = samePageFormsData.at(-1)?.variant

    if (!lastSamePageFormVariant) {
      return
    }

    if (lastSamePageFormVariant === PASSED_FORM_DATA_VARIANT.DYNAMIC && currentDynamicFormData) {
      handleSetSelectedRowIds(currentDynamicFormData.rowId as string)
    }

    if (lastSamePageFormVariant === PASSED_FORM_DATA_VARIANT.STATIC && currentStaticFormData) {
      handleSetSelectedRowIds(currentStaticFormData.rowId as string)
    }
  }, [currentStaticForm, currentDynamicForm, samePageFormsData])

  const [contextMenuData, setContextMenuData] = useState<Omit<NestedMenuProps, 'onClose'>>({
    items: [],
    dataTransfer: {
      changes: {},
      config: {},
      data: {},
      metaData: {},
    },
    onItemClick: undefined,
  })

  const handleContextMenu = (event: MouseEvent<HTMLDivElement>) => {
    if (!event.target?.dataset?.field || event.target?.dataset?.field === 'actions') {
      return
    }

    if (configActions.actionsAvailable.on_column_right_click) {
      const field = event.target?.dataset?.field ?? ''
      const column = columns.find(col => col.field === field)
      const columnId = column?.id

      if (columnId && configActions.actionsAvailable.on_column_right_click[columnId]) {
        event.preventDefault()

        setContextMenuData({
          items: configActions.actionsData.on_column_right_click[columnId]?.contextMenu?.menuPoints,
          dataTransfer: { changes: {}, config: {}, data: {}, metaData: { column } },
          onItemClick: configActions.actionHandlers.on_column_right_click[columnId],
        })
        onOpenContextMenu(event)

        return
      }
    }

    if (configActions.actionsAvailable.on_row_right_click) {
      event.preventDefault()

      const rowId = event.currentTarget.getAttribute('data-id')

      if (rowId) {
        setContextMenuData({
          items: configActions.actionsData.on_row_right_click?.contextMenu?.menuPoints,
          dataTransfer: {
            changes: {},
            config: {},
            data: {},
            metaData: {
              srcObj: apiRef.current.getRow(rowId),
              variables: configActions.actionsData.on_row_right_click?.contextMenu?.variables,
            },
          },
          onItemClick: configActions.actionHandlers.on_row_right_click,
        })
        onOpenContextMenu(event)
      }
    }
  }

  // Эффект для стилизации колонок в соответсвии с их параметрами (глобальные, на уровне вью, на уровне колонки)
  useEffect(() => {
    if (!entity || !globalColumnHeaderStyles || !globalColumnTextStyles) {
      return
    }

    const cellsDisplayRule = generateClassesCellDisplayRule(viewColumns)

    // сеттим стили глобально с созданными классами выше
    const changeCellsDisplayRuleValueTypeToObjectClassesStyles = () => {
      if (cellsDisplayRule) {
        cellsDisplayRule.map(cell => {
          if (cell) {
            const textSetting = cell?.styleSettings?.settingText
            // TODO оптимизация
            setObjectStyles((prev: Record<any, any>) => ({
              ...prev,
              [`.${cell.className}`]: {
                fontFamily: textSetting.fontFamily,
                fontSize: `${textSetting.fontSize}px !important`,
                fontWeight: 'normal',
                fontStyle: 'normal',
                color: `${textSetting.fontColor} !important`,
                background: `${cell.styleSettings.background}`,
                border: `2px solid ${cell.styleSettings.settingBorder.color} !important`,
              },
            }))
          }
        })
      }
    }

    changeCellsDisplayRuleValueTypeToObjectClassesStyles()

    const { transformedParams: globalColumnHeaderParams, styles: globalHeaderStyles } =
      getColumnParameterStyles(globalColumnHeaderStyles)
    const { transformedParams: globalColumnTextParams, styles: globalTextStyles } =
      getColumnParameterStyles(globalColumnTextStyles)

    const globalStylesNames = getClassNameGlobalStyles(
      NAMES_OF_ENTITIES_FOR_STYLES.GLOBAL,
      entity,
      type
    )
    const entityStylesNames = getClassNameGlobalStyles(
      NAMES_OF_ENTITIES_FOR_STYLES.ENTITY,
      entity,
      type
    )

    let borderColor: string | undefined = globalTextStyles.borderColor
    // Если цвет фона у колонки = none в глобальных параметрах или в параметрах вью, или в параметрах колонки
    // (приориритет следующий, от меньшего к большему: global -> view -> column), то
    // срабатывает #BG_CONDITION (см. комментрарии с этим тегом) и при закреплении колонки
    // администратором или пользователем колонка окрашивается в цвет DEFAULT_BACKGROUND_PINNED_COLUMN (см. #USE_GREY)
    let shouldApplyPinnedBgColor = false

    setGlobalStyles({
      [`.${globalStylesNames.header}`]: globalHeaderStyles,
      [`.${globalStylesNames.text}`]: globalTextStyles,
      [`.${globalStylesNames.pinnedColumnCell}`]: DEFAULT_BACKGROUND_PINNED_COLUMN,
      borderColor,
    })

    let viewHeaderAlign: string | undefined = ''
    let viewAlign: string | undefined = ''

    // #BG_CONDITION
    if (globalTextStyles.background === 'none') {
      shouldApplyPinnedBgColor = true
    }

    if (entity.useLocalParameters && entity.parameters) {
      const { headerProperties: viewHeaderProperties, textProperties: viewTextProperties } =
        getColumnParametersFromArray(entity.parameters)

      if (viewHeaderProperties && viewTextProperties) {
        const { transformedParams: viewColumnHeaderParams, styles: viewHeaderStyles } =
          getColumnParameterStyles(viewHeaderProperties)
        const { transformedParams: viewColumnTextParams, styles: viewTextStyles } =
          getColumnParameterStyles(viewTextProperties)

        viewHeaderAlign = viewColumnHeaderParams?.alignment
        viewAlign = viewColumnTextParams?.alignment
        borderColor = viewColumnTextParams?.borderColor

        // #BG_CONDITION
        if (viewTextStyles.background === 'none') {
          shouldApplyPinnedBgColor = true
        } else {
          shouldApplyPinnedBgColor = false
        }

        setGlobalStyles((prev: Record<any, any>) => ({
          ...prev,
          [`.${entityStylesNames.header}`]: viewHeaderStyles,
          [`.${entityStylesNames.text}`]: viewTextStyles,
          [`.${entityStylesNames.pinnedColumnCell}`]: DEFAULT_BACKGROUND_PINNED_COLUMN,
          borderColor,
        }))
      }
    }

    setColumns(prev => {
      const shouldApplyPinnedBgColorBeforeCalculations = shouldApplyPinnedBgColor

      return prev.map(column => {
        const columnParameters = entity?.columns?.find(
          entityColumn =>
            getColumnFieldName(entityColumn) === column.field && entityColumn.useLocalParameters
        )?.parameters

        const entityColumnData = entity?.columns?.find(
          entityColumn => getColumnFieldName(entityColumn) === column.field
        )

        // TODO: необходимо заменить '.' на '-', т.к. не применяются стили
        let headerAlign: string | undefined = 'left'
        let align: string | undefined = 'left'

        if (globalColumnHeaderParams && globalColumnTextParams) {
          headerAlign = globalColumnHeaderParams?.alignment
          align = globalColumnTextParams?.alignment
        }

        if (entity.useLocalParameters && entity.parameters) {
          headerAlign = viewHeaderAlign
          align = viewAlign
        }

        const columnStylesNames = getClassNameGlobalStyles(
          NAMES_OF_ENTITIES_FOR_STYLES.COLUMN,
          entity,
          type,
          column
        )

        let pinnedColumnCellClassName = columnStylesNames.pinnedColumnCell

        if (columnParameters && columnParameters.length > 0) {
          const { headerProperties: columnHeaderProperties, textProperties: columnTextProperties } =
            getColumnParametersFromArray(columnParameters)

          if (columnHeaderProperties && columnTextProperties) {
            const { transformedParams: columnHeaderParams, styles: columnHeaderStyles } =
              getColumnParameterStyles(columnHeaderProperties)
            const { transformedParams: columnTextParams, styles: columnTextStyles } =
              getColumnParameterStyles(columnTextProperties)

            if (columnHeaderParams && columnTextParams) {
              headerAlign = columnHeaderParams?.alignment
              align = columnTextParams?.alignment
            }

            // #BG_CONDITION
            if (columnTextStyles.background === 'none') {
              shouldApplyPinnedBgColor = true
            } else {
              pinnedColumnCellClassName = ''
              shouldApplyPinnedBgColor = false
            }

            setGlobalStyles((prev: Record<any, any>) => ({
              ...prev,
              [`.${columnStylesNames.header}`]: columnHeaderStyles,
              [`.${columnStylesNames.text}`]: columnTextStyles,
            }))
          }
        }

        const columnIsPinned =
          entityColumnData?.pinnedColumn ||
          entityRestrictions?.fix?.find(
            restriction => restriction.fieldCode === entityColumnData?.code
          )

        // #USE_GREY
        if (shouldApplyPinnedBgColor && columnIsPinned) {
          setGlobalStyles((prev: Record<any, any>) => ({
            ...prev,
            [`.${columnStylesNames.pinnedColumnCell}`]: DEFAULT_BACKGROUND_PINNED_COLUMN,
          }))
        }

        shouldApplyPinnedBgColor = shouldApplyPinnedBgColorBeforeCalculations

        const columnsObject: Record<string, ViewRow> = Object.fromEntries(
          entity.columns ? entity.columns.map(column => [column.code, column]) : []
        )

        const displayRulesObject: Record<string, CellsDisplayRuleValueType[]> =
          cellsDisplayRule?.reduce((acc, object) => {
            const columnCode = object?.columnCode
            acc[columnCode] ??= []
            acc[columnCode].push(object)

            return acc
          }, {})

        const cellsDisplayRuleValueProps: CellsDisplayRuleValueProps = {
          displayRulesObject,
          globalStylesNames,
          entityStylesNames,
          pinnedColumnCellClassName,
          columnStylesNames,
          columnsObject,
        }

        return {
          ...column,
          headerClassName: `${globalStylesNames.header} ${entityStylesNames.header} ${columnStylesNames.header} ${DEFAULT_HEADER_STYLES_CLASSNAME}`,
          cellClassName: params => getClassNameCell({ ...cellsDisplayRuleValueProps, params }),
          headerAlign,
          align,
        }
      })
    })
  }, [entity, entityRestrictions, globalColumnHeaderStyles, globalColumnTextStyles])

  // TODO В коммите написано WF-1690 - Навигация в списочных представлениях с помощью клавиш
  // TODO Но непонятно, как это работает
  // TODO При обновлении с 5 на 6 версию, необходимо избавиться от такого события и использовать slotProps.cell.onFocus
  // useEffect(() => {
  //   if (entity && !isLoadingView && apiRef.current && apiRef.current.subscribeEvent) {
  //     apiRef.current.subscribeEvent('cellFocusIn', params => {
  //       console.log('handleSetSelectedRowIds cellFocusIn')
  //       handleSetSelectedRowIds(params.row._id)
  //     })
  //   }
  // }, [apiRef])

  const isForm = pathname.includes('/forms/')
  const isSearchResult = isAssistiantSearch && entityId
  const isShowTableData = Boolean(entityId)

  const isListControl = type === ENTITY.LIST_CONTROL

  return {
    state: {
      ...state,
      globalStyles,
      isListControl,
      columnVisibilityModel,
      showActionsColumn,
      visibleColumns,
      viewColumns,
      entity,
      configActions,
      contextMenuData,
      onRefContextMenu,
      position,
      objectStyles,
      tooltipPosition,
      tooltipData,
    },
    data: {
      ...data,
      areAllColumnsHidden,
      isAssistiantSearch,
      objectData: areAllColumnsHidden ? [] : data.objectData,
      objects,
      form,
      formIsSuccess,
      actions,
      objectCode,
      allViews,
      columns: columnsForEntity,
      isForm,
      isSearchResult,
      isShowTableData,
      rowCreateAction,
      hasRowAction,
      shouldDisplayCreateButton,
      isLoadingView,
      apiRef,
      isColumnReorderEnabled,
      hasQuickSearch,
    },
    handlers: {
      onCloseContextMenu,
      handleContextMenu,
      handleOpenTooltip,
      handleCloseTooltip,
      ...handlers,
    },
  }
}
