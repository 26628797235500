import { useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'

import { useFetchAllObjectQuery, useFetchObjectByCodeQuery } from '@redux/api'
import { useFetchTooltipByIdQuery } from '@redux/api/tooltips.api'

import { usePrompt, useYupValidationResolver } from '@hooks'
import { ROUTES } from '@constants'

import { tooltipSchema } from '@validations'

import { TooltipForm } from '../types'

import { useHandlers } from './useHandlers'

const defaultValues = {
  id: null,
  code: '',
  title: '',
  width: null,
  object: null,
  points: [],
  isJson: false,
  jsonValue: '',
  variables: [],
}

export const useTooltipCreateOrEdit = () => {
  const location = useLocation() as unknown as Location & { state?: { id?: string } }
  const { id: paramsId } = useParams()
  const id = Number(paramsId)
  const copyId = location.state?.id
  const resolver = useYupValidationResolver(tooltipSchema)
  const methods = useForm<TooltipForm>({
    defaultValues,
    mode: 'all',
    reValidateMode: 'onChange',
    resolver,
  })
  const {
    watch,
    reset,
    getValues,
    formState: { isDirty },
  } = methods
  const {
    fields: variableFields,
    append,
    remove,
  } = useFieldArray({
    name: 'variables',
    control: methods.control,
  })

  const watchedIsJson = watch('isJson')
  const watchedJsonValue = watch('jsonValue')
  const watchedPoints = watch('points')
  const watchedVariables = watch('variables')

  const { state, data, handlers } = useHandlers({
    id,
    methods,
    append,
    remove,
  })

  const { isLoading } = state

  const disabledTab = !id || watchedIsJson

  const modelType = ROUTES.CONFIG_TOOLTIPS_CREATE === location.pathname ? 'create' : 'edit'
  const isEdit = id || copyId

  const { data: objects, isLoading: isLoadingObjects } = useFetchAllObjectQuery()
  const { data: tooltipDataById, isFetching: isLoadingTooltipById } = useFetchTooltipByIdQuery(id, {
    skip: !isEdit,
    refetchOnMountOrArgChange: true,
  })
  const { data: object } = useFetchObjectByCodeQuery(tooltipDataById?.objectCode, {
    skip: !tooltipDataById?.objectCode,
  })

  const isLeaveEdit = isDirty && isEdit && !isLoading && !!tooltipDataById
  const isLeaveCreate = isDirty && !isEdit && !isLoading

  usePrompt({ when: isLeaveEdit || isLeaveCreate })

  useEffect(() => {
    if (tooltipDataById) {
      reset({
        ...getValues(),
        id: tooltipDataById.id,
        code: tooltipDataById.code,
        title: tooltipDataById.title,
        width: tooltipDataById.width,
        object: {
          id: tooltipDataById.objectCode,
          label: tooltipDataById.objectTitle,
        },
        isJson: tooltipDataById.isJson,
        jsonValue: tooltipDataById.jsonValue,
        points: tooltipDataById.points,
        variables: tooltipDataById.variables,
      })
    }
  }, [tooltipDataById])

  return {
    data: {
      objects,
      isLoadingObjects,
      isLoadingTooltipById,
      modelType,
      methods,
      copyId,
      id,
      isDirty,
      isEdit,
      objectFields: object?.fields ?? [],
      variableFields,
      watchedIsJson,
      watchedJsonValue,
      watchedPoints,
      watchedVariables,
      ...data,
    },
    state: {
      disabledTab,
      ...state,
    },
    handlers: {
      ...handlers,
    },
  }
}
