import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'

import { FormFiltersType } from '@pages/BarChartPage/BarChartPage'
import { DatasetParams } from '@pages/BarChartPage/hooks/useDataset'

import { useDebounce } from '@hooks'

export const useDatasetParams = (
  methods: UseFormReturn<FormFiltersType, any, undefined>,
  defaultValues: FormFiltersType
) => {
  const {
    watch,
    formState: { isDirty },
  } = methods

  const watchedPlanDay = watch('planDay')
  const watchedQual = watch('qual')
  const watchedDepartment = watch('department')
  // const watchedPlanQual = watch('planQual')
  // const watchedStaffQual = watch('staffQual')

  const defaultDatasetParams: DatasetParams = useMemo(
    () => ({
      planDay: defaultValues.planDay,
      qual: defaultValues.qual,
      department: defaultValues.department,
      // planQual: defaultValues.planQual,
      // staffQual: defaultValues.staffQual,
    }),
    [defaultValues]
  )

  const datasetParams: DatasetParams = useMemo(() => {
    if (!isDirty) {
      return defaultDatasetParams
    }

    return {
      planDay: watchedPlanDay,
      qual: watchedQual,
      department: watchedDepartment,
      // planQual: watchedPlanQual,
      // staffQual: watchedStaffQual,
    }
  }, [isDirty, watchedPlanDay, watchedQual, watchedDepartment, defaultValues])

  return useDebounce(datasetParams, 500)
}
