import { EntityType, ObjectDataRecord } from 'src/types'
import { GridColDef } from '@mui/x-data-grid'

import {
  ENTITY,
  ENTITY_PATH,
  STYLED_COLUMN_HEADER_CLASSNAME,
  STYLED_COLUMN_TEXT_CLASSNAME,
  STYLED_PINNED_COLUMN_CLASSNAME,
} from '@constants'

export enum NAMES_OF_ENTITIES_FOR_STYLES {
  GLOBAL = 'GLOBAL',
  COLUMN = 'COLUMN',
  ENTITY = 'ENTITY',
}

const getClassNameGlobalStyles = (
  name: NAMES_OF_ENTITIES_FOR_STYLES,
  entity: EntityType,
  type: ENTITY,
  column?: GridColDef<ObjectDataRecord, any, any>
) => {
  const modifyColumnField = column?.field.replaceAll('.', '-')

  switch (name) {
    case NAMES_OF_ENTITIES_FOR_STYLES.GLOBAL:
      return {
        header: `${STYLED_COLUMN_HEADER_CLASSNAME}_${entity.code}_${entity.id}`,
        text: `${STYLED_COLUMN_TEXT_CLASSNAME}_${entity.code}_${entity.id}`,
        pinnedColumnCell: `${STYLED_PINNED_COLUMN_CLASSNAME}_${entity.code}_${entity.id}`,
      }
    case NAMES_OF_ENTITIES_FOR_STYLES.ENTITY:
      return {
        header: `${STYLED_COLUMN_HEADER_CLASSNAME}-${entity.code}_${entity.id}_${ENTITY_PATH[type]}`,
        text: `${STYLED_COLUMN_TEXT_CLASSNAME}-${entity.code}_${entity.id}_${ENTITY_PATH[type]}`,
        pinnedColumnCell: `${STYLED_PINNED_COLUMN_CLASSNAME}-${entity.code}-${entity.id}_${ENTITY_PATH[type]}`,
      }
    case NAMES_OF_ENTITIES_FOR_STYLES.COLUMN:
      return {
        header: `${STYLED_COLUMN_HEADER_CLASSNAME}-${entity.code}_${entity.id}_${ENTITY_PATH[type]}-${modifyColumnField}_field`,
        text: `${STYLED_COLUMN_TEXT_CLASSNAME}-${entity.code}_${entity.id}_${ENTITY_PATH[type]}-${modifyColumnField}_field`,
        pinnedColumnCell: `${STYLED_PINNED_COLUMN_CLASSNAME}-${entity.code}-${entity.id}_${ENTITY_PATH[type]}-${modifyColumnField}_field`,
      }
    default:
      return {
        header: '',
        text: '',
        pinnedColumnCell: '',
      }
  }
}

export default getClassNameGlobalStyles
