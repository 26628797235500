import i18next from 'i18next'
import * as yup from 'yup'

import { VariableType } from '@pages/ContextMenuCreateOrEdit'

export const tooltipSchema = yup.object().shape({
  variables: yup.array().of(
    yup.object().shape({
      field: yup
        .string()
        .test('required', '', val => Boolean(val?.trim()))
        .test('unique', '', function (value, ctx) {
          return this.from
            ?.at(-1)
            ?.value.variables.filter((variable: VariableType) => variable.field === value).length >
            1
            ? ctx.createError({ message: i18next.t('alreadyError', { value }), path: ctx.path })
            : true
        }),
    })
  ),
  code: yup.string().test('required', '', val => Boolean(val?.trim())),
  title: yup.string().test('required', '', val => Boolean(val?.trim())),
  object: yup.object().shape({
    id: yup.string().test('required', '', val => Boolean(val?.trim())),
    label: yup.string().test('required', '', val => Boolean(val?.trim())),
  }),
  width: yup
    .string()
    .test('max', i18next.t('tooltips.errors.widthMaxValue'), val =>
      Boolean(val ? Number(val) > 0 && Number(val) <= 1000 : true)
    )
    .notRequired(),
})
