import { FC } from 'react'
import moment from 'moment'
import { Close as CloseIcon } from '@mui/icons-material'
import { Chip, Divider, Grid, IconButton } from '@mui/material'

type ViewFilterChipsProps = {
  filter: {
    from: string
    to: string
  }
  handleDeleteFilter: () => void
}

export const VewFilterChips: FC<ViewFilterChipsProps> = ({ filter, handleDeleteFilter }) => {
  return (
    <Grid container p={1} spacing={1}>
      <Grid item>
        <Chip label={moment(filter.from).format('DD.MM.YYYY HH:mm')} size='small'></Chip>
      </Grid>
      <Grid item>
        <Grid container alignItems='center' height={'100%'}>
          <Divider orientation={'horizontal'} sx={{ width: 15 }} />
        </Grid>
      </Grid>
      <Grid item>
        <Chip label={moment(filter.to).format('DD.MM.YYYY HH:mm')} size='small'></Chip>
      </Grid>
      <Grid item>
        <IconButton size={'small'} sx={{ p: 0 }} onClick={handleDeleteFilter}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </Grid>
    </Grid>
  )
}
