import {
  Dispatch,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { t } from 'i18next'
import { Checkbox } from '@microservices/wiskey-react-components'
import {
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridPinnedColumnPosition,
  GridPinnedColumns,
  GridRenderCellParams,
  MuiEvent,
} from '@microservices/wiskey-react-table-component'
import { Box } from '@mui/material'

import { HeaderRender } from '@pages/ConfiguredEntity/components/HeaderRender'
import {
  findActionByEventCode,
  getColumnFieldName,
  getColumnFieldNameForSort,
  getInitialColumnVisibilityModel,
  sortByOrder,
  transformFixRestrictions,
} from '@pages/ConfiguredEntity/helpers'
import { ConfigActionsType } from '@pages/ConfiguredEntity/hooks/useGetActions'
import { OpenFormProps } from '@pages/ConfiguredEntity/types'

import {
  generateDataGridActionsColumn,
  generateDataGridLinkColumn,
  getActionBoolean,
} from '@helpers'
import {
  BIND_TYPE,
  CLICK_EVENT_TYPE,
  DEFAULT_HEADER_STYLES_CLASSNAME,
  ENTITY,
  EVENT_CODE,
  MIN_VIEW_COLUMN_WIDTH,
  PINNED_COLUMN,
} from '@constants'
import { EntityType, ObjectDataRecord, RestrictionData, ViewActionRow, ViewRow } from '@types'

type UseColumnsParams = {
  columns: GridColDef<ObjectDataRecord>[]
  onSetColumns: Dispatch<SetStateAction<GridColDef<ObjectDataRecord>[]>>
  type: ENTITY
  parentFormIsDirty?: boolean
  readonly?: boolean
  selectedRowIds: ObjectDataRecord[]
  entity: EntityType | null
  actions?: ViewActionRow[]
  isViewDialogWindow?: boolean
  isListControlDialogWindow: boolean
  handleEdit: (
    params: GridCellParams<ObjectDataRecord>,
    event: MouseEvent<HTMLButtonElement>
  ) => void
  handleAdditionalButtonClick: (
    params: GridCellParams<ObjectDataRecord>,
    event: MouseEvent<HTMLButtonElement>
  ) => void
  handleClickDelete: (params?: GridCellParams<ObjectDataRecord>) => void
  handleOpenForm?: (
    formProps: OpenFormProps,
    event: MouseEvent<HTMLButtonElement> | MuiEvent<MouseEvent>,
    type: CLICK_EVENT_TYPE
  ) => void
  viewColumns: ViewRow[]
  cellRenderer: (
    column: ViewRow,
    params: GridRenderCellParams<unknown, ObjectDataRecord, unknown>
  ) => ReactNode
  configActions: ConfigActionsType
  hasQuickSearch: boolean
  setInitialColumnVisibilityModel: Dispatch<SetStateAction<GridColumnVisibilityModel>>
  isEntityRestrictionsChanged: boolean
  entityRestrictions?: RestrictionData
  handleSetPinnedColumns: (value: GridPinnedColumns) => void
  handleOpenTooltip: (event: MouseEvent<HTMLElement>, isHeader?: boolean) => void
  handleCloseTooltip: (event: MouseEvent<HTMLElement>) => void
}

export const useColumns = ({
  columns,
  onSetColumns,
  type,
  parentFormIsDirty,
  readonly,
  selectedRowIds,
  entity,
  actions,
  isViewDialogWindow,
  isListControlDialogWindow,
  handleEdit,
  handleAdditionalButtonClick,
  handleClickDelete,
  handleOpenForm,
  viewColumns,
  cellRenderer,
  configActions,
  hasQuickSearch,
  setInitialColumnVisibilityModel,
  isEntityRestrictionsChanged,
  entityRestrictions,
  handleSetPinnedColumns,
  handleOpenTooltip,
  handleCloseTooltip,
}: UseColumnsParams) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({})

  // Колонка с чекбоксами у dropdown window
  const selectionColumn: GridColDef = {
    field: 'select',
    headerName: t('configuredView.select'),
    type: 'custom', // TODO ругается на select
    headerAlign: 'center',
    align: 'center',
    width: 75,
    resizable: false,
    hideable: false,
    pinnedColumn: PINNED_COLUMN.LEFT,
    sortable: false,
    headerClassName: `${DEFAULT_HEADER_STYLES_CLASSNAME}`,
    renderCell: params => {
      return (
        <Box>
          <Checkbox
            checked={selectedRowIds.includes(params.row._id)} // TODO Поменять хардкод хотя бы на uuid поле id
            disabled={readonly}
            sx={{
              input: {
                zIndex: theme => theme.zIndex.modal + 15,
              },
              color: theme => theme.palette.color.ddwCheckbox,
              '&.Mui-checked': {
                color: theme => theme.palette.color.ddwCheckbox,
              },
              '&.Mui-disabled': {
                color: theme => theme.palette.grey['400'],
                pointerEvents: 'fill',
              },
            }}
          />
        </Box>
      )
    },
  }

  const areAllColumnsHidden = useMemo(
    () =>
      Object.keys(columnVisibilityModel).filter(modelKey => !columnVisibilityModel[modelKey])
        .length === entity?.columns?.length,
    [columnVisibilityModel, entity]
  )

  const rowClickAction = findActionByEventCode(EVENT_CODE.ON_ROW_CLICK, actions)
  const rowCreateAction = findActionByEventCode(EVENT_CODE.ON_ROW_CREATE, actions)
  const rowEditAction = findActionByEventCode(EVENT_CODE.ON_ROW_EDIT, actions)
  const rowDeleteButtonClickAction = findActionByEventCode(
    EVENT_CODE.ON_ROW_DELETE_BUTTON_CLICK,
    actions
  )
  const rowAdditionalButtonClickAction = findActionByEventCode(
    EVENT_CODE.ON_ROW_ADDITIONAL_BUTTON_CLICK,
    actions
  )

  const hasRowAction = getActionBoolean(rowClickAction)

  const isShowRowDeleteButtonClick = getActionBoolean(
    rowDeleteButtonClickAction,
    rowDeleteButtonClickAction?.actionCode
  )
  const isShowEdit = getActionBoolean(rowEditAction)
  const isShowAdditionalButton = getActionBoolean(rowAdditionalButtonClickAction)
  const showActionsColumn = isShowEdit || isShowAdditionalButton || isShowRowDeleteButtonClick

  const shouldDisplayCreateButton = getActionBoolean(rowCreateAction)

  const columnsWithActions = useMemo(() => {
    if (showActionsColumn) {
      return [
        generateDataGridLinkColumn(isViewDialogWindow || isListControlDialogWindow),
        ...columns,
        generateDataGridActionsColumn({
          onEdit: isShowEdit ? handleEdit : undefined,
          onDelete: isShowRowDeleteButtonClick ? handleClickDelete : undefined,
          onAdditionalButtonClick: isShowAdditionalButton ? handleAdditionalButtonClick : undefined,
        }),
      ]
    }

    if (hasRowAction) {
      return [
        generateDataGridLinkColumn(isViewDialogWindow || isListControlDialogWindow),
        ...columns,
      ]
    }

    return [...columns]
  }, [columns, parentFormIsDirty, showActionsColumn, handleOpenForm])

  const columnsForEntity = useMemo(
    () =>
      type === ENTITY.DROP_DOWN_ENTITY
        ? [selectionColumn, ...columnsWithActions]
        : columnsWithActions,
    [selectionColumn, type, areAllColumnsHidden]
  )

  useEffect(() => {
    onSetColumns(
      sortByOrder(viewColumns).map(column => {
        const shouldSort = column.bindType === BIND_TYPE.FIELD || !!column.columnToSort

        return {
          id: column.id,
          internalId: column.internalId,
          field: getColumnFieldName(column),
          headerName: column.title,
          sortable: shouldSort,
          editable: false,
          hideable: !column.pinnedColumn,
          // Если колонка закреплена администратором, то пользователь не может ее открепить/перезакрепить
          // pinnable: Boolean(column.pinnedColumn),
          sortKey: getColumnFieldNameForSort(column),
          columnValue: column.value,
          minWidth: MIN_VIEW_COLUMN_WIDTH,
          width: column?.restrictions?.width ?? MIN_VIEW_COLUMN_WIDTH,
          order: column?.restrictions?.order ?? column.order,
          renderCell: params => cellRenderer(column, params),
          columnObjectValue: column.objectValue ?? '',
          renderHeader: params => (
            <HeaderRender
              column={column}
              handleCloseTooltip={handleCloseTooltip}
              handleOpenTooltip={handleOpenTooltip}
              hasQuickSearch={hasQuickSearch}
              params={params}
            />
          ),
          // TODO Отключил flex, так-как он не работает с width, а свойство width необходимо для ресайза
          // flex: 1,
          // order: Math.round(Math.random() * 100),
          pinnedColumn: column.pinnedColumn,
          // headerClassName:
          //   column.bindType !== BIND_TYPE.FIELD
          //     ? TABLE_DESCRIPTION.MODIFY
          //     : TABLE_DESCRIPTION.COMMON,
        }
      })
    )

    const initialColumnVisibilityModel = getInitialColumnVisibilityModel(viewColumns)

    setInitialColumnVisibilityModel(initialColumnVisibilityModel)
    setColumnVisibilityModel(initialColumnVisibilityModel)
  }, [entity, isEntityRestrictionsChanged])

  useEffect(() => {
    const pinnedColumns: GridPinnedColumns = {
      left: type === ENTITY.DROP_DOWN_ENTITY ? ['linkToForm', 'select'] : ['linkToForm'], // 'linkToForm' - стрелка в 1 столбце, обязательное закрепление
      right: [],
    }

    entity?.columns?.forEach(column => {
      const pinnedColumn = column?.pinnedColumn

      if (pinnedColumn) {
        pinnedColumns[pinnedColumn]?.push(column.code)
      }
    })

    if (entityRestrictions && entityRestrictions.fix) {
      const transformedFixRestrictions = transformFixRestrictions(entityRestrictions.fix)

      Object.keys(pinnedColumns).forEach((leftRightKey: string) => {
        pinnedColumns[leftRightKey as GridPinnedColumnPosition]?.push(
          ...(transformedFixRestrictions[leftRightKey as GridPinnedColumnPosition] || [])
        )
      })
    }

    pinnedColumns.right?.push('actions')

    // Во избежание дубля колонки при закреплении ее во вью и в конфигураторе
    const uniqPinnedColumns = {
      left: Array.from(new Set(pinnedColumns.left)),
      right: Array.from(new Set(pinnedColumns.right)),
    }

    handleSetPinnedColumns(uniqPinnedColumns)
  }, [entityRestrictions, entity])

  const isColumnReorderEnabled = type === ENTITY.VIEW || type === ENTITY.LIST_CONTROL

  return {
    columnsForEntity,
    areAllColumnsHidden,
    setColumnVisibilityModel,
    columnVisibilityModel,
    shouldDisplayCreateButton,
    showActionsColumn,
    rowCreateAction,
    hasRowAction,
    isColumnReorderEnabled,
  }
}
