import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { RndResizeCallback } from 'react-rnd'
import { Grid } from '@mui/material'

import {
  useDeletePointMutation,
  useUpdatePointLineElementByIdMutation,
} from '@redux/api/tooltips.api'

import { FORM_ELEMENT_TYPE, GRID_SIZE } from '../../../../../constants'
import { ResizeGridItemWrapper } from '../../../../../features/ResizeGridItemWrapper'
import { getElementPathByDroppableId, getLineById } from '../../../../../helpers'
import {
  useCreateListControlFilterMutation,
  useDeleteListControlFilterMutation,
  useUpdateListControlFilterMutation,
} from '../../../../../redux/api/container.api'
import { GETListControlFilter, TooltipContainer, TooltipElement } from '../../../../../types'
import { FieldsConfigContext } from '../FieldsConfig'

import { Field } from './Field'

type DraggableFieldOnFormProps = {
  element: TooltipElement
  index: number
  countSizeRow: number
  pathToLine: string
  width: number
}

export const DraggableFieldOnTooltip: FC<DraggableFieldOnFormProps> = ({
  element,
  index,
  pathToLine,
  countSizeRow,
  width,
}) => {
  const { containers } = useContext(FieldsConfigContext)
  const [deleteElement] = useDeletePointMutation()
  const [updateElement] = useUpdatePointLineElementByIdMutation()
  const [createFilter] = useCreateListControlFilterMutation()
  const [updateFilter] = useUpdateListControlFilterMutation()
  const [deleteFilter] = useDeleteListControlFilterMutation()

  const [currentSize, setCurrentSize] = useState(element.size)
  const [textTitle, setTextTitle] = useState('')

  const textElementCreationInProgress = element.type === FORM_ELEMENT_TYPE.TEXT && element.id === -1
  const isSmallSize = currentSize === 1
  const elementId = `elementId:${element.id}`

  const newItemRef = useRef<HTMLDivElement>(null)

  const minWidth = useMemo(() => width / GRID_SIZE, [width])
  const remains = useMemo(
    () => GRID_SIZE - (countSizeRow - currentSize),
    [countSizeRow, currentSize]
  )

  useEffect(() => {
    if (element.title) {
      setTextTitle(element.title)
    }
  }, [])

  const handleDeleteFieldOnForm = () => {
    const newContainers = structuredClone(containers) as TooltipContainer[]
    const [containerId, tabId, lineId] = getElementPathByDroppableId(pathToLine)
    const { line } = getLineById(newContainers, containerId, tabId, lineId)

    if (!line) {
      return
    }

    line.elements.splice(index, 1)

    deleteElement(element.id)
  }

  const getSize = (width: number): number => {
    const widthSize = Math.round(width / minWidth)
    // размер если widthSize больше свободного места в строке
    const sizeLargerRemains = widthSize >= remains ? remains : widthSize
    // результат в зависимости от единицы
    return widthSize > 1 ? sizeLargerRemains : 1
  }

  const handleResize: RndResizeCallback = (e, direction, ref, delta) => {
    const result = getSize(parseInt(ref.style.width, 10))

    setCurrentSize(result)
  }

  const handleResizeStop: RndResizeCallback = (e, direction, ref, delta) => {
    const [, , lineId] = getElementPathByDroppableId(pathToLine)

    const result = getSize(parseInt(ref.style.width, 10))

    if (result !== element.size) {
      updateElement({
        id: element.id,
        lineId,
        pointId: element.point.id,
        title: element.title,
        order: element.order,
        size: result,
      })
    }
  }

  const handleSubmitFilter = (filter: GETListControlFilter) => {
    if (filter.id) {
      updateFilter(filter)

      return
    }
    createFilter(filter)
  }

  const handleDeleteFilter = (id: number) => {
    deleteFilter(id)
  }

  return (
    <>
      <Draggable draggableId={elementId} index={index}>
        {(provided, snapshot) => {
          const dragHandlePropsForContainer =
            isSmallSize && provided.dragHandleProps ? provided.dragHandleProps : undefined
          const dragHandlePropsForIcon =
            !isSmallSize && provided.dragHandleProps ? provided.dragHandleProps : undefined

          return (
            <Grid
              ref={provided.innerRef}
              item
              alignItems={'center'}
              {...provided.draggableProps}
              sx={{
                py: 0.5,
              }}
            >
              <ResizeGridItemWrapper
                countSize={countSizeRow}
                disableResizing={element.type === FORM_ELEMENT_TYPE.VIEW}
                size={currentSize}
                width={width}
                onResize={handleResize}
                onResizeStop={handleResizeStop}
              >
                <Field
                  ref={newItemRef}
                  dragHandlePropsForContainer={dragHandlePropsForContainer}
                  dragHandlePropsForIcon={dragHandlePropsForIcon}
                  elementId={element.id}
                  elementTitle={element.title}
                  elementType={element.type}
                  filters={element.filters}
                  handleDeleteFieldOnForm={handleDeleteFieldOnForm}
                  isEditOrPopup={textElementCreationInProgress}
                  isSmallSize={isSmallSize}
                  textTitle={textTitle}
                  onDeleteFilter={handleDeleteFilter}
                  onSubmitFilter={handleSubmitFilter}
                />
              </ResizeGridItemWrapper>
            </Grid>
          )
        }}
      </Draggable>
    </>
  )
}
