import { AutocompleteOption } from '@microservices/wiskey-react-components/dist/AutoComplete'

export enum PARAMETER_SHOW_SA {
  VIEW = 'As a part of the View',
  FILTER = 'As a standalone filter',
  NO_SA = 'No SA',
}
export const PARAMETER_SHOW_SA_VALUES = {
  [PARAMETER_SHOW_SA.VIEW]: 'VIEW',
  [PARAMETER_SHOW_SA.FILTER]: 'FILTER',
  [PARAMETER_SHOW_SA.NO_SA]: 'NO_SA',
}

export const PARAMETER_SHOW_SA_OPTIONS: AutocompleteOption[] = [
  { id: PARAMETER_SHOW_SA_VALUES[PARAMETER_SHOW_SA.VIEW], label: PARAMETER_SHOW_SA.VIEW },
  // {id: PARAMETER_SHOW_SA_VALUES[PARAMETER_SHOW_SA.FILTER], label: PARAMETER_SHOW_SA.FILTER},
  // {id: PARAMETER_SHOW_SA_VALUES[PARAMETER_SHOW_SA.NO_SA], label: PARAMETER_SHOW_SA.NO_SA},
]
