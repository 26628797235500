import { GANTT_BIND_TYPE } from '@constants'

import {
  BACKGROUND_BINDING_TYPE,
  FILTER_BINDING_TYPE,
  GANTT_BIND_TYPE_LIST,
  SegmentType,
} from '@gantt/components/GanttCreateOrEdit/types'
import { EMPTY_COMMAND_FORM } from '@gantt/constants'

const barDefaultValue = {
  segmentType: SegmentType.MULTI,
  commands: {
    bindType: GANTT_BIND_TYPE_LIST.COMMANDS,
    commands: EMPTY_COMMAND_FORM,
  },
  actions: [],
  additionalFields: [],
  sections: [],
  data: null,
  hasCorners: false,
  title: {
    bindType: GANTT_BIND_TYPE.FIELD,
    field: { field: '', pathStr: '', pathArray: [] },
  },
  tooltip: {
    bindType: GANTT_BIND_TYPE.FIELD,
    field: { field: '', pathStr: '', pathArray: [] },
  },
  background: {
    bindType: BACKGROUND_BINDING_TYPE.STATIC,
    static: '',
  },
  filter: {
    static: '',
    bindType: FILTER_BINDING_TYPE.STATIC,
  },
  key: {
    bindType: GANTT_BIND_TYPE.FIELD,
    field: { field: '', pathStr: '', pathArray: [] },
  },
  isNew: true,
  lines: {
    top: {
      has: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: false,
      },
      color: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      width: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
    },
    bottom: {
      has: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: false,
      },
      color: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      width: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
    },
  },
  borders: {
    inner: {
      has: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: false,
      },
      color: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      width: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
    },
    outer: {
      has: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: false,
      },
      color: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
      width: {
        bindType: GANTT_BIND_TYPE_LIST.STATIC,
        static: '',
      },
    },
  },
  textColor: {
    bindType: GANTT_BIND_TYPE_LIST.STATIC,
    static: '',
  },
  boxVerticalGuideLines: {
    has: {
      bindType: GANTT_BIND_TYPE_LIST.STATIC,
      static: false,
    },
    isBoxColor: {
      bindType: GANTT_BIND_TYPE_LIST.STATIC,
      static: false,
    },
    color: {
      bindType: GANTT_BIND_TYPE_LIST.STATIC,
      static: undefined,
    },
    width: {
      bindType: GANTT_BIND_TYPE_LIST.STATIC,
      static: '',
    },
    type: {
      bindType: GANTT_BIND_TYPE_LIST.STATIC,
      static: '',
    },
  },
}

export const getBarDefaultValue = () => {
  return structuredClone(barDefaultValue)
}
