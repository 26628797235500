// TODO Исправить в библиотеке
import { SelectOption } from '@microservices/wiskey-react-components'

import { AutocompleteOption, LabelPlacement } from '@types'

export enum FORM_TYPE {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
  VIEW_EDIT = 'view_edit',
}

export enum EVENT_ACTION_FORM {
  edit = 'on_row_edit',
  view = 'on_row_click',
  view_edit = 'on_row_click',
  create = 'on_row_create',
}

export enum FIELD_VALUE_TYPE {
  STRING = 'string',
  INTEGER = 'integer',
  DATETIME = 'datetime',
  /** @deprecated **/
  OBJ_EMBEDDED = 'obj_embedded',
  OBJ_PK_LINK = 'obj_pk_link',
  OBJ_INTERNAL_ID_LINK = 'obj_internal_id_link',
  BOOLEAN = 'boolean',
  DOUBLE = 'double',
}

export const OBJECT_VALUE_TYPE: FIELD_VALUE_TYPE[] = [
  FIELD_VALUE_TYPE.OBJ_EMBEDDED,
  FIELD_VALUE_TYPE.OBJ_PK_LINK,
  FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
]

export enum OBJECT_VALUES {
  AC_ID = 'ac_id',
  AC_TYPE_ID = 'ac_type_id',
  AIRLINE_ID = 'airline_id',
  AIRPORT_ID = 'airport_id',
  DELAY_CODE_ID = 'delayCode_id',
}

export enum OBJECT_NAMES {
  WKEY_FLIGHT_OBJECT = 'Wkey flight object',
}

export enum GENERATOR_INPUT_TYPE {
  INPUT = 'input',
  SELECT = 'select',
  TEXTAREA = 'textarea',
  SWITCH = 'switch',
  CHECKBOX = 'checkbox',
  DATE_TIME_PICKER = 'datepicker',
  AUTOCOMPLETE = 'autocomplete',
  DATE_RANGE_PICKER = 'daterangepicker',
  RADIO_GROUP = 'radiogroup',
  TOGGLE_BUTTON_GROUP = 'togglebuttongroup',
}

export enum PINNED_COLUMN {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum SEARCH_FIELD_TYPE {
  EXACT_VALUE = 'exact_value',
  RANGE_OF_VALUES = 'range_of_values',
  RELATIVE_VALUE = 'relative_value',
}

export const FORM_IN_NEW_TAB = 'FORM_IN_NEW_TAB'

// Типы элементов на сконфигурированной форме внутри контейнера
export enum FORM_ELEMENT_TYPE {
  FIELD = 'FIELD',
  VIEW = 'VIEW',
  TEXT = 'TEXT',
  SPACE = 'SPACE',
}

// Типы элементов, доступных для перетаскивания на область конфигурирования формы
export enum AVAILABLE_FORM_ELEMENT_TYPE {
  FIELD = 'FIELD',
  VIEW = 'VIEW',
  TEXT = 'TEXT',
  SPACE = 'SPACE',
  CONTAINER = 'CONTAINER',
  HISTORY = 'HISTORY',
}

// Типы droppable-зон на сконфигурированной форме
export enum FORM_DROPPABLE_TYPE {
  CONTAINERS = 'CONTAINERS',
  LINES = 'LINES',
  ELEMENTS = 'ELEMENTS',
  DEFAULT = 'DEFAULT',
}

export const FORM_ELEMENT_TYPE_TO_DROPPABLE_TYPE: Record<
  AVAILABLE_FORM_ELEMENT_TYPE,
  FORM_DROPPABLE_TYPE
> = {
  [AVAILABLE_FORM_ELEMENT_TYPE.FIELD]: FORM_DROPPABLE_TYPE.ELEMENTS,
  [AVAILABLE_FORM_ELEMENT_TYPE.VIEW]: FORM_DROPPABLE_TYPE.ELEMENTS,
  [AVAILABLE_FORM_ELEMENT_TYPE.TEXT]: FORM_DROPPABLE_TYPE.ELEMENTS,
  [AVAILABLE_FORM_ELEMENT_TYPE.SPACE]: FORM_DROPPABLE_TYPE.ELEMENTS,
  [AVAILABLE_FORM_ELEMENT_TYPE.CONTAINER]: FORM_DROPPABLE_TYPE.CONTAINERS,
  [AVAILABLE_FORM_ELEMENT_TYPE.HISTORY]: FORM_DROPPABLE_TYPE.CONTAINERS,
}

// Типы позиции текста в контейнере формы
export enum FORM_ELEMENT_TEXT_ALIGN {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

// Типы позиции лейбла полей формы
export enum FORM_FIELD_LABEL_POSITION {
  LEFT = 'LEFT',
  TOP = 'TOP',
}

// Приведение енама FORM_FIELD_LABEL_POSITION в вид, нужный для компонентов MUI
export const LABEL_PLACEMENT_RECORD: Record<FORM_FIELD_LABEL_POSITION, LabelPlacement> = {
  [FORM_FIELD_LABEL_POSITION.LEFT]: 'start',
  [FORM_FIELD_LABEL_POSITION.TOP]: 'top',
}

export enum DATE_RANGE_POSITION {
  START = 'START',
  END = 'END',
}

export const DEFAULT_FORMAT_DATE = 'DD.MM.YYYY HH:mm'

export const FORMATS_DATE_OPTIONS: AutocompleteOption[] = [
  { id: 'HH:mm:ss', label: 'HH:mm:ss' },
  { id: 'HH:mm', label: 'HH:mm' },
  { id: 'MM.DD.YYYY', label: 'MM.DD.YYYY' },
  { id: 'DD.MM.YYYY', label: 'DD.MM.YYYY' },
  { id: 'MM.DD.YYYY HH:mm:ss', label: 'MM.DD.YYYY HH:mm:ss' },
  { id: 'MM.DD.YYYY HH:mm', label: 'MM.DD.YYYY HH:mm' },
  { id: 'DD.MM.YYYY HH:mm:ss', label: 'DD.MM.YYYY HH:mm:ss' },
  { id: 'DD.MM.YYYY HH:mm', label: 'DD.MM.YYYY HH:mm' },
]

export const BOOLEAN_SELECT_OPTIONS: SelectOption[] = [
  {
    id: 0,
    name: 'true',
    value: 'true',
  },
  {
    id: 1,
    name: 'false',
    value: 'false',
  },
]

export type PreFillType = {
  objectCode: string
  objectId: string
}
