import { RefObject, useEffect, useState } from 'react'

import { getFactDutyFilter } from '@pages/BarChartPage/helpers/getFactDutyFilter'
import { DatasetParams } from '@pages/BarChartPage/hooks/useDataset'
import { GetConfiguredEntityParamsHandle } from '@pages/ConfiguredEntity/ConfiguredEntity'

type UseViewFilterParams = {
  viewRef: RefObject<GetConfiguredEntityParamsHandle>
  datasetParams: DatasetParams
}

export const useViewFilter = ({ viewRef, datasetParams }: UseViewFilterParams) => {
  const [viewFilter, setViewFilter] = useState<{ from: string; to: string } | null | undefined>(
    undefined
  )

  useEffect(() => {
    if (!viewRef.current) {
      return
    }

    if (viewFilter === undefined) {
      return
    }

    if (viewFilter === null) {
      viewRef.current.refreshObjectData({ filter: '' })

      return
    }

    const { from, to } = viewFilter

    viewRef.current.refreshObjectData({
      filter: getFactDutyFilter(
        from,
        to,
        datasetParams.department?.id || '',
        // TODO Харкодно фильтруем по квалификации сотрудника 'ALL' (потому-что такой у него нет)
        datasetParams.qual?.id ? [datasetParams.qual.id].filter(qual => qual !== 'ALL') : []
      ),
    })
  }, [viewFilter])

  return { viewFilter, setViewFilter }
}
