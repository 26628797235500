import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GridColumnMenuColumnsItem,
  GridColumnMenuContainer,
  GridColumnMenuPinningItem,
  GridColumnMenuProps,
} from '@microservices/wiskey-react-table-component'
import { MenuItem } from '@mui/material'

import { PageContext } from '@pages/ConfiguredEntity'

import { RESTRICTION_TYPE } from '../../../../types'

type CustomColumnMenuComponentProps = {
  onShowConfig: (value: boolean) => void
} & GridColumnMenuProps

export const CustomColumnMenuComponent: FC<CustomColumnMenuComponentProps> = props => {
  const { colDef, hideMenu, onShowConfig } = props
  const currentColumn = colDef
  const { t } = useTranslation()
  const { onDeleteViewRestrictionsByType, type } = useContext(PageContext)

  return (
    <GridColumnMenuContainer {...props}>
      {/*<GridColumnMenuHideItem*/}
      {/*  colDef={currentColumn}*/}
      {/*  onClick={e => {*/}
      {/*    hideMenu(e)*/}
      {/*  }}*/}
      {/*/>*/}
      <GridColumnMenuColumnsItem
        colDef={currentColumn}
        onClick={e => {
          hideMenu(e)
          onShowConfig(true)
        }}
      />
      <GridColumnMenuPinningItem
        colDef={currentColumn}
        onClick={e => {
          hideMenu(e)
        }}
      />
      <MenuItem
        onClick={e => {
          onDeleteViewRestrictionsByType(RESTRICTION_TYPE.ORDER)
          hideMenu(e)
        }}
      >
        {t('configEntity.view.resetOrder')}
      </MenuItem>
    </GridColumnMenuContainer>
  )
}
