import { createContext, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import isUndefined from 'lodash/isUndefined'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { CircularProgress, Collapse, Grid, Typography } from '@mui/material'

import { PageContentLayout } from '@layouts/PageContentLayout'

import { useFetchAllCriteriasQuery } from '@redux/api'

import {
  checkDirtyStoredValue,
  filterAndSortCriteriasByOrder,
  getLocalStorageContext,
  getSortParamRequest,
} from '@helpers'
import {
  COUNT_FORM_FIELD,
  CriteriaType,
  FIELD_VALUE_TYPE,
  FILTERS_SA_TYPE,
  SORT_BY_ORDER_AND_DEFAULT,
  ValueAddType,
} from '@constants'
import { EntityType, POSTObjectDataFilter } from '@types'

import { AssistantType } from './components/AssistantType'
import { FilterCreate } from './components/FilterCreate'
import { ViewSelector } from './components/ViewSelector'
import { sortByValue, transformCriteriaFromServer, transformFiltersToValuesPOST } from './helpers'

const sortCriteriasByValue = (filters: CriteriaType[]) =>
  filters.sort((a, b) => (b.storedValues.some(({ value }) => value) ? 1 : -1))

export type SearchAssistantProps = {
  entity: EntityType | null
  isSearchAssistant?: boolean
  isSearchAssistantDialogWindow?: boolean
  dialogId?: string
  searchAssistantId: string | number | undefined
  onClear: () => void
  onSetEntityId: (value: string | number | null) => void
  onSetEntityCode: (value: string | null) => void
  onSearchFilter: (value: POSTObjectDataFilter) => void
  searchFilterBody?: POSTObjectDataFilter
  defaultViewIdSA: string | number | undefined | null
  loadingContextSA?: boolean
}

type SearchAssistantContextProps = {
  searchAssistantId: string | number | undefined
  entityObjectCode: string | undefined
  isDropDownWindowOpen: boolean
  isSearchAssistantDialogWindow: boolean
  entityCode?: string
  entityId?: string | number
  onSetDropDownWindowOpen: (value: boolean) => void
  onSearchFilter: (value: POSTObjectDataFilter) => void
  loadingContextSA?: boolean
}

export const SearchAssistantContext = createContext<SearchAssistantContextProps>(
  {} as SearchAssistantContextProps
)

export const SearchAssistant: FC<SearchAssistantProps> = ({
  isSearchAssistant,
  isSearchAssistantDialogWindow = false,
  entity,
  dialogId,
  searchAssistantId,
  onClear,
  onSetEntityCode,
  onSetEntityId,
  onSearchFilter,
  searchFilterBody,
  defaultViewIdSA,
  loadingContextSA,
}) => {
  const { t } = useTranslation()
  const [isOpenFilter, setIsOpenFilter] = useState(Boolean(isSearchAssistant))
  const [isDropDownWindowOpen, setDropDownWindowOpen] = useState(false)

  const handleSetDropDownWindowOpen = (value: boolean) => setDropDownWindowOpen(value)

  const context = getLocalStorageContext()

  // criterias - все критерии у вьюхи с бэка
  const {
    data: criteriasData,
    isLoading: loadingCriterias,
    isFetching: fetchingCriterias,
  } = useFetchAllCriteriasQuery(
    {
      searchAssistantId,
      objectCode: entity?.objectCode,
      sort: getSortParamRequest(SORT_BY_ORDER_AND_DEFAULT),
    },
    {
      skip: !entity?.objectCode || !isOpenFilter || !searchAssistantId,
      refetchOnMountOrArgChange: true,
    }
  )

  const isLoadingCriterias = loadingCriterias || fetchingCriterias

  const visibleCriterias = useMemo(
    () => criteriasData?.data?.filter(criteria => criteria.visibility) || [],
    [criteriasData]
  )

  // все текущие критерии у вьюхи
  const [assistantCriterias, setAssistantCriterias] = useState<CriteriaType[]>()
  // Первые 5 заполненных критериев из assistantCriterias (50 строка)
  const [currentCriterias, setCurrentCriteria] = useState<CriteriaType[]>([])
  const [pinnedCriterias, setPinnedCriterias] = useState<CriteriaType[]>([])
  const isDirty = useMemo(
    () =>
      assistantCriterias?.some(criteria =>
        criteria.storedValues.some(({ value, searchRule }) =>
          checkDirtyStoredValue(value, searchRule)
        )
      ) || false,
    [assistantCriterias]
  )

  const handleSetDefaultCriterias = () => {
    const assistantCriterias = visibleCriterias.map(transformCriteriaFromServer)
    const pinnedCriterias = assistantCriterias.filter(({ isPinning }) => isPinning)
    const commonCriterias = filterAndSortCriteriasByOrder(
      assistantCriterias.filter(({ isPinning }) => !isPinning)
    )

    setAssistantCriterias(assistantCriterias)
    setPinnedCriterias(pinnedCriterias)
    setCurrentCriteria(commonCriterias.slice(0, COUNT_FORM_FIELD))
  }

  useEffect(() => {
    handleSetDefaultCriterias()
  }, [visibleCriterias])

  const handleSetSavedSearch = (search: CriteriaType[]) => {
    const assistantCriterias = visibleCriterias.map(criteria => {
      const usedCriteria = search.find(
        savedCriteria => savedCriteria.codeCriteria === criteria.code
      )

      if (usedCriteria) {
        return usedCriteria
      }

      return transformCriteriaFromServer(criteria)
    })

    setAssistantCriterias(assistantCriterias)
    setCurrentCriteria(
      sortByValue(assistantCriterias)
        .filter(({ isPinning }) => !isPinning)
        .slice(0, COUNT_FORM_FIELD)
    )
    setPinnedCriterias(sortByValue(assistantCriterias).filter(({ isPinning }) => isPinning))

    onSearchFilter(transformFiltersToValuesPOST(search))
  }

  const handleOpenFilter = () => setIsOpenFilter(!isOpenFilter)

  // TODO Жёсткий рефакторинг
  const addValue = ({ codeCriteria, value, valueId, searchRule }: ValueAddType) => {
    setAssistantCriterias(prevAssistantCriterias =>
      prevAssistantCriterias?.map(assistantCriteria => {
        // находим текущий критерий
        if (assistantCriteria.codeCriteria === codeCriteria) {
          return {
            ...assistantCriteria,
            storedValues: assistantCriteria.storedValues.map(storedValue => {
              if (valueId) {
                //если id сохраненных полей совпадает, то обновить текущее значение
                if (storedValue.id === valueId) {
                  return { ...storedValue, searchRule, value }
                }

                return storedValue
              }

              return {
                ...storedValue,
                searchRule,
                value: storedValue.valueType === FIELD_VALUE_TYPE.DATETIME ? null : '',
              }
            }),
          }
        }

        return assistantCriteria
      })
    )
  }

  const handleCleanAssistantCriteriasValues = () => {
    setAssistantCriterias(prev =>
      prev?.map(criteria => {
        return {
          ...criteria,
          storedValues: criteria.storedValues.map(storedValue => {
            return {
              ...storedValue,
              value: storedValue.valueType === FIELD_VALUE_TYPE.DATETIME ? null : '', // Сброс значения критерия
              searchRule: FILTERS_SA_TYPE.CONTAIN,
            }
          }),
        }
      })
    )
  }

  const handleClean = () => {
    handleCleanAssistantCriteriasValues()
    onClear()
    handleSetDefaultCriterias()
  }

  const AssistianCriterias = useMemo(() => {
    return assistantCriterias && filterAndSortCriteriasByOrder(assistantCriterias).length > 0 ? (
      <FilterCreate
        addValue={addValue}
        assistantCriterias={filterAndSortCriteriasByOrder(assistantCriterias) || []}
        criterias={visibleCriterias}
        currentCriterias={currentCriterias}
        isDirty={isDirty}
        pinnedCriterias={pinnedCriterias}
        setCurrentCriteria={setCurrentCriteria}
        setPinnedCriterias={setPinnedCriterias}
        onClean={handleClean}
        onSearch={handleSetSavedSearch}
      />
    ) : (
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 100,
        }}
      >
        <Typography mb={2} ml={2}>
          {t('searchFilterForm.emptyCriterias')}
        </Typography>
      </Grid>
    )
  }, [
    isSearchAssistantDialogWindow,
    assistantCriterias,
    entity?.objectCode,
    isDirty,
    visibleCriterias,
    currentCriterias,
  ])

  return (
    <SearchAssistantContext.Provider
      value={{
        isDropDownWindowOpen,
        isSearchAssistantDialogWindow,
        entityCode: entity?.code,
        entityId: entity?.id,
        searchAssistantId,
        entityObjectCode: entity?.objectCode,
        onSetDropDownWindowOpen: handleSetDropDownWindowOpen,
        onSearchFilter,
        loadingContextSA,
      }}
    >
      <Grid
        container
        bgcolor={theme => theme.palette.background.main}
        visibility={!isUndefined(searchFilterBody) ? 'hidden' : 'visible'}
      >
        <Grid container item alignItems={'center'} height={25} pb={0}>
          <AssistantType
            isCollapse
            icon={<FilterAltOutlinedIcon />}
            isActive={isOpenFilter}
            title={t('filter.create')}
            onOpen={handleOpenFilter}
          />
        </Grid>
        <Grid container item>
          <Collapse in={isOpenFilter} sx={{ width: '100%' }}>
            <PageContentLayout>
              {isSearchAssistant && (
                <Grid item p={0.5}>
                  <ViewSelector
                    contextId={context?.id}
                    defaultViewIdSA={defaultViewIdSA || null}
                    entityId={entity?.id || null}
                    loadingContextSA={loadingContextSA}
                    searchAssistantId={searchAssistantId || null}
                    onClear={handleCleanAssistantCriteriasValues}
                    onSetEntityCode={onSetEntityCode}
                    onSetEntityId={onSetEntityId}
                  />
                </Grid>
              )}
              {isLoadingCriterias ? (
                <Grid
                  item
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 100,
                  }}
                >
                  <CircularProgress />
                </Grid>
              ) : (
                AssistianCriterias
              )}
            </PageContentLayout>
          </Collapse>
        </Grid>
      </Grid>
    </SearchAssistantContext.Provider>
  )
}
