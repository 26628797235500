import { URLS } from '@constants'
import {
  GETAllVariables,
  GETObjectDataParams,
  GETVariableDTO,
  POSTCopyVariable,
  POSTVariableDTO,
} from '@types'

import { commonAPI } from './common.api'

export const variableAPI = commonAPI.injectEndpoints({
  endpoints: build => ({
    fetchAllVariables: build.query<GETAllVariables, GETObjectDataParams | void>({
      query: params => ({
        url: URLS.FRONT_CFG + '/variable',
        params: {
          searchPattern: params?.searchPattern,
        },
      }),
      providesTags: ['AllVariables'],
    }),
    fetchVariableById: build.query<GETVariableDTO, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/variable/id/${id}`,
      }),
      providesTags: ['SingleVariable'],
    }),
    createVariable: build.mutation<GETVariableDTO, POSTVariableDTO>({
      query: context => ({
        url: URLS.FRONT_CFG + '/variable',
        method: 'POST',
        body: context,
      }),
      invalidatesTags: ['AllVariables'],
    }),
    updateVariable: build.mutation<GETVariableDTO, POSTVariableDTO>({
      query: variableData => ({
        url: URLS.FRONT_CFG + '/variable',
        method: 'POST',
        body: variableData,
      }),
      invalidatesTags: ['AllVariables', 'SingleVariable'],
    }),
    copyVariable: build.mutation<GETVariableDTO, POSTCopyVariable>({
      query: variableData => ({
        url: URLS.FRONT_CFG + '/variable/copy',
        method: 'POST',
        body: variableData,
      }),
      invalidatesTags: ['AllVariables'],
    }),
    deleteVariable: build.mutation<GETVariableDTO, number>({
      query: id => ({
        url: URLS.FRONT_CFG + `/variable/id/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AllVariables'],
    }),
  }),
})

export const {
  useFetchAllVariablesQuery,
  useCreateVariableMutation,
  useFetchVariableByIdQuery,
  useUpdateVariableMutation,
  useCopyVariableMutation,
  useDeleteVariableMutation,
} = variableAPI
