import { Moment } from 'moment'

export const getHoursInterval = (
  currentDay: Moment,
  offset: number
): {
  startHourInterval: Moment
  endHourInterval: Moment
} => {
  const startHourInterval = currentDay.clone().add(offset, 'hour')
  const endHourInterval = currentDay.clone().add(offset + 1, 'hour')

  return { startHourInterval, endHourInterval }
}
