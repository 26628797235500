import i18next from 'i18next'

import { SearchFilterForm } from '../AddSearchFilterDialog'

export const getDefaultValues = (): SearchFilterForm => {
  return {
    code: '',
    title: '',
    objectTitle: i18next.t('searchFilterForm.object.placeholder'),
    isPinning: false,
    // value: [{ id: 0, label: i18next.t('searchFilterForm.value.placeholder') }],
    // fieldType: i18next.t('searchFilterForm.fieldType.placeholder'),
    // objectValue: i18next.t('searchFilterForm.objectValue.placeholder'),
    values: [
      {
        name: null,
        fieldType: null,
        objectValue: '',
        linkedObjectCode: '',
        isDefaultPlaceholder: true,
        placeholderValue: '',
        placeholderEnd: '',
        placeholderStart: '',
        isPinning: false,
        dropDownListCode: null,
        dropDownWindowCode: null,
        dropDownListFilters: [],
        dropDownWindowFilters: [],
        isGroupRelated: false,
        asCheckbox: false,
        asDuration: false,
        pathArray: [],
      },
    ],
    visibility: true,
  }
}
