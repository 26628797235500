import { FC, SyntheticEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Resizable } from 'react-resizable'
import { ModalType, ObjectFieldDTO } from 'src/types'
import { ModalWrapper } from '@microservices/wiskey-react-components'
import { Box, DialogContent, Typography, useTheme } from '@mui/material'

import { EditorBase } from '@components/ScriptValueDialog/EditorBase'

import { ErrorValidJSType } from '@helpers'
import { SCRIPT_DIALOG_WINDOW_OFFSET } from '@constants'

import 'react-resizable/css/styles.css'
import 'prismjs/themes/prism.css'

type ScriptValueDialogProps = {
  isShow: boolean
  onClose: (value?: boolean) => void
  value?: string
  title?: string
  btnText?: string
  errorMessage?: string
  objectFields?: ObjectFieldDTO[]
  modalType?: ModalType
  language?: 'js' | 'json' | 'jsx'
  isJsx?: boolean
  onSave?: (value: string) => void
  noValidate?: boolean
  hint?: string
  windowBoundaries?: boolean
  validator?: (value: string) => ErrorValidJSType | undefined
}

export const ScriptValueDialog: FC<ScriptValueDialogProps> = ({
  isShow,
  onClose,
  btnText,
  modalType,
  objectFields,
  title,
  isJsx,
  value,
  onSave,
  noValidate = false,
  hint,
  windowBoundaries = false,
  validator,
  language = 'jsx',
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [invalid, setInvalid] = useState(false)
  const [valueJs, setValueJs] = useState(value || '')
  const isDirty = value !== valueJs

  const handleSetInvalid = (state: boolean) => {
    setInvalid(state)
  }

  const getDialogErrorStyle = useMemo(() => {
    return {
      minWidth: 450,
      border: `1px solid ${theme.palette.grey[600]}`,
      borderColor: invalid ? theme.palette.error.light : theme.palette.grey[600],
      transition: 'all .5s ease-in-out',
    }
  }, [invalid, theme])

  const handleSaveChangedValues = (): void => {
    if (value !== valueJs) {
      onSave?.(valueJs)
    }
    if (onClose) {
      onClose()
    }
  }

  const [height, setHeight] = useState(200)
  const [width, setWidth] = useState(450)

  const handleResize = (event: SyntheticEvent) => {
    const windowWidthBoundary = window.innerWidth - SCRIPT_DIALOG_WINDOW_OFFSET.WIDTH
    const windowHeightBoundary = window.innerHeight - SCRIPT_DIALOG_WINDOW_OFFSET.HEIGHT

    const newWidth = width + event.movementX
    const newHeight = height + event.movementY

    const isOutsideOfWindowBoundaries =
      newWidth > windowWidthBoundary || newHeight > windowHeightBoundary

    if (windowBoundaries && isOutsideOfWindowBoundaries) {
      return
    }

    setWidth(newWidth)
    setHeight(newHeight)
  }

  return (
    <ModalWrapper
      btnText={btnText || t(`modal.applyBtnText`)}
      disabledSave={!isDirty || invalid}
      focusCancel={false}
      focusSave={false}
      isShow={isShow}
      title={title || `${t(`addDialog.${modalType}`)} JS | JSX`}
      onClose={onClose}
      onSave={handleSaveChangedValues}
    >
      <Resizable height={height} width={width} onResize={handleResize}>
        <>
          <DialogContent>
            <Box sx={{ height, width }}>
              <EditorBase
                invalid={invalid}
                isJsx={isJsx}
                language={language}
                noValidate={noValidate}
                objectFields={objectFields}
                style={getDialogErrorStyle}
                validator={validator}
                value={value}
                onChange={value => setValueJs(value)}
                onInvalid={handleSetInvalid}
              />
            </Box>
          </DialogContent>
          {hint && (
            <Typography color={'primary'} variant={'subtitle2'}>
              {hint}
            </Typography>
          )}
        </>
      </Resizable>
    </ModalWrapper>
  )
}
