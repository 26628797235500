import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Backdrop, Box, CardContent, CircularProgress, Grid, LinearProgress } from '@mui/material'

import { TabPanel } from '@components/Tabs'

import { ContainerTabPanel } from './ConfiguredElementsOnTooltip/ContainerTabPanel'
import { FieldsConfigContext, Title } from './FieldsConfig'

export const ConfiguredTooltipElements: FC = () => {
  const { containers = [], isLoadingLines } = useContext(FieldsConfigContext)
  const { t } = useTranslation()

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      {isLoadingLines && (
        <LinearProgress sx={{ position: 'absolute', top: '-1rem', width: '100%' }} />
      )}
      <Backdrop
        open={isLoadingLines}
        sx={{ color: '#fff', position: 'absolute', zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Grid container direction={'column'}>
        <Grid item>
          <Title sx={{ ml: 2 }} title={t('fieldsConfig.configuration')} />
        </Grid>
        <Grid item>
          <CardContent sx={{ pt: 1, pl: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{
                  minHeight: 56,
                  py: 1.5,
                  boxSizing: 'content-box',
                  background: theme => theme.palette.background.formCardContainer,
                  position: 'relative',
                }}
              >
                {containers[0].tabs.map((tab, tabIndex) => (
                  <TabPanel
                    key={tab.id}
                    index={0}
                    value={tabIndex}
                    contentSx={{
                      p: 0,
                    }}
                  >
                    <ContainerTabPanel containerId={containers[0].id} index={tabIndex} tab={tab} />
                  </TabPanel>
                ))}
              </Box>
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Box>
  )
}
